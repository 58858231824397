import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { BookingSummaryAccommodationLoading } from '../../../common'
import { LayoutHistoryDetailsLoading } from '../common'

interface HistoryDetailsAccommodationLoadingProps {
  backNavigationPath: To
}

export const HistoryDetailsAccommodationLoading: FC<HistoryDetailsAccommodationLoadingProps> = ({
  backNavigationPath,
}) => {
  return (
    <LayoutHistoryDetailsLoading backNavigationPath={backNavigationPath}>
      <BookingSummaryAccommodationLoading />
      {/* // TODO 128: Restore price summaries on booking details when a bug in HotelLinx is fixed */}
      {/* //  https://hcrdevelopment.atlassian.net/browse/SII-128 */}
      {/* // <BookingSummaryPriceLoading /> */}
    </LayoutHistoryDetailsLoading>
  )
}
