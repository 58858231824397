import { z } from 'zod'

import { Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

export const RoomDtoSchema = z.strictObject({
  room_number: Int32DtoSchema,
  first_name: StringDtoSchema,
  last_name: StringDtoSchema,
  check_in_time: StringDtoSchema.datetime().nullable(),
  check_out_time: StringDtoSchema.datetime().nullable(),
})

export type RoomDto = z.infer<typeof RoomDtoSchema>
