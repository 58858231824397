import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkUnstyled, unit } from '@hcr/ui'

import { SvgPersonMagnifier } from '../../../assets'
import { Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutNavigation } from '../../common'

export const ServicesEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutNavigation showContextMenuButton stretchContentVertically>
      <Container>
        <SvgPersonMagnifier />
        <Stack gap={unit(2.5)} textAlign='center'>
          <Typography variant='titleM'>{t('services.services')}</Typography>
          <Typography variant='bodyM'>{t('services.to-explore-services-please-select-a-destination')}</Typography>
        </Stack>
        <Button component={LinkUnstyled} to={to(Path.ContextMenuDestinations)} variant='contained' color='primary'>
          {t('services.browse-destinations')}
        </Button>
      </Container>
    </LayoutNavigation>
  )
}

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${unit(7)};
  padding: ${unit(19)} ${unit(7)} ${unit(7)};
`
