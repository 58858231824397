import { Box } from '@mui/material'
import type { FC } from 'react'

import { unit } from '@hcr/ui'

import { Error500 } from '../error'

import { LayoutNavigation } from './LayoutNavigation'

interface LayoutNavigationError500Props {
  showContextMenuButton?: boolean
}

export const LayoutNavigationError500: FC<LayoutNavigationError500Props> = ({ showContextMenuButton }) => {
  return (
    <LayoutNavigation showContextMenuButton={showContextMenuButton} stretchContentVertically>
      <Box paddingTop={unit(19)} height='100%'>
        <Error500 />
      </Box>
    </LayoutNavigation>
  )
}
