import { Box, Divider, Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import type { To } from 'react-router-dom'

import { COLORS, unit } from '@hcr/ui'

import { LayoutNavigationBack } from '../../../../../common'

interface LayoutOwnerBenefitsProps extends PropsWithChildren {
  backNavigationPath: To
  description: ReactNode
  title: ReactNode
}

export const LayoutOwnerBenefits: FC<LayoutOwnerBenefitsProps> = ({
  backNavigationPath,
  children,
  description,
  title,
}) => {
  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
      <Box bgcolor={COLORS.white} minHeight='100vh'>
        <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
          <Typography variant='headlineL'>{title}</Typography>
          <Typography variant='bodyM'>{description}</Typography>
        </Stack>
        <Box paddingX={unit(3)}>
          <Divider />
          {children}
        </Box>
      </Box>
    </LayoutNavigationBack>
  )
}
