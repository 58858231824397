import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { CardDtoSchema } from './CardDto'

export const CarouselDtoSchema = z.strictObject({
  component: z.literal('carrousel'),
  title: StringDtoSchema,
  content: z.array(CardDtoSchema),
})

export type CarouselDto = z.infer<typeof CarouselDtoSchema>

export const isCarouselDto = (x: unknown): x is CarouselDto => CarouselDtoSchema.safeParse(x).success
