import { Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

import { unit } from '@hcr/ui'

import { useErrorPageTracking } from './hooks'

interface ErrorProps {
  dataAnalyticsId: string
  description: string
  image: ReactNode
  title: string
}

export const Error: FC<ErrorProps> = ({ dataAnalyticsId, description, image, title }) => {
  useErrorPageTracking({ dataAnalyticsId })

  return (
    <Container data-analyticsid={dataAnalyticsId}>
      {image}
      <Content>
        <Typography variant='titleM'>{title}</Typography>
        <Typography variant='bodyM'>{description}</Typography>
      </Content>
    </Container>
  )
}

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${unit(7)};
`

const Content = styled(Stack)`
  text-align: center;
  gap: ${unit(2.5)};
`
