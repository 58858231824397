import { z } from 'zod'

export const AccommodationStatusDtoSchema = z.union([
  z.literal('Active'),
  z.literal('Cancelled'),
  z.literal('Checked in'),
  z.literal('Checked out'),
  z.literal('Queued'),
])

export type AccommodationStatusDto = z.infer<typeof AccommodationStatusDtoSchema>
