import { z } from 'zod'

import { Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { CardPrimaryDtoSchema, PricingDtoSchema } from '../components'

export const BuildingDetailsDtoSchema = z.strictObject({
  destinationId: Int32DtoSchema,
  destinationName: StringDtoSchema.nullable(),
  imageUrl: StringDtoSchema.url(),
  title: StringDtoSchema,
  description: StringDtoSchema,
  content: z.array(z.union([CardPrimaryDtoSchema, PricingDtoSchema])),
})

export type BuildingDetailsDto = z.infer<typeof BuildingDetailsDtoSchema>

export const isBuildingDetailsDto = (x: unknown): x is BuildingDetailsDto =>
  BuildingDetailsDtoSchema.safeParse(x).success
