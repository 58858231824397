import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoSchema } from '../common'

import { CardDtoBaseSchema } from './CardDtoBase'

export const CardPrimaryDtoSchema = CardDtoBaseSchema.extend({
  component: z.literal('card-primary'),
  imageUrl: StringDtoSchema.url().nullable(),
  tag: StringDtoSchema.nullable(),
  title: StringDtoSchema.nullable(),
  link: LinkDtoSchema.nullable(),
})

export type CardPrimaryDto = z.infer<typeof CardPrimaryDtoSchema>

export const isCardPrimaryDto = (x: unknown): x is CardPrimaryDto => CardPrimaryDtoSchema.safeParse(x).success
