import type { LogLevel } from '@hcr/utils'

import { Environment } from '../environments/environment'

interface AppConfig {
  logLevel: LogLevel
  thirdPartySoftwareLicensesPath: string
  version: string
}

export const APP_CONFIG: AppConfig = {
  logLevel: Environment.General.logLevel,
  thirdPartySoftwareLicensesPath: '/3rdpartylicenses.txt',
  version: `${process.env.VERSION}-${Environment.General.name}`,
}
