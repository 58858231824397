import { isBefore } from 'date-fns'

import type { HoldingDto, HoldingWeekDto } from '@hcr/api/consumer'
import { filter, flow, getPropertyValue, sort } from '@hcr/utils'

import { compareDates } from '../../../dates'

export const selectNextHoldingWeeks: (holding: HoldingDto) => HoldingWeekDto[] = flow(
  getPropertyValue('weeks'),
  filter(week => isBefore(new Date(), new Date(week.week_start))),
  sort({
    comparer: compareDates,
    asc: getPropertyValue('week_start'),
  })
)
