import { Stack, Typography } from '@mui/material'
import { sanitize } from 'dompurify'
import type { FC } from 'react'

import { COLORS } from '../../../constants'
import { optimizelyCss } from '../../../styles'
import { unit } from '../../../utils'

interface PaperProps {
  description: string
  title: string
}

export const Paper: FC<PaperProps> = ({ description, title }) => {
  return (
    <Stack gap={unit(3)} padding={unit(8)} bgcolor={COLORS.white} alignItems='flex-start' justifyContent='flex-start'>
      <Typography variant='headlineM' color={COLORS.burgundi[500]}>
        {title}
      </Typography>
      <Typography variant='bodyM' css={optimizelyCss} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    </Stack>
  )
}
