import { Skeleton } from '@mui/material'
import type { FC } from 'react'

import { CarouselLoading, unit } from '@hcr/ui'

import { LayoutServices } from './LayoutServices'

export const LayoutServicesLoading: FC = () => {
  return (
    <LayoutServices
      description={
        <>
          <Skeleton width='95%' height={unit(7)} />
          <Skeleton width='85%' height={unit(7)} />
          <Skeleton width='90%' height={unit(7)} />
        </>
      }
      title={<Skeleton width='80%' height={unit(9.5)} variant='rectangular' />}
    >
      <CarouselLoading slidesMargin={unit(6)} />
      <CarouselLoading slidesMargin={unit(6)} />
      <CarouselLoading slidesMargin={unit(6)} />
    </LayoutServices>
  )
}
