import { Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { unit } from '@hcr/ui'

import { useLastPath } from '../../../contexts'
import { useAccountQuery } from '../../../hooks'
import { ButtonSignIn, ButtonSignUp, LayoutNavigationBack } from '../../common'

import { UsefulLinks } from './common'

export const AccountUnauthenticated: FC = () => {
  const { t } = useTranslation()
  const lastPath = useLastPath()

  const account = useAccountQuery()

  return (
    <LayoutNavigationBack to={lastPath.root} arrowVariant='text'>
      <Stack gap={unit(7)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(9)}>
        <Stack gap={unit(2.5)} textAlign='center'>
          <Typography variant='titleM'>{t('account.account-information')}</Typography>
          <Typography variant='bodyM'>{t('account.to-view-and-manage-your-account-please-sign-in')}</Typography>
        </Stack>
        <Stack gap={unit(4)}>
          <ButtonSignIn variant='contained' color='primary' />
          <ButtonSignUp variant='outlined' color='black' />
        </Stack>
      </Stack>
      <UsefulLinks links={account.isSuccess ? account.data.links : []} />
    </LayoutNavigationBack>
  )
}
