import type { ActivityDto } from '@hcr/api/consumer'

import { DateFormat, TimeFormat } from '../../models'
import { dateFormatter } from '../dates'

interface ActivityHistoryValidity {
  date: string
  time: string
}

export const createActivityHistoryValidity = (activity: ActivityDto): ActivityHistoryValidity => ({
  date: `${dateFormatter.format(DateFormat.Standard, activity.start_date)}`,
  time: `${dateFormatter.formatDateRange({
    from: activity.start_date,
    fromFormat: TimeFormat.Standard,
    to: activity.end_date,
    toFormat: TimeFormat.Standard,
  })}`,
})
