import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, UserDetailsDto } from '../../dtos'
import { UserDetailsDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

export const createUserDetailsQueryKey = () => ['consumer', 'user']

export const createUserDetailsQuery =
  (baseUrl: string) =>
  <Data = UserDetailsDto>(
    params: AuthorizedRequestParams,
    options?: Partial<UseQueryOptions<UserDetailsDto, ProblemDetailsDto, Data>>
  ) =>
    useQuery<UserDetailsDto, ProblemDetailsDto, Data>({
      ...options,
      queryKey: createUserDetailsQueryKey(),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(params.idToken),
          schema: UserDetailsDtoSchema,
          url: `${baseUrl}/me`,
        }),
    })
