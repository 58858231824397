import { z } from 'zod'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkHomeDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('home'),
})

export type LinkHomeDto = z.infer<typeof LinkHomeDtoSchema>

export const isLinkHomeDto = (x: unknown): x is LinkHomeDto => LinkHomeDtoSchema.safeParse(x).success
