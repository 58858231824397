import { Button, Stack, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { createHoldingsQueryKey } from '@hcr/api/consumer'
import { Alert, ButtonLoading, LinkUnstyled, unit } from '@hcr/ui'
import { isNotUndefined, isUndefined, logger } from '@hcr/utils'

import { useHoldingBookingWeekCancelMutation, useIdToken } from '../../../../hooks'
import { Path, type OwnerHoldingBookingCancelPathParams } from '../../../../models'
import { createHoldingNameString, to } from '../../../../utils'
import { LayoutNavigationBackError404 } from '../../../common'
import { ContractPreview, LayoutOwnerHoldingAction, LayoutOwnerHoldingActionLoading } from '../common'
import { useBackNavigationPath, useHolding, useWeek } from '../hooks'

export const OwnerHoldingBookingCancel: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const params = useParams<OwnerHoldingBookingCancelPathParams>()
  const queryClient = useQueryClient()

  const holding = useHolding({ holdingId: params.holdingId })
  const holdingBookingCancel = useHoldingBookingWeekCancelMutation({ idToken: String(idToken) })
  const week = useWeek({ holding: holding.data, weekStart: params.weekStart })

  const backNavigationPath = useBackNavigationPath({ holdingId: params.holdingId })

  const handleCancelBooking = async () => {
    try {
      if (isNotUndefined(holding.data) && isNotUndefined(week)) {
        await holdingBookingCancel.mutateAsync({
          contract_id: holding.data.contract_id,
          end_date: week.week_end,
          room_number: holding.data.room_number,
          start_date: week.week_start,
          week_number: week.week_number,
        })
        queryClient.removeQueries({ queryKey: createHoldingsQueryKey() })
      }
    } catch {
      logger.error('Failed to cancel the week:', week)
    }
  }

  if (
    isUndefined(params.holdingId) ||
    isUndefined(params.weekStart) ||
    holding.isError ||
    (holding.isSuccess && isUndefined(holding.data)) ||
    (holding.isSuccess && isUndefined(week))
  ) {
    return <LayoutNavigationBackError404 to={backNavigationPath} />
  }

  if (holding.isSuccess && isNotUndefined(holding.data) && isNotUndefined(week)) {
    const backBookingPath = to([
      Path.OwnerHoldingBooking,
      { holdingId: holding.data.holding_id, weekStart: week.week_start },
    ])

    if (week.is_reservation_request_received) {
      return (
        <LayoutOwnerHoldingAction
          backNavigationPath={backBookingPath}
          header={<Typography variant='headlineL'>{t('owner-holding.booking-cancel-requested')}</Typography>}
        >
          <Typography variant='bodyM'>{t('owner-holding.your-booking-cancel-request-has-been-sent')}</Typography>
          <ContractPreview
            buildingId={holding.data.building_id}
            holdingName={createHoldingNameString(holding.data)}
            week={week}
          />
          <Stack gap={unit(1.5)}>
            <ButtonLoading
              onClick={handleCancelBooking}
              isLoading={holdingBookingCancel.isPending}
              variant='contained'
              color='success'
            >
              {t('owner-holding.confirm-cancellation')}
            </ButtonLoading>

            <Button component={LinkUnstyled} to={backBookingPath} variant='outlined' color='black'>
              {t('owner-holding.cancel')}
            </Button>
          </Stack>
        </LayoutOwnerHoldingAction>
      )
    }

    if (week.is_locked) {
      return <Navigate to={backNavigationPath} replace />
    }

    const backHoldingWeeksPath = to([Path.OwnerHoldingWeeks, { holdingId: holding.data.holding_id }])

    return (
      <LayoutOwnerHoldingAction
        backNavigationPath={backHoldingWeeksPath}
        header={<Typography variant='headlineL'>{t('owner-holding.confirm-cancel-reservation')}</Typography>}
      >
        <Typography variant='bodyM'>{t('owner-holding.your-booking-cancelation-has-been-confirmed')}</Typography>

        <ContractPreview
          buildingId={holding.data.building_id}
          holdingName={createHoldingNameString(holding.data)}
          week={week}
        />

        {holdingBookingCancel.isError && (
          <Alert
            severity='error'
            title={t('owner-holding.booking-cancel-failed')}
            description={t('owner-holding.an-error-occurred-while-booking-cancel')}
          />
        )}

        <Button component={LinkUnstyled} to={backHoldingWeeksPath} variant='outlined' color='black'>
          {t('owner-holding.close')}
        </Button>
      </LayoutOwnerHoldingAction>
    )
  }

  return <LayoutOwnerHoldingActionLoading backNavigationPath={backNavigationPath} />
}
