import { Environment } from '../environments/environment'
import type { Locale } from '../models'
import { LocaleId } from '../models'

interface LocaleConfig {
  default: LocaleId
  supported: Record<LocaleId, Locale>
}

// Note: When adding more supported locales, remember to update *Webpack Magic Comments* in locale-related loaders
export const LOCALE_CONFIG: LocaleConfig = {
  default: Environment.General.localeId,
  supported: {
    [LocaleId.EnUS]: {
      id: LocaleId.EnUS,
      lang: 'en',
      name: 'English',
    },
    [LocaleId.Fi]: {
      id: LocaleId.Fi,
      lang: 'fi',
      name: 'suomi',
    },
  },
}
