import { z } from 'zod'

import { LinkExternalDtoSchema } from '../common'

export const AccountDtoSchema = z.strictObject({
  links: z.array(LinkExternalDtoSchema),
})

export type AccountDto = z.infer<typeof AccountDtoSchema>

export const isAccountDto = (x: unknown): x is AccountDto => AccountDtoSchema.safeParse(x).success
