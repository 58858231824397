import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { CardPromotionRegularDto } from '@hcr/api/optimizely'
import { isNotNull } from '@hcr/utils'

import type { OptionsWithCreateLink } from '../../../../models'
import { CardVariant } from '../types'

import type { CardPromotionProps } from './CardPromotion'
import { CardPromotion } from './CardPromotion'

export type CreateCardPromotionRegularOptions = OptionsWithCreateLink & Pick<CardPromotionProps, 'embedment'>

export const createCardPromotionRegular =
  ({ createLink, embedment }: CreateCardPromotionRegularOptions) =>
  (dto: CardPromotionRegularDto): ReactNode => (
    <CardPromotion
      description={dto.description}
      embedment={embedment}
      key={hash([dto.title, dto.description])}
      link={isNotNull(dto.link) ? createLink(dto.link) : null}
      title={dto.title}
      variant={CardVariant.Regular}
    />
  )
