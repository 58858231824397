import { css } from '@mui/material'

export const rotateYCss = css`
  @keyframes rotateY {
    20%,
    100% {
      transform: rotateY(360deg);
    }
  }

  animation: 5s infinite linear rotateY;
`
