import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { DestinationDetailsDto, ErrorDto } from '../../dtos'
import { DestinationDetailsDtoSchema } from '../../dtos'

interface DestinationDetailsQueryParams {
  destinationId: string
}

export const createDestinationDetailsQueryKey = (params: DestinationDetailsQueryParams) => [
  'optimizely',
  'destinations',
  params,
]

export const createDestinationDetailsQuery =
  (baseUrl: string) =>
  <Data = DestinationDetailsDto>(
    params: DestinationDetailsQueryParams,
    options?: Partial<UseQueryOptions<DestinationDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<DestinationDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createDestinationDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: DestinationDetailsDtoSchema,
          url: `${baseUrl}/destinations/${params.destinationId}/details`,
        }),
    })
