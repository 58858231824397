import { css } from '@mui/material'

import { COLORS } from '../constants'
import { unit } from '../utils'

export const optimizelyCss = css`
  a {
    font-family: 'ABC Ginto Nord', sans-serif;
    font-weight: bold;
    color: ${COLORS.burgundi[500]};
  }

  em,
  strong {
    font-weight: bold;
  }

  li {
    margin-left: ${unit(4)};
    padding-left: ${unit(1.5)};
  }

  p:not(:last-of-type) {
    margin-bottom: ${unit(3)};
  }

  ul {
    list-style: square outside;
  }
`
