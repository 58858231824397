import { z } from 'zod'

import { DoubleDtoSchema, Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { HoldingWeekDtoSchema } from './HoldingWeekDto'
import { HoldingWeekTypeDtoSchema } from './HoldingWeekTypeDto'

export const HoldingDtoSchema = z.strictObject({
  holding_id: StringDtoSchema,
  contract_id: Int32DtoSchema,
  building_id: StringDtoSchema,
  real_estate_company: StringDtoSchema,
  resort_name: StringDtoSchema,
  room_number: StringDtoSchema,
  room_size: DoubleDtoSchema,
  share_percent: DoubleDtoSchema,
  week_type: HoldingWeekTypeDtoSchema,
  weeks: z.array(HoldingWeekDtoSchema),
})

export type HoldingDto = z.infer<typeof HoldingDtoSchema>
