import type { PublicClientApplication } from '@azure/msal-browser'

import { APP_CONFIG, AUTH_CONFIG, HTTP_CONFIG, LOCALE_CONFIG } from '../configs'

import { createI18nextLoader } from './createI18nextLoader'
import { createMsalLoader } from './createMsalLoader'
import { createMswLoader } from './createMswLoader'

interface LoadersResults {
  msalInstance: PublicClientApplication
  mswInstance: ServiceWorkerRegistration | undefined
}

export const resolveLoaders = (): Promise<LoadersResults> => {
  const locale = LOCALE_CONFIG.supported[LOCALE_CONFIG.default]

  return Promise.all([
    createI18nextLoader({
      locale,
    }),
    createMsalLoader({
      msalConfig: AUTH_CONFIG.msal,
    }),
    createMswLoader({
      apis: {
        consumerUrl: HTTP_CONFIG.API.Consumer.useMocks ? HTTP_CONFIG.API.Consumer.url : null,
        optimizelyUrl: HTTP_CONFIG.API.Optimizely.useMocks ? HTTP_CONFIG.API.Optimizely.url : null,
      },
      logLevel: APP_CONFIG.logLevel,
    }),
  ]).then(([, msalInstance, mswInstance]) => ({ msalInstance, mswInstance }))
}
