import { Box, Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'

import { isNotNull } from '@hcr/utils'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

interface TileProps extends PropsWithChildren {
  cta: ReactNode
  icon: ReactNode
  title: string
}

export const Tile: FC<TileProps> = ({ children, cta, icon, title }) => {
  return (
    <Stack paddingY={unit(6.5)} paddingX={unit(5)} bgcolor={COLORS.vaalea['60%']}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='headlineS'>{title}</Typography>
        <Box fontSize={unit(6)} color={COLORS.burgundi[400]}>
          {icon}
        </Box>
      </Stack>
      {isNotNull(children) && <Box marginTop={unit(5)}>{children}</Box>}
      {isNotNull(cta) && <Box marginTop={unit(6.5)}>{cta}</Box>}
    </Stack>
  )
}
