import { Typography } from '@mui/material'
import type { FC, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react'
import type { To } from 'react-router-dom'

import { LinkUnstyled } from '../link-unstyled'

interface LinkTransProps extends PropsWithChildren {
  color?: string
  rel?: string
  target?: HTMLAttributeAnchorTarget
  to: To
}

// Note: Use this wrapper when you need to format a link inside <Trans /> component
export const LinkTrans: FC<LinkTransProps> = ({ children, color, rel, target, to }) => {
  return (
    <LinkUnstyled to={to} target={target} rel={rel}>
      <Typography variant='textLinkM' color={color}>
        {children}
      </Typography>
    </LinkUnstyled>
  )
}
