import type { Config } from 'dompurify'

interface SanitizeConfig extends Config {
  RETURN_TRUSTED_TYPE: true
}

export const SANITIZE_CONFIG: SanitizeConfig = {
  ALLOWED_TAGS: ['a', 'br', 'em', 'li', 'p', 'strong', 'ul'],
  ALLOWED_ATTR: ['href', 'rel', 'target'],
  RETURN_TRUSTED_TYPE: true,
}
