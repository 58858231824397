import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, unit, Z_INDEX } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationDetailsQuery } from '../../../../../hooks'
import { Path } from '../../../../../models'
import { to } from '../../../../../utils'

interface NavigationFloatingProps {
  showContextMenuButton: boolean
}

export const NavigationFloating: FC<NavigationFloatingProps> = ({ showContextMenuButton }) => {
  const { t } = useTranslation()
  const holiday = useHoliday()

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(holiday.data.destinationId) },
    { enabled: isNotNull(holiday.data.destinationId) && showContextMenuButton, retryOnMount: false }
  )

  const label = destinationDetails.isSuccess
    ? destinationDetails.data.name
    : destinationDetails.isError && isNotNull(holiday.data.accommodationId)
      ? t('context-menu.booking-number', { accommodationId: holiday.data.accommodationId })
      : t('context-menu.bookings')

  return (
    <Container>
      <Content>
        <Section>
          {showContextMenuButton && (
            <NavigationButton component={LinkUnstyled} to={to(Path.ContextMenu)}>
              <FontAwesomeIcon icon={ICONS.fasCalendarHeart} />
              <Typography variant='bodyM'>{label}</Typography>
              <FontAwesomeIcon icon={ICONS.fassChevronDown} />
            </NavigationButton>
          )}
        </Section>
        <Section>
          <NavigationButton component={LinkUnstyled} to={to(Path.Account)}>
            <FontAwesomeIcon icon={ICONS.fasUser} />
          </NavigationButton>
        </Section>
      </Content>
    </Container>
  )
}

const Container = styled(Box)`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.navigation};
`

const Content = styled(Box)`
  position: absolute;
  top: ${unit(4)};
  left: ${unit(3)};
  right: ${unit(3)};
  height: ${unit(10)};
  display: flex;
  justify-content: space-between;
`

const Section = styled(Box)`
  background-color: ${COLORS.hiekka[500]};
  border-radius: ${unit(1)};
  width: auto;
`

const NavigationButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  gap: ${unit(2)};
  padding: ${unit(2)} ${unit(3)};
  color: ${COLORS.red.dark};
  font-size: ${unit(4.5)};
  font-weight: initial;
  height: 100%;
` as typeof Button
