import type { ReactNode } from 'react'

import type { ServiceDetailsDto } from '@hcr/api/optimizely'
import { isAccordionDto, isCardPrimaryDto, isCardPromotionDto, isCarouselDto } from '@hcr/api/optimizely'
import type { CreateCarouselOptions } from '@hcr/ui'
import {
  CardEmbedment,
  CardVariant,
  createAccordion,
  createCardPrimary,
  createCardPromotion,
  createCarousel,
  createPricing,
} from '@hcr/ui'

import { createLink } from '../common'

export type CreateServiceDetailsContentOptions = Pick<CreateCarouselOptions, 'slidesMargin'>

export const createServiceDetailsContent = ({ slidesMargin }: CreateServiceDetailsContentOptions) => {
  const accordion = createAccordion()
  const cardPrimary = createCardPrimary({
    createLink,
    embedment: CardEmbedment.Standalone,
    variant: CardVariant.Regular,
  })
  const cardPromotion = createCardPromotion({ createLink, embedment: CardEmbedment.Standalone })
  const carousel = createCarousel({ createLink, slidesMargin })
  const pricing = createPricing()

  return (dto: ServiceDetailsDto['content'][number]): ReactNode => {
    if (isAccordionDto(dto)) {
      return accordion(dto)
    }

    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    if (isCardPromotionDto(dto)) {
      return cardPromotion(dto)
    }

    if (isCarouselDto(dto)) {
      return carousel(dto)
    }

    return pricing(dto)
  }
}
