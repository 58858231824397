import type { Shadows } from '@mui/material'
import {
  alertClasses,
  bottomNavigationActionClasses,
  buttonClasses,
  createTheme,
  outlinedInputClasses,
} from '@mui/material'

import { COLORS } from '../../constants'
import { unit } from '../../utils'

const breakpointsTheme = createTheme()

export const light = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    mode: 'light',
    primary: {
      main: COLORS.burgundi[500],
      light: COLORS.red.dark,
      dark: COLORS.burgundi[400],
      contrastText: COLORS.white,
    },
    white: {
      main: COLORS.white,
      contrastText: COLORS.black,
    },
    black: {
      main: COLORS.black,
      contrastText: COLORS.white,
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          color: COLORS.black,
          [`&.${alertClasses.standard}.${alertClasses.colorSuccess}`]: {
            backgroundColor: COLORS.semantic.successBody,
            [`& .${alertClasses.icon}`]: {
              color: COLORS.semantic.success,
            },
          },
          [`&.${alertClasses.standard}.${alertClasses.colorInfo}`]: {
            backgroundColor: COLORS.semantic.infoBody,
            [`& .${alertClasses.icon}`]: {
              color: COLORS.semantic.info,
            },
          },
          [`&.${alertClasses.standard}.${alertClasses.colorWarning}`]: {
            backgroundColor: COLORS.semantic.warningBody,
            [`& .${alertClasses.icon}`]: {
              color: COLORS.semantic.warning,
            },
          },
          [`&.${alertClasses.standard}.${alertClasses.colorError}`]: {
            backgroundColor: COLORS.semantic.errorBody,
            [`& .${alertClasses.icon}`]: {
              color: COLORS.semantic.error,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          padding: 13,
          textTransform: 'none',
          [`&.${buttonClasses.outlined}`]: {
            border: `${unit(0.5)} solid`,
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.puuteri[300],
          color: COLORS.black,
          transition: 'none',
          display: 'flex',
          width: '100%',
          height: 'unset',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          transition: 'none',
          display: 'flex',
          gap: unit(1),
          maxWidth: 'unset',
          padding: `${unit(4)} 0`,
          color: COLORS.black,
          [`&.${bottomNavigationActionClasses.selected}`]: {
            color: COLORS.burgundi[500],
            backgroundColor: COLORS.puuteri[400],
            transition: 'none',
            fontSize: `${unit(3)}`,
          },
          '&:hover': {
            color: COLORS.burgundi[500],
          },
        },
        label: {
          fontFamily: 'ABC Ginto Nord, sans-serif',
          fontWeight: 'bold',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: COLORS.semantic.divider,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          margin: '0 auto',
          [breakpointsTheme.breakpoints.up('md')]: {
            width: `${breakpointsTheme.breakpoints.values['sm']}px`,
          },
          [breakpointsTheme.breakpoints.up('lg')]: {
            width: `${breakpointsTheme.breakpoints.values['md']}px`,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: COLORS.black,
          fontFamily: 'ABC Ginto Nord, sans-serif',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'ABC Ginto Nord, sans-serif',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`.${outlinedInputClasses.disabled}`]: {
            backgroundColor: COLORS.grey[100],
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: COLORS.grey[600],
            },
          },
        },
        notchedOutline: {
          borderColor: COLORS.grey[800],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: COLORS.black,
          backgroundColor: 'none',
          fontFamily: 'ABC Ginto Nord, sans-serif',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          border: `${unit(0.25)} solid ${COLORS.grey[600]}`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':before': {
            height: 0,
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'ABC Ginto Nord, sans-serif',
    button: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3.5),
      fontWeight: 'bold',
      lineHeight: unit(6.5),
      letterSpacing: 0.4,
    },
    bodyL: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(4),
      lineHeight: unit(7),
      letterSpacing: 0.4,
    },
    bodyM: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3.5),
      lineHeight: unit(6),
      letterSpacing: 0.4,
    },
    bodyS: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3),
      lineHeight: unit(5),
      letterSpacing: 0.4,
    },
    displayM: {
      fontFamily: 'Moret, serif',
      fontSize: unit(10.5),
      fontWeight: 'bold',
      lineHeight: unit(10.5),
      letterSpacing: 0.2,
    },
    headlineExtraExtraSmall: {
      fontFamily: 'Moret, serif',
      fontSize: unit(5),
      fontWeight: 'bold',
      lineHeight: unit(6),
      letterSpacing: 0.4,
    },
    headlineExtraSmall: {
      fontFamily: 'Moret, serif',
      fontSize: unit(5.5),
      fontWeight: 'bold',
      lineHeight: unit(6.5),
      letterSpacing: 0.4,
    },
    headlineL: {
      fontFamily: 'Moret, serif',
      fontSize: unit(8.5),
      fontWeight: 'bold',
      lineHeight: unit(9.5),
      letterSpacing: 0.4,
    },
    headlineM: {
      fontFamily: 'Moret, serif',
      fontSize: unit(7),
      fontWeight: 'bold',
      lineHeight: unit(8),
      letterSpacing: 0.4,
    },
    headlineS: {
      fontFamily: 'Moret, serif',
      fontSize: unit(6),
      fontWeight: 'bold',
      lineHeight: unit(7),
      letterSpacing: 0.4,
    },
    labelExtraSmall: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(2.5),
      fontWeight: 'bold',
      lineHeight: unit(3.5),
      letterSpacing: 0.4,
    },
    labelL: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(4),
      fontWeight: 'bold',
      lineHeight: unit(7),
      letterSpacing: 0.4,
    },
    labelM: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3.5),
      fontWeight: 'bold',
      lineHeight: unit(6.5),
      letterSpacing: 0.4,
    },
    labelS: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3),
      fontWeight: 'bold',
      lineHeight: unit(6),
      letterSpacing: 0.4,
    },
    meta: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3),
      lineHeight: unit(5),
      letterSpacing: 0.6,
    },
    textLinkM: {
      color: COLORS.burgundi[500],
      cursor: 'pointer',
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3.5),
      fontWeight: 'bold',
      lineHeight: unit(6),
      letterSpacing: 0.4,
      textDecoration: 'underline',
    },
    textLinkS: {
      color: COLORS.burgundi[500],
      cursor: 'pointer',
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3),
      fontWeight: 'bold',
      lineHeight: unit(6),
      letterSpacing: 0.4,
      textDecoration: 'underline',
    },
    titleL: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(4.5),
      fontWeight: 'bold',
      lineHeight: unit(6.5),
      letterSpacing: 0.4,
    },
    titleM: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(4),
      fontWeight: 'bold',
      lineHeight: unit(6),
      letterSpacing: 0.4,
    },
    titleS: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3.5),
      fontWeight: 'bold',
      lineHeight: unit(5.5),
      letterSpacing: 0.4,
    },
    titleExtraSmall: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3),
      fontWeight: 'bold',
      lineHeight: unit(5),
      letterSpacing: 0.2,
    },
    uppercaseExtraSmall: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3),
      fontWeight: 'bold',
      lineHeight: unit(5.5),
      textTransform: 'uppercase',
    },
    uppercaseL: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(4.5),
      fontWeight: 'bold',
      lineHeight: unit(7),
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
    uppercaseS: {
      fontFamily: 'ABC Ginto Nord, sans-serif',
      fontSize: unit(3.5),
      fontWeight: 'bold',
      lineHeight: unit(6),
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
  },
})
