import type { IdTokenClaims } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'

import { isUndefined } from '@hcr/utils'

// Note: The global ID property was incorrectly named "country" on the B2C side.
// This won't be fixed, because "country is used in all apps and this would be a big change".
interface CustomIdTokenClaims extends IdTokenClaims {
  country?: string
}

export const useGlobalId = (): string | null => {
  const msal = useMsal()
  const account = msal.accounts[0]

  if (isUndefined(account) || isUndefined(account.idTokenClaims)) {
    return null
  }

  const idTokenClaims: CustomIdTokenClaims = account.idTokenClaims

  if (isUndefined(idTokenClaims.country)) {
    return null
  }

  return idTokenClaims.country
}
