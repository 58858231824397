import { getYear } from 'date-fns'

import type { HoldingWeekDto } from '@hcr/api/consumer'
import { flow, group, sort } from '@hcr/utils'

import { compareDates } from '../../../../../utils'

export const prepareWeekPreviewsData = flow(
  sort<HoldingWeekDto>({
    comparer: compareDates,
    asc: 'week_start',
  }),
  group(week => String(getYear(new Date(week.week_start))))
)
