import { Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { COLORS, PaperLoading, unit } from '@hcr/ui'

import { LayoutHomeUnauthenticated } from './common'

export const HomeUnauthenticatedLoading: FC = () => {
  return (
    <LayoutHomeUnauthenticated
      description={
        <Stack alignItems='center'>
          <Skeleton width='95%' height={unit(5.5)} sx={{ bgcolor: COLORS.white }} />
          <Skeleton width='85%' height={unit(5.5)} sx={{ bgcolor: COLORS.white }} />
        </Stack>
      }
      imageUrl={''}
      title={
        <Stack alignItems='center'>
          <Skeleton width='80%' height={unit(9.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
        </Stack>
      }
    >
      <PaperLoading />
      <PaperLoading />
    </LayoutHomeUnauthenticated>
  )
}
