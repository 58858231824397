import { Button } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { LinkUnstyled, LoadingState } from '@hcr/ui'
import { isNotNull, isNull } from '@hcr/utils'

import { useUserOnboardingDetailsQuery } from '../../../hooks'
import { OnboardingCompletionSearchParamsKeys, Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutOnboarding } from '../common'

import { OnboardingCompletionForm } from './common'
import { OnboardingCompletionExpired } from './OnboardingCompletionExpired'
import { OnboardingCompletionSuccess } from './OnboardingCompletionSuccess'

export const OnboardingCompletion: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const nonceSearchParam = searchParams.get(OnboardingCompletionSearchParamsKeys.Nonce)

  const userOnboardingDetails = useUserOnboardingDetailsQuery(
    { nonce: String(nonceSearchParam) },
    { enabled: isNotNull(nonceSearchParam) }
  )

  if (isFormSubmitted) {
    return <OnboardingCompletionSuccess />
  }

  if (isNull(nonceSearchParam) || userOnboardingDetails.isError) {
    return <OnboardingCompletionExpired />
  }

  if (userOnboardingDetails.isSuccess) {
    return (
      <LayoutOnboarding
        description={t(
          'onboarding.thank-you-for-confirming-your-email-please-finalize-your-account-by-filling-in-your-address-and-by-creating-a-password'
        )}
        footer={
          <Button component={LinkUnstyled} to={to(Path.Home)} variant='text' color='primary'>
            {t('onboarding.cancel')}
          </Button>
        }
        form={
          <OnboardingCompletionForm
            email={userOnboardingDetails.data.email}
            firstName={userOnboardingDetails.data.first_name}
            lastName={userOnboardingDetails.data.last_name}
            nonce={nonceSearchParam}
            setIsFormSubmitted={setIsFormSubmitted}
          />
        }
        headline={t('onboarding.finalize-your-account')}
        stepper={{ highlight: 2, of: 2 }}
      />
    )
  }

  return <LoadingState />
}
