import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, ServicesDto } from '../../dtos'
import { ServicesDtoSchema } from '../../dtos'

interface ServicesQueryParams {
  destinationId: string
}

export const createServicesQueryKey = (params: ServicesQueryParams) => ['optimizely', 'services', params]

export const createServicesQuery =
  (baseUrl: string) =>
  <Data = ServicesDto>(params: ServicesQueryParams, options?: Partial<UseQueryOptions<ServicesDto, ErrorDto, Data>>) =>
    useQuery<ServicesDto, ErrorDto, Data>({
      ...options,
      queryKey: createServicesQueryKey(params),
      queryFn: () =>
        getJson({
          schema: ServicesDtoSchema,
          url: `${baseUrl}/destinations/${params.destinationId}/services`,
        }),
    })
