import { Box, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

type DividerWithLabelProps = PropsWithChildren

export const DividerWithLabel: FC<DividerWithLabelProps> = ({ children }) => {
  return (
    <Box bgcolor={COLORS.hiekka[500]} paddingY={unit(3.5)} paddingX={unit(7)}>
      <Typography variant='uppercaseS'>{children}</Typography>
    </Box>
  )
}
