import { useMemo } from 'react'

import type { HoldingDto, HoldingWeekDto } from '@hcr/api/consumer'
import { hasPropertyValue, isUndefined } from '@hcr/utils'

interface UseWeekOptions {
  holding: HoldingDto | undefined
  weekStart: string | undefined
}

export const useWeek = ({ holding, weekStart }: UseWeekOptions): HoldingWeekDto | undefined =>
  useMemo(() => {
    if (isUndefined(holding) || isUndefined(weekStart)) {
      return undefined
    }

    return holding.weeks.find(hasPropertyValue('week_start', weekStart))
  }, [holding, weekStart])
