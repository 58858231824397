import { useTranslation } from 'react-i18next'

import { hasPropertyValue } from '@hcr/utils'

import { LOCALE_CONFIG } from '../../configs'
import type { Locale } from '../../models'

export const useLocale = (): Locale => {
  const { i18n } = useTranslation()
  const locale = Object.values(LOCALE_CONFIG.supported).find(hasPropertyValue('lang', i18n.language))

  return locale ?? LOCALE_CONFIG.supported[LOCALE_CONFIG.default]
}
