import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const PricingDtoSchema = z.strictObject({
  component: z.literal('pricing'),
  title: StringDtoSchema,
  description: StringDtoSchema.nullable(),
  footer: StringDtoSchema.nullable(),
  content: z.array(
    z.strictObject({
      label: StringDtoSchema,
      value: StringDtoSchema,
    })
  ),
})

export type PricingDto = z.infer<typeof PricingDtoSchema>

export const isPricingDto = (x: unknown): x is PricingDto => PricingDtoSchema.safeParse(x).success
