import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { getJson } from '@hcr/api/shared'

import type { HoldingDto, ProblemDetailsDto } from '../../dtos'
import { HoldingDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

export const createHoldingsQueryKey = () => ['consumer', 'holdings']

export const createHoldingsQuery =
  (baseUrl: string) =>
  <Data = HoldingDto[]>(
    params: AuthorizedRequestParams,
    options?: Partial<UseQueryOptions<HoldingDto[], ProblemDetailsDto, Data>>
  ) =>
    useQuery<HoldingDto[], ProblemDetailsDto, Data>({
      ...options,
      queryKey: createHoldingsQueryKey(),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(params.idToken),
          schema: z.array(HoldingDtoSchema),
          url: `${baseUrl}/user/holdings`,
        }),
    })
