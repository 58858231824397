import type { FC } from 'react'

import { LayoutTickets, TicketPreviewLoading } from './common'

export const TicketsLoading: FC = () => {
  return (
    <LayoutTickets>
      <TicketPreviewLoading />
      <TicketPreviewLoading />
      <TicketPreviewLoading />
    </LayoutTickets>
  )
}
