import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { AccommodationDto, ProblemDetailsDto } from '../../dtos'
import { AccommodationDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

interface HistoryAccommodationDetailsQueryParams {
  accommodationId: number
}

export const createHistoryAccommodationDetailsQueryKey = (params: HistoryAccommodationDetailsQueryParams) => [
  'consumer',
  'history',
  'accommodations',
  params,
]

export const createHistoryAccommodationDetailsQuery =
  (baseUrl: string) =>
  <Data = AccommodationDto>(
    { idToken, ...params }: AuthorizedRequestParams & HistoryAccommodationDetailsQueryParams,
    options?: Partial<UseQueryOptions<AccommodationDto, ProblemDetailsDto, Data>>
  ) =>
    useQuery<AccommodationDto, ProblemDetailsDto, Data>({
      ...options,
      queryKey: createHistoryAccommodationDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(idToken),
          schema: AccommodationDtoSchema,
          url: `${baseUrl}/history/accommodations/${params.accommodationId}/details`,
        }),
    })
