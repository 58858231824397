import { z } from 'zod'

import { CardPromotionOwnerDtoSchema } from './CardPromotionOwnerDto'
import { CardPromotionRegularDtoSchema } from './CardPromotionRegularDto'

export const CardPromotionDtoSchema = z.discriminatedUnion('variant', [
  CardPromotionOwnerDtoSchema,
  CardPromotionRegularDtoSchema,
])

export type CardPromotionDto = z.infer<typeof CardPromotionDtoSchema>

export const isCardPromotionDto = (x: unknown): x is CardPromotionDto => CardPromotionDtoSchema.safeParse(x).success
