import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'

import { BookingSummaryLoading } from '@hcr/ui'

import { LayoutHistoryDetailsLoading } from '../layout-history-details'

interface HistoryDetailsTicketLoadingProps {
  backNavigationPath: To
}

export const HistoryDetailsTicketLoading: FC<HistoryDetailsTicketLoadingProps> = ({ backNavigationPath }) => {
  const { t } = useTranslation()

  return (
    <LayoutHistoryDetailsLoading backNavigationPath={backNavigationPath}>
      <BookingSummaryLoading
        labels={[
          t('history-details.resort'),
          t('history-details.price'),
          t('history-details.status'),
          t('history-details.validity'),
          t('history-details.booking-date'),
          t('history-details.booking-number'),
        ]}
      />
    </LayoutHistoryDetailsLoading>
  )
}
