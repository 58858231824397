import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { AccommodationDto } from '@hcr/api/consumer'
import { find, flow, getPropertyValue, isNotUndefined } from '@hcr/utils'

import { useDestinationsQuery, useRoomDetailsQuery } from '../../../../../hooks'
import { DateFormat, LocaleNamespace, Path } from '../../../../../models'
import { createRoomNameString, currencyFormatter, dateFormatter, isDestinationOf, to } from '../../../../../utils'

import { HistoryPreview } from './HistoryPreview'
import { HistoryPreviewLoading } from './HistoryPreviewLoading'

interface HistoryPreviewAccommodationProps {
  accommodation: AccommodationDto
}

export const HistoryPreviewAccommodation: FC<HistoryPreviewAccommodationProps> = ({ accommodation }) => {
  const { t } = useTranslation()

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(accommodation))),
  })

  const isRoomDetailsEnabled = isNotUndefined(destination.data?.destinationId)
  const roomDetails = useRoomDetailsQuery(
    { destinationId: String(destination.data?.destinationId), roomId: String(accommodation.room_type_id) },
    { enabled: isRoomDetailsEnabled }
  )

  if (destination.isPending || (isRoomDetailsEnabled && roomDetails.isPending)) {
    return <HistoryPreviewLoading />
  }

  return (
    <HistoryPreview
      key={accommodation.accommodation_id}
      bookingDate={dateFormatter.format(DateFormat.Standard, accommodation.booking_date)}
      destinationName={destination.data?.title ?? t('history.holiday-club-resorts')}
      headerPrimary={roomDetails.isSuccess ? createRoomNameString(roomDetails.data) : t('history.vacation')}
      headerSecondary={dateFormatter.formatDateRange({ from: accommodation.start_date, to: accommodation.end_date })}
      link={to([Path.HistoryDetailsAccommodation, { accommodationId: String(accommodation.accommodation_id) }])}
      price={
        accommodation.status === 'Cancelled'
          ? t(accommodation.status, { ns: LocaleNamespace.AccommodationStatus })
          : currencyFormatter.format(accommodation.total_amount)
      }
    />
  )
}
