import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const UserOnboardingDetailsDtoSchema = z.strictObject({
  first_name: StringDtoSchema,
  last_name: StringDtoSchema,
  email: StringDtoSchema.email(),
})

export type UserOnboardingDetailsDto = z.infer<typeof UserOnboardingDetailsDtoSchema>
