import { Box, CircularProgress, styled } from '@mui/material'
import type { FC } from 'react'

import { unit } from '../../../utils'

export const LoadingState: FC = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${unit(5)};
`
