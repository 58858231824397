import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkExternalDtoSchema } from '../common'
import { AccordionDtoSchema, CardPrimaryDtoSchema, CardPromotionDtoSchema, PricingDtoSchema } from '../components'

export const OwnerDetailsDtoSchema = z.strictObject({
  imageUrl: StringDtoSchema.url(),
  title: StringDtoSchema,
  description: StringDtoSchema.nullable(),
  link: LinkExternalDtoSchema.nullable(),
  content: z.array(z.union([AccordionDtoSchema, CardPrimaryDtoSchema, CardPromotionDtoSchema, PricingDtoSchema])),
})

export type OwnerDetailsDto = z.infer<typeof OwnerDetailsDtoSchema>

export const isOwnerDetailsDto = (x: unknown): x is OwnerDetailsDto => OwnerDetailsDtoSchema.safeParse(x).success
