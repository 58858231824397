import { Box, Divider, Stack, styled } from '@mui/material'

import { COLORS, unit } from '@hcr/ui'

export const LayoutBookingOverview = styled(Box)`
  background-color: ${COLORS.burgundi[500]};
  color: ${COLORS.white};
  padding: ${unit(3)};
`

export const LayoutBookingOverviewHeader = styled(Stack)`
  align-items: center;
  padding: ${unit(11.5)} ${unit(5)} ${unit(5)};
  text-align: center;
`

export const LayoutBookingOverviewContent = styled(Box)`
  background-color: ${COLORS.red.dark};
  padding: ${unit(5)};
`

export const LayoutBookingOverviewDivider = styled(Divider)`
  border-color: ${COLORS.maitokahvi[500]};
`
