import { Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import type { HoldingWeekDto } from '@hcr/api/consumer'
import { COLORS, unit } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { useBuildingDetailsQuery } from '../../../../../hooks'
import { dateFormatter } from '../../../../../utils'
import { WarningMissingData } from '../../../../common'

import { ContractPreviewLoading } from './ContractPreviewLoading'

interface ContractPreviewProps {
  buildingId: string
  holdingName: string
  week: HoldingWeekDto
}

export const ContractPreview: FC<ContractPreviewProps> = ({ buildingId, holdingName, week }) => {
  const buildingDetails = useBuildingDetailsQuery({ buildingId, isContracted: true })

  if (buildingDetails.isError) {
    return <WarningMissingData />
  }

  if (buildingDetails.isSuccess) {
    return (
      <Stack
        padding={unit(4)}
        sx={{
          border: `thin solid ${COLORS.black}`,
          backgroundColor: COLORS.white,
        }}
      >
        <Typography variant='headlineM' color={COLORS.burgundi[500]}>
          {buildingDetails.data.title}
        </Typography>
        <Typography variant='labelL'>
          {dateFormatter.formatDateRange({
            from: week.week_start,
            to: week.week_end,
          })}
        </Typography>
        {isNotNull(buildingDetails.data.destinationName) && (
          <Typography variant='bodyL'>{buildingDetails.data.destinationName}</Typography>
        )}
        <Typography variant='bodyL'>{holdingName}</Typography>
      </Stack>
    )
  }

  return (
    <ContractPreviewLoading
      isActionRequested={week.is_rental_request_received || week.is_reservation_request_received}
    />
  )
}
