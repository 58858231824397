import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgPersonTechnicalError } from '../../../assets'

import { Error } from './Error'

export const Error500: FC = () => {
  const { t } = useTranslation()

  return (
    <Error
      dataAnalyticsId='error-500'
      description={t('error.apologies-try-refreshing')}
      image={<SvgPersonTechnicalError />}
      title={t('error.technical-error')}
    />
  )
}
