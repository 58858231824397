import { Box, styled } from '@mui/material'

export const LayoutBase = styled(Box)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: ${props => props.theme.breakpoints.values['sm']}px;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: ${props => props.theme.breakpoints.values['md']}px;
  }
`
