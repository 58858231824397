import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { HoldingDto } from '@hcr/api/consumer'
import { CardEmbedment, CardPrimary, CardVariant } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { useBuildingDetailsQuery } from '../../../../../../hooks'
import { Path } from '../../../../../../models'
import { createHoldingNameString, to } from '../../../../../../utils'

import { HoldingPreviewLoading } from './HoldingPreviewLoading'

interface HoldingPreviewProps {
  holding: HoldingDto
}

export const HoldingPreview: FC<HoldingPreviewProps> = ({ holding }) => {
  const { t } = useTranslation()

  const buildingDetails = useBuildingDetailsQuery({ buildingId: holding.building_id, isContracted: true })

  if (buildingDetails.isError) {
    return null
  }

  if (buildingDetails.isSuccess) {
    const description = [
      buildingDetails.data.destinationName && buildingDetails.data.title,
      createHoldingNameString(holding),
    ]
      .filter(isNotNull)
      .join('<br />')

    return (
      <CardPrimary
        badge={null}
        description={description}
        embedment={CardEmbedment.Carousel}
        imageUrl={buildingDetails.data.imageUrl}
        link={{
          isExternal: false,
          label: t('owner.more-information'),
          to: to([Path.OwnerHoldingDetails, { holdingId: holding.holding_id }]),
        }}
        title={buildingDetails.data.destinationName ?? buildingDetails.data.title}
        variant={CardVariant.Regular}
      />
    )
  }

  return <HoldingPreviewLoading />
}
