import { Box, Snackbar, styled } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'

import { unit, Z_INDEX } from '@hcr/ui'

import { WarningMissingData } from './WarningMissingData'

export const WarningMissingDataFloating: FC = () => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClose = () => setIsOpen(false)

  return (
    <Container>
      <Content>
        <Snackbar open={isOpen} onClose={handleClose} sx={{ position: 'static' }}>
          <WarningMissingData onClose={handleClose} />
        </Snackbar>
      </Content>
    </Container>
  )
}

const Container = styled(Box)`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.toast};
`

const Content = styled(Box)`
  position: absolute;
  top: ${unit(4)};
  left: ${unit(3)};
  right: ${unit(3)};
`
