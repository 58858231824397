import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Stack, Typography } from '@mui/material'
import { sanitize } from 'dompurify'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ICONS, optimizelyCss, unit } from '@hcr/ui'

import { Drawer } from '../../../common'

interface DestinationDrawerProps {
  description: string
  isOpen: boolean
  onClose: () => void
  resortName: string
  title: string
}

export const DestinationDrawer: FC<DestinationDrawerProps> = ({ description, isOpen, onClose, resortName, title }) => {
  const { t } = useTranslation()

  return (
    <Drawer open={isOpen} onClose={onClose} anchor='bottom'>
      <Stack maxHeight='65vh'>
        <IconButton onClick={onClose} aria-label={t('home.close')} sx={{ alignSelf: 'end' }}>
          <FontAwesomeIcon icon={ICONS.farXmark} />
        </IconButton>
        <Stack>
          <Typography variant='labelM'>{resortName}</Typography>
          <Typography variant='headlineS' marginBottom={unit(3)}>
            {title}
          </Typography>
          <Typography variant='bodyM' css={optimizelyCss} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
        </Stack>
      </Stack>
    </Drawer>
  )
}
