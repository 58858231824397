import type { ReactNode } from 'react'

import type { BenefitsGroupDto } from '@hcr/api/optimizely'

import { BenefitPreview } from './BenefitPreview'

export const createBenefitPreview = () => {
  return (dto: BenefitsGroupDto['content'][number]): ReactNode => {
    return <BenefitPreview key={dto.benefitId} benefit={dto} />
  }
}
