import { Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'

import { COLORS, unit } from '@hcr/ui'

interface LayoutBookingOverviewSectionProps extends PropsWithChildren {
  label: string
}

export const LayoutBookingOverviewSection: FC<LayoutBookingOverviewSectionProps> = ({ children, label }) => {
  return (
    <Stack gap={unit(0.5)} marginY={unit(5)}>
      <Typography variant='titleS' color={COLORS.puuteri[400]}>
        {label}
      </Typography>
      {children}
    </Stack>
  )
}
