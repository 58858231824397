import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, TimeshareIntroductionInfoDto } from '../../dtos'
import { TimeshareIntroductionInfoDtoSchema } from '../../dtos'

export const createTimeshareIntroductionInfoQueryKey = () => ['optimizely', 'timeshare-introduction-info']

export const createTimeshareIntroductionInfoQuery =
  (baseUrl: string) =>
  <Data = TimeshareIntroductionInfoDto>(
    options?: Partial<UseQueryOptions<TimeshareIntroductionInfoDto, ErrorDto, Data>>
  ) =>
    useQuery<TimeshareIntroductionInfoDto, ErrorDto, Data>({
      ...options,
      queryKey: createTimeshareIntroductionInfoQueryKey(),
      queryFn: () =>
        getJson({
          schema: TimeshareIntroductionInfoDtoSchema,
          url: `${baseUrl}/tsIntroductionInfo `,
        }),
    })
