import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { sendJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, UserOnboardingCompletionPayloadDto } from '../../dtos'

export const createUserOnboardingCompletionMutation =
  (baseUrl: string) =>
  (options?: Partial<UseMutationOptions<void, ProblemDetailsDto, UserOnboardingCompletionPayloadDto>>) =>
    useMutation<void, ProblemDetailsDto, UserOnboardingCompletionPayloadDto>({
      ...options,
      mutationFn: body =>
        sendJson({
          body,
          method: 'POST',
          url: `${baseUrl}/user/registration`,
        }),
    })
