import { Button, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CardVariant, COLORS, LinkUnstyled, unit } from '@hcr/ui'

import { useHomeQuery } from '../../hooks'
import { Path } from '../../models'
import { createHomeContent, to } from '../../utils'
import { LayoutNavigationError500 } from '../common'

import { LayoutHome } from './common'
import { HomeEmptyLoading } from './HomeEmptyLoading'

const homeContent = createHomeContent({ slidesMargin: unit(3), variant: CardVariant.Regular })

export const HomeEmpty: FC = () => {
  const { t } = useTranslation()

  const home = useHomeQuery({ isAuthenticated: true })

  if (home.isError) {
    return <LayoutNavigationError500 showContextMenuButton />
  }

  if (home.isSuccess) {
    return (
      <LayoutHome
        imageUrl={home.data.imageUrl}
        header={
          <Stack padding={unit(5)} bgcolor={COLORS.red.dark} color={COLORS.white}>
            <Typography variant='displayM' marginBottom={unit(4)}>
              {home.data.title}
            </Typography>
            <Typography variant='bodyM' marginBottom={unit(7)}>
              {home.data.description}
            </Typography>
            <Button component={LinkUnstyled} to={to(Path.ContextMenuDestinations)} variant='contained' color='primary'>
              {t('home.browse-destinations')}
            </Button>
          </Stack>
        }
        content={home.data.content.map(homeContent)}
      />
    )
  }

  return <HomeEmptyLoading />
}
