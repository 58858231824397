import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import type { DestinationDto } from '@hcr/api/optimizely'
import { COLORS, DividerWithLabel, ICONS, LoadingState, unit } from '@hcr/ui'
import { flow, getPropertyValue, group, isEmpty, isNotUndefined, sort } from '@hcr/utils'

import { useHoliday, useLastPath } from '../../../contexts'
import { useDestinationsQuery } from '../../../hooks'
import { Path } from '../../../models'
import { compareStringsIntl, isServicesPath, to } from '../../../utils'
import { LayoutBase, LayoutNavigationBackError500 } from '../../common'

import { Destination } from './common'

export const ContextMenuDestinations: FC = () => {
  const { t } = useTranslation()
  const lastPath = useLastPath()
  const navigate = useNavigate()
  const holiday = useHoliday()

  const destinations = useDestinationsQuery({
    select: flow(
      getPropertyValue('destinations'),
      sort({ comparer: compareStringsIntl, asc: getPropertyValue('title') }),
      group(({ isSpa }) => (isSpa ? 'isSpa' : 'isNotSpa'))
    ),
  })

  const getBackNavigationPath = useCallback(
    (destinationId?: string): To => {
      if (isNotUndefined(destinationId) && isServicesPath(lastPath.root)) {
        return to([Path.Services, { destinationId }])
      }

      return lastPath.root
    },
    [lastPath.root]
  )

  const createDestination = useCallback(
    ({ description, destinationId, title }: DestinationDto) => (
      <Destination
        key={destinationId}
        description={description}
        isActive={destinationId === holiday.data.destinationId}
        onClick={() => {
          holiday.setDestinationId(destinationId)
          navigate(getBackNavigationPath(destinationId))
        }}
        title={title}
      />
    ),
    [getBackNavigationPath, holiday, navigate]
  )

  if (destinations.isError || (destinations.isSuccess && isEmpty(destinations.data))) {
    return <LayoutNavigationBackError500 to={getBackNavigationPath()} />
  }

  if (destinations.isSuccess) {
    return (
      <LayoutBase>
        <Container>
          <Header>
            <Stack>
              <IconButton
                onClick={() => navigate(getBackNavigationPath())}
                aria-label={t('context-menu.close')}
                sx={{
                  alignSelf: 'end',
                  margin: `${unit(4.5)} ${unit(8)} 0 0`,
                  padding: 0,
                  fontSize: unit(6),
                }}
              >
                <FontAwesomeIcon icon={ICONS.farXmark} />
              </IconButton>
            </Stack>
            <Typography variant='headlineS'>{t('context-menu.holiday-club-destinations')}</Typography>
          </Header>
          {destinations.data.has('isSpa') && (
            <>
              <DividerWithLabel>{t('context-menu.spa-hotels-holiday-houses-and-apartments')}</DividerWithLabel>
              <Box>{destinations.data.get('isSpa')?.map(createDestination)}</Box>
            </>
          )}
          {destinations.data.has('isNotSpa') && (
            <>
              <DividerWithLabel>{t('context-menu.holiday-houses-and-apartments')}</DividerWithLabel>
              <Box>{destinations.data.get('isNotSpa')?.map(createDestination)}</Box>
            </>
          )}
        </Container>
      </LayoutBase>
    )
  }

  return <LoadingState />
}

const Container = styled(Box)`
  min-height: 100vh;
  background-color: ${COLORS.hiekka[500]};
`

const Header = styled(Box)`
  background-color: ${COLORS.vaalea['60%']};
  padding: 0 0 ${unit(3.5)} ${unit(7)};
`
