import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, unit } from '@hcr/ui'

type TicketValidityActiveProps = PropsWithChildren

export const TicketValidityActive: FC<TicketValidityActiveProps> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        <SquareIcon>
          <FontAwesomeIcon icon={ICONS.fassSquare} />
        </SquareIcon>
        <Typography variant='titleS'>{t('tickets.active')}</Typography>
      </Header>
      <Content>
        <Typography variant='headlineL'>{children}</Typography>
      </Content>
    </Container>
  )
}

const Container = styled(Box)`
  color: ${COLORS.semantic.info};
`

const Header = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${unit(2)} 0;
  background-color: ${COLORS.semantic.infoBodyDark};
`

const SquareIcon = styled(Box)`
  font-size: ${unit(1.25)};
  margin-right: ${unit(1.5)};
`

const Content = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${unit(6.5)} 0;
  background-color: ${COLORS.semantic.infoBody};
  text-align: center;
`
