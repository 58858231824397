import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import type { ButtonProps } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLoading } from '@hcr/ui'

type ButtonSignOutProps = Pick<ButtonProps, 'children' | 'color' | 'variant'>

export const ButtonSignOut: FC<ButtonSignOutProps> = ({ children, color, variant }) => {
  const { t } = useTranslation()
  const msal = useMsal()

  return (
    <ButtonLoading
      isLoading={msal.inProgress === InteractionStatus.Logout}
      onClick={() => msal.instance.logoutRedirect()}
      variant={variant}
      color={color}
    >
      {children ?? t('buttons.sign-out')}
    </ButtonLoading>
  )
}
