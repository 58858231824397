import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { AccordionDto } from '@hcr/api/optimizely'

import { Accordion } from './Accordion'

export const createAccordion =
  () =>
  (dto: AccordionDto): ReactNode => <Accordion content={dto.content} key={hash(dto.content[0])} />
