import type { ReactNode } from 'react'

import type { CardDto } from '@hcr/api/optimizely'
import { isCardPrimaryDto } from '@hcr/api/optimizely'

import type { CreateCardPrimaryOptions } from './card-primary'
import { createCardPrimary } from './card-primary'
import type { CreateCardPromotionOptions } from './card-promotion'
import { createCardPromotion } from './card-promotion'

export type CreateCardOptions = CreateCardPrimaryOptions & CreateCardPromotionOptions

export const createCard = ({ createLink, embedment, variant }: CreateCardOptions) => {
  const cardPrimary = createCardPrimary({ createLink, embedment, variant })
  const cardPromotion = createCardPromotion({ createLink, embedment })

  return (dto: CardDto): ReactNode => {
    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    return cardPromotion(dto)
  }
}
