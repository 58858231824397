import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, Typography } from '@mui/material'
import { sanitize } from 'dompurify'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { clampCss, COLORS, ICONS, LinkUnstyled, unit } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { useHoliday } from '../../contexts'
import { useDestinationDetailsQuery } from '../../hooks'
import { EMPTY_PATH_PARAM, Path } from '../../models'
import { createDestinationDetailsContent, to } from '../../utils'

import { DestinationDrawer, DestinationDrawerLink, LayoutHome } from './common'
import { HomeDestinationLoading } from './HomeDestinationLoading'
import { HomeEmpty } from './HomeEmpty'

const destinationDetailsContent = createDestinationDetailsContent({ slidesMargin: unit(3) })

export const HomeDestination: FC = () => {
  const { t } = useTranslation()
  const holiday = useHoliday()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(holiday.data.destinationId) },
    { enabled: isNotNull(holiday.data.destinationId) }
  )

  if (holiday.isError || destinationDetails.isError) {
    return <HomeEmpty />
  }

  if (holiday.isSuccess && destinationDetails.isSuccess) {
    return (
      <>
        <LayoutHome
          imageUrl={destinationDetails.data.imageUrl}
          header={
            <Stack gap={unit(4)}>
              <Stack gap={unit(4)} paddingX={unit(5)}>
                <Box component='img' src={destinationDetails.data.logoUrl} width={unit(10)} height={unit(10)} />
                <Typography variant='displayM' color={COLORS.white}>
                  {destinationDetails.data.name}
                </Typography>
              </Stack>
              <Stack gap={unit(4)} padding={unit(5)} bgcolor={COLORS.red.dark} color={COLORS.white}>
                <Stack direction='row' alignItems='center' gap={unit(2)}>
                  <FontAwesomeIcon icon={ICONS.farInfoCircle} />
                  <Typography variant='titleS'>{t('home.destination-info')}</Typography>
                </Stack>
                <Box>
                  <Typography
                    variant='bodyM'
                    css={clampCss(4)}
                    dangerouslySetInnerHTML={{ __html: sanitize(destinationDetails.data.description) }}
                  />
                  <DestinationDrawerLink onClick={openDrawer}>
                    <Typography variant='textLinkM'>{t('home.read-more')}</Typography>
                    <FontAwesomeIcon icon={ICONS.farArrowDown} />
                  </DestinationDrawerLink>
                </Box>
                <Button
                  component={LinkUnstyled}
                  to={to([Path.Services, { destinationId: holiday.data.destinationId ?? EMPTY_PATH_PARAM }])}
                  variant='contained'
                  color='primary'
                >
                  {t('home.explore-services')}
                </Button>
              </Stack>
            </Stack>
          }
          content={destinationDetails.data.content.map(destinationDetailsContent)}
        />
        <DestinationDrawer
          description={destinationDetails.data.description}
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          resortName={destinationDetails.data.name}
          title={destinationDetails.data.title}
        />
      </>
    )
  }

  return <HomeDestinationLoading />
}
