import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { CarouselLoading, COLORS, unit } from '@hcr/ui'

import { LayoutHome } from './common'

export const HomeEmptyLoading: FC = () => {
  return (
    <LayoutHome
      content={<CarouselLoading slidesMargin={unit(3)} />}
      header={
        <Stack padding={unit(5)} bgcolor={COLORS.red.dark}>
          <Box marginBottom={unit(4)}>
            <Skeleton width='80%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
          </Box>
          <Box marginBottom={unit(7)}>
            <Skeleton width='95%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            <Skeleton width='85%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            <Skeleton width='90%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            <Skeleton width='95%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            <Skeleton width='85%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
          </Box>
          <Skeleton width='100%' height={unit(13)} sx={{ bgcolor: COLORS.burgundi[500] }} variant='rectangular' />
        </Stack>
      }
      imageUrl={''}
    ></LayoutHome>
  )
}
