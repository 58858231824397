import { Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { DividerWithLabelLoading, unit } from '@hcr/ui'

import { LayoutNavigationBack } from '../../../common'
import { WeekPreviewLoading } from '../common'

interface OwnerHoldingWeeksLoadingProps {
  backNavigationPath: To
}

export const OwnerHoldingWeeksLoading: FC<OwnerHoldingWeeksLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
      <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
        <Skeleton width={unit(70)} height={unit(9.5)} variant='rectangular' />
        <Skeleton width={unit(40)} height={unit(6)} />
      </Stack>
      <DividerWithLabelLoading />
      <Stack gap={unit(4.5)} paddingY={unit(4.5)} paddingX={unit(7)}>
        <WeekPreviewLoading />
        <WeekPreviewLoading />
        <WeekPreviewLoading />
      </Stack>
      <DividerWithLabelLoading />
      <Stack gap={unit(4.5)} paddingY={unit(4.5)} paddingX={unit(7)}>
        <WeekPreviewLoading />
        <WeekPreviewLoading />
      </Stack>
    </LayoutNavigationBack>
  )
}
