import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import type { FC, ReactNode } from 'react'

import { LoadingState } from '@hcr/ui'

import { Restricted } from '../restricted'

interface RestrictedProps {
  fallback: ReactNode
  isAuthenticated: boolean
}

export const RestrictedMsal: FC<RestrictedProps> = ({ fallback, isAuthenticated }) => {
  const msal = useMsal()
  const isMsalAuthenticated = useIsAuthenticated()

  if (msal.inProgress !== InteractionStatus.None) {
    return <LoadingState />
  }

  return <Restricted fallback={fallback} isAllowed={isMsalAuthenticated === isAuthenticated} />
}
