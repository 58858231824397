import { Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { COLORS, unit } from '@hcr/ui'

interface ContractPreviewLoadingProps {
  isActionRequested: boolean
}

export const ContractPreviewLoading: FC<ContractPreviewLoadingProps> = ({ isActionRequested }) => {
  return (
    <Stack
      padding={unit(4)}
      sx={{
        border: `thin solid ${COLORS.black}`,
        backgroundColor: COLORS.white,
      }}
    >
      <Skeleton width='95%' height={unit(8)} variant='rectangular' />
      <Skeleton width='85%' height={unit(7)} />
      <Skeleton width='90%' height={unit(7)} />
      <Skeleton width='85%' height={unit(7)} />
    </Stack>
  )
}
