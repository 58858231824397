import { t } from 'i18next'

import { isNotEmpty } from '@hcr/utils'

import type { Guests } from '../../models'

type CreateGuestsStringOptions = Guests

export const createGuestsString = ({ adults, children, universal }: CreateGuestsStringOptions): string | undefined => {
  const strings: string[] = []

  if (adults > 0) {
    strings.push(t('utils.guests-count-adult', { count: adults }))
  }

  if (children > 0) {
    strings.push(t('utils.guests-count-child', { count: children }))
  }

  if (universal > 0) {
    strings.push(t('utils.guests-count-universal', { count: universal }))
  }

  return isNotEmpty(strings) ? strings.join(', ') : undefined
}
