import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { BenefitDetailsDto, ErrorDto } from '../../dtos'
import { BenefitDetailsDtoSchema } from '../../dtos'

interface BenefitDetailsQueryParams {
  benefitId: string
}

export const createBenefitDetailsQueryKey = (params: BenefitDetailsQueryParams) => ['optimizely', 'benefits', params]

export const createBenefitDetailsQuery =
  (baseUrl: string) =>
  <Data = BenefitDetailsDto>(
    params: BenefitDetailsQueryParams,
    options?: Partial<UseQueryOptions<BenefitDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<BenefitDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createBenefitDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: BenefitDetailsDtoSchema,
          url: `${baseUrl}/owners/benefits/${params.benefitId}/details`,
        }),
    })
