import type { FC, PropsWithChildren } from 'react'

import { ConsentSettingsDrawer } from './common'
import { useAccommodationContextChangeTracking } from './hooks'

type AnalyticsProps = PropsWithChildren

export const Analytics: FC<AnalyticsProps> = ({ children }) => {
  useAccommodationContextChangeTracking()

  return (
    <>
      {children}
      <ConsentSettingsDrawer />
    </>
  )
}
