import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgPersonMagnifierError } from '../../../assets'

import { Error } from './Error'

export const Error404: FC = () => {
  const { t } = useTranslation()

  return (
    <Error
      dataAnalyticsId='error-404'
      description={t('error.apologies-page-missing')}
      image={<SvgPersonMagnifierError />}
      title={t('error.page-not-found')}
    />
  )
}
