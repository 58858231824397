import { z } from 'zod'

import { DoubleDtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { MarketCodeDtoSchema } from './MarketCodeDto'

export const PriceDtoSchema = z.strictObject({
  category_id: StringDtoSchema,
  amount: DoubleDtoSchema,
  market_code: MarketCodeDtoSchema.nullable(),
})

export type PriceDto = z.infer<typeof PriceDtoSchema>
