import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, UserOnboardingDetailsDto } from '../../dtos'
import { UserOnboardingDetailsDtoSchema } from '../../dtos'

interface UserOnboardingDetailsQueryParams {
  nonce: string
}

export const createUserOnboardingDetailsQueryKey = (params: UserOnboardingDetailsQueryParams) => [
  'consumer',
  'onboarding',
  params,
]

export const createUserOnboardingDetailsQuery =
  (baseUrl: string) =>
  <Data = UserOnboardingDetailsDto>(
    params: UserOnboardingDetailsQueryParams,
    options?: Partial<UseQueryOptions<UserOnboardingDetailsDto, ProblemDetailsDto, Data>>
  ) =>
    useQuery<UserOnboardingDetailsDto, ProblemDetailsDto, Data>({
      ...options,
      queryKey: createUserOnboardingDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: UserOnboardingDetailsDtoSchema,
          url: `${baseUrl}/user/registration/${params.nonce}/user-information`,
        }),
    })
