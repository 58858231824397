import { Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'

import { ButtonBuyAccommodation } from '../../../../common'

export const BookingListEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Typography variant='bodyM' textAlign='center'>
        {t('booking.no-bookings')}
      </Typography>
      <ButtonBuyAccommodation variant='outlined' color='black' />
    </Container>
  )
}

const Container = styled(Stack)`
  gap: ${unit(4)};
  padding: ${unit(6)} ${unit(4)};
  border: ${unit(0.5)} dashed ${COLORS.semantic.divider};
  width: 100%;
`
