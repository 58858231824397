import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { unit } from '@hcr/ui'

import { LayoutNavigationBack } from '../../../common'

interface OwnerBenefitDetailsLoadingProps {
  backNavigationPath: To
}

export const OwnerBenefitDetailsLoading: FC<OwnerBenefitDetailsLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
      <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
        <Skeleton width='80%' height={unit(9.5)} variant='rectangular' />
        <Skeleton width='40%' height={unit(7)} variant='rectangular' />
        <Box>
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
          <Skeleton width='90%' height={unit(6)} />
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
          <Skeleton width='90%' height={unit(6)} />
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
        </Box>
      </Stack>
    </LayoutNavigationBack>
  )
}
