import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, SingleTicketDto } from '../../dtos'
import { SingleTicketDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

interface SingleTicketsQueryParams {
  resortsIds?: number[]
}

export const createSingleTicketsQueryKey = (params: SingleTicketsQueryParams = {}) => [
  'consumer',
  'single-tickets',
  params,
]

export const createSingleTicketsQuery =
  (baseUrl: string) =>
  <Data = SingleTicketDto[]>(
    { idToken, ...params }: AuthorizedRequestParams & SingleTicketsQueryParams,
    options?: Partial<UseQueryOptions<SingleTicketDto[], ProblemDetailsDto, Data>>
  ) =>
    useQuery<SingleTicketDto[], ProblemDetailsDto, Data>({
      ...options,
      queryKey: createSingleTicketsQueryKey(params),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(idToken),
          schema: z.array(SingleTicketDtoSchema),
          url: `${baseUrl}/single-tickets${encodeSearchParams({ resort_ids: params.resortsIds })}`,
        }),
    })
