import { zodResolver } from '@hookform/resolvers/zod'
import { Box, styled, TextField, Typography } from '@mui/material'
import { type Dispatch, type FC, type SetStateAction } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Alert, ButtonLoading, unit } from '@hcr/ui'
import { isNotEmpty, isNotUndefined, logger } from '@hcr/utils'

import { SENTRY_CONFIG } from '../../../../../configs'
import { useUserOnboardingStartMutation } from '../../../../../hooks'

interface OnboardingFormProps {
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>
}

export const OnboardingForm: FC<OnboardingFormProps> = ({ setIsFormSubmitted }) => {
  const { t } = useTranslation()

  const userOnboardingStart = useUserOnboardingStartMutation()

  const schema = z.strictObject({
    first_name: z
      .string()
      .trim()
      .min(1, { message: t('validation.required') })
      .max(25, { message: t('validation.characters-count-not-exceed', { count: 25 }) })
      .regex(/^\S+$/, { message: t('validation.type-first-name') }),
    last_name: z
      .string()
      .trim()
      .min(1, { message: t('validation.required') })
      .max(25, { message: t('validation.characters-count-not-exceed', { count: 25 }) }),
    email_address: z
      .string()
      .trim()
      .min(1, { message: t('validation.required') })
      .max(50, { message: t('validation.characters-count-not-exceed', { count: 50 }) })
      .email(t('validation.type-email')),
  })

  type Schema = z.infer<typeof schema>

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email_address: '',
    },
  })

  const onSubmit: SubmitHandler<Schema> = async data => {
    try {
      await userOnboardingStart.mutateAsync(data)
      setIsFormSubmitted(true)
    } catch {
      logger.error('Failed to start user onboarding:', data)
    }
  }

  return (
    <Box>
      <Form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <Controller
          control={form.control}
          name='first_name'
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              className={SENTRY_CONFIG.privacy.className}
              value={value}
              onChange={onChange}
              required
              label={t('onboarding.first-name')}
              error={isNotUndefined(error)}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          control={form.control}
          name='last_name'
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              className={SENTRY_CONFIG.privacy.className}
              value={value}
              onChange={onChange}
              required
              label={t('onboarding.last-name')}
              error={isNotUndefined(error)}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          control={form.control}
          name='email_address'
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              className={SENTRY_CONFIG.privacy.className}
              inputMode='email'
              value={value}
              onChange={onChange}
              required
              label={t('onboarding.email-address')}
              error={isNotUndefined(error)}
              helperText={error?.message}
            />
          )}
        />
        <Typography variant='meta' paddingLeft={unit(3)}>
          {t('onboarding.required-information')}
        </Typography>
        {userOnboardingStart.isError && (
          <Alert severity='error' title={t('error.error')} description={t('error.generic')} />
        )}
        <ButtonLoading
          disabled={isNotEmpty(Object.keys(form.formState.errors))}
          isLoading={form.formState.isSubmitting}
          type='submit'
          variant='contained'
          color='primary'
        >
          {t('onboarding.continue')}
        </ButtonLoading>
      </Form>
    </Box>
  )
}

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: ${unit(5)};
`
