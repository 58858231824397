import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Accordion } from '@hcr/ui'

import { LINK_CONFIG } from '../../../../../configs'
import { useLocale } from '../../../../../hooks'

export const BookingTermsAndConditions: FC = () => {
  const { t } = useTranslation()
  const locale = useLocale()

  return (
    <Accordion
      content={[
        {
          title: t('booking-details.terms-of-use'),
          description: `<a href='${LINK_CONFIG.termsOfBookings[locale.id]}' target='_blank' rel='external'>${t(
            'booking-details.you-can-check-general-booking-and-cancellation-terms-here'
          )}</a>`,
        },
      ]}
    />
  )
}
