import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

export const PaperLoading: FC = () => {
  return (
    <Stack gap={unit(3)} padding={unit(8)} bgcolor={COLORS.white} alignItems='flex-start' justifyContent='flex-start'>
      <Skeleton width='80%' height={unit(8)} variant='rectangular' />
      <Box width='100%'>
        <Skeleton width='95%' height={unit(6)} />
        <Skeleton width='85%' height={unit(6)} />
        <Skeleton width='90%' height={unit(6)} />
        <Skeleton width='95%' height={unit(6)} />
        <Skeleton width='85%' height={unit(6)} />
      </Box>
    </Stack>
  )
}
