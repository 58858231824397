export enum DateFormat {
  DayOfTheWeekShort = 'EEEEEE',
  Standard = 'P',
}

export enum TimeFormat {
  Standard = 'p',
}

export enum DateTimeFormat {
  Standard = `${DateFormat.Standard}, ${TimeFormat.Standard}`,
}

export type DateFnsFormat = DateFormat | TimeFormat | DateTimeFormat
