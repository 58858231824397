import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, OwnerDetailsDto } from '../../dtos'
import { OwnerDetailsDtoSchema } from '../../dtos'

interface OwnerDetailsQueryParams {
  ownerId: string
}

export const createOwnerDetailsQueryKey = (params: OwnerDetailsQueryParams) => ['optimizely', 'owners', params]

export const createOwnerDetailsQuery =
  (baseUrl: string) =>
  <Data = OwnerDetailsDto>(
    params: OwnerDetailsQueryParams,
    options?: Partial<UseQueryOptions<OwnerDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<OwnerDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createOwnerDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: OwnerDetailsDtoSchema,
          url: `${baseUrl}/owners/${params.ownerId}/details`,
        }),
    })
