import { Box, Stack } from '@mui/material'
import type { FC } from 'react'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

export interface StepperProps {
  highlight: number
  of: number
}

export const Stepper: FC<StepperProps> = ({ highlight, of }) => {
  const steps = Array.from({ length: of }, (_, index) => (
    <Box
      key={index}
      width={unit(6)}
      height={unit(1)}
      bgcolor={index <= highlight - 1 ? COLORS.semantic.floatingLabel : COLORS.hiekka[500]}
    />
  ))

  return (
    <Stack direction='row' alignItems='center' gap={unit(2)}>
      {steps}
    </Stack>
  )
}
