import { useEffect, useRef } from 'react'
import { useAnalytics } from 'use-analytics'

import { isNotNull, isNull } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import { useAccommodationDetailsQuery, useIdToken } from '../../../hooks'
import { createAccommodationContextChangedEvent } from '../../../utils'

export const useAccommodationContextChangeTracking = (): void => {
  const idToken = useIdToken()
  const holiday = useHoliday()
  const analytics = useAnalytics()
  const accommodationIdRef = useRef<number | null | undefined>(undefined)

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(holiday.data.accommodationId) },
    { enabled: isNotNull(idToken) && isNotNull(holiday.data.accommodationId) }
  )

  useEffect(() => {
    const trackAccommodationContextChange = () => {
      if (holiday.isSuccess && isNull(holiday.data.accommodationId) && isNotNull(accommodationIdRef.current)) {
        accommodationIdRef.current = null
        return analytics.track(...createAccommodationContextChangedEvent({ accommodationId: null }))
      }

      if (accommodation.isSuccess && accommodation.data.accommodation_id !== accommodationIdRef.current) {
        accommodationIdRef.current = accommodation.data.accommodation_id
        return analytics.track(
          ...createAccommodationContextChangedEvent({
            accommodationId: accommodation.data.accommodation_id,
            resortId: accommodation.data.resort_id,
            fromDate: accommodation.data.start_date,
            toDate: accommodation.data.end_date,
          })
        )
      }
    }

    void trackAccommodationContextChange()
  }, [holiday.isSuccess, holiday.data, accommodation.isSuccess, accommodation.data, analytics])
}
