import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema } from '../components'

export const OwnersDtoSchema = z.strictObject({
  imageUrl: StringDtoSchema.url(),
  title: StringDtoSchema,
  description: StringDtoSchema,
  content: z.array(z.union([CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema])),
})

export type OwnersDto = z.infer<typeof OwnersDtoSchema>

export const isOwnersDto = (x: unknown): x is OwnersDto => OwnersDtoSchema.safeParse(x).success
