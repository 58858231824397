import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BookingSummaryLoading } from '@hcr/ui'

export const BookingSummaryAccommodationLoading: FC = () => {
  const { t } = useTranslation()

  return (
    <BookingSummaryLoading
      labels={[
        t('booking-summary-accommodation.destination'),
        t('booking-summary-accommodation.price'),
        t('booking-summary-accommodation.check-in'),
        t('booking-summary-accommodation.check-out'),
        t('booking-summary-accommodation.number-of-visitors'),
        t('booking-summary-accommodation.booking-status'),
        t('booking-summary-accommodation.payment-status'),
        t('booking-summary-accommodation.booking-date'),
        t('booking-summary-accommodation.booking-number'),
      ]}
    />
  )
}
