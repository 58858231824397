import { css } from '@mui/material'
import styledReset from 'emotion-reset'

import {
  ABCGintoNordMediumOtf,
  ABCGintoNordMediumWoff,
  ABCGintoNordMediumWoff2,
  ABCGintoNordRegularOtf,
  ABCGintoNordRegularWoff,
  ABCGintoNordRegularWoff2,
  MoretBoldOtf,
  MoretRegularOtf,
} from '../assets'
import { COLORS } from '../constants'

export const globalCss = css`
  ${styledReset};

  @font-face {
    font-family: 'ABC Ginto Nord';
    font-style: normal;
    font-weight: normal;
    src:
      url(${ABCGintoNordRegularWoff2}) format('woff2'),
      url(${ABCGintoNordRegularWoff}) format('woff'),
      url(${ABCGintoNordRegularOtf}) format('opentype');
  }

  @font-face {
    font-family: 'ABC Ginto Nord';
    font-style: normal;
    font-weight: bold;
    src:
      url(${ABCGintoNordMediumWoff2}) format('woff2'),
      url(${ABCGintoNordMediumWoff}) format('woff'),
      url(${ABCGintoNordMediumOtf}) format('opentype');
  }

  @font-face {
    font-family: 'Moret';
    font-style: normal;
    font-weight: normal;
    src: url(${MoretRegularOtf}) format('opentype');
  }

  @font-face {
    font-family: 'Moret';
    font-style: normal;
    font-weight: bold;
    src: url(${MoretBoldOtf}) format('opentype');
  }

  html {
    overflow-y: scroll;
  }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    font-family: 'ABC Ginto Nord', sans-serif;
    background-color: ${COLORS.vaalea['60%']};
  }

  * {
    box-sizing: border-box;
  }
`
