import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'

import { COLORS, ICONS, unit } from '@hcr/ui'

interface DestinationProps {
  description: string
  isActive: boolean
  onClick: () => void
  title: string
}

export const Destination: FC<DestinationProps> = ({ description, isActive, onClick, title }) => {
  return (
    <Container
      bgcolor={isActive ? COLORS.burgundi[500] : COLORS.vaalea['60%']}
      color={isActive ? COLORS.white : COLORS.black}
      onClick={onClick}
    >
      <Stack>
        <Typography color={isActive ? 'inherit' : COLORS.burgundi[500]} variant='titleM'>
          {title}
        </Typography>
        <Typography variant='bodyM'>{description}</Typography>
      </Stack>
      <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} />
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(4)};
  padding: ${unit(3)} ${unit(9)} ${unit(3)} ${unit(7)};
  cursor: pointer;

  :not(:last-of-type) {
    border-bottom: thin solid ${COLORS.vaalea[600]};
  }
`
