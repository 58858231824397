import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, unit } from '@hcr/ui'
import { isNotNull, isNull } from '@hcr/utils'

import { useHoliday } from '../../contexts'
import { useDestinationDetailsQuery } from '../../hooks'
import { createDestinationDetailsContent } from '../../utils'
import { BookingOverviewCompact, LayoutNavigationError500 } from '../common'

import { DestinationDrawer, DestinationDrawerLink, LayoutHome } from './common'
import { HomeDestination } from './HomeDestination'
import { HomeEmpty } from './HomeEmpty'
import { HomeFallback } from './HomeFallback'
import { HomeLoading } from './HomeLoading'

const destinationDetailsContent = createDestinationDetailsContent({ slidesMargin: unit(3) })

export const Home: FC = () => {
  const { t } = useTranslation()
  const holiday = useHoliday()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(holiday.data.destinationId) },
    { enabled: isNotNull(holiday.data.destinationId) }
  )

  if (holiday.isError) {
    return <LayoutNavigationError500 showContextMenuButton />
  }

  if (destinationDetails.isError) {
    return <HomeFallback />
  }

  if (holiday.isSuccess && isNull(holiday.data.accommodationId) && isNull(holiday.data.destinationId)) {
    return <HomeEmpty />
  }

  if (holiday.isSuccess && isNull(holiday.data.accommodationId)) {
    return <HomeDestination />
  }

  if (holiday.isSuccess && destinationDetails.isSuccess) {
    return (
      <>
        <LayoutHome
          imageUrl={destinationDetails.data.imageUrl}
          header={
            <Stack gap={unit(4)}>
              <Stack gap={unit(4)} paddingX={unit(5)}>
                <Box component='img' src={destinationDetails.data.logoUrl} width={unit(10)} height={unit(10)} />
                <Typography variant='displayM' color={COLORS.white}>
                  {destinationDetails.data.name}
                </Typography>
                <DestinationDrawerLink onClick={openDrawer}>
                  <FontAwesomeIcon icon={ICONS.farInfoCircle} />
                  <Typography variant='textLinkM'>{t('home.destination-info')}</Typography>
                </DestinationDrawerLink>
              </Stack>
              <BookingOverviewCompact />
            </Stack>
          }
          content={destinationDetails.data.content.map(destinationDetailsContent)}
        />
        <DestinationDrawer
          description={destinationDetails.data.description}
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          resortName={destinationDetails.data.name}
          title={destinationDetails.data.title}
        />
      </>
    )
  }

  return <HomeLoading />
}
