import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material'
import { Box, Checkbox as MuiCheckbox } from '@mui/material'
import { forwardRef } from 'react'

import { COLORS, ICONS } from '../../../constants'
import { unit } from '../../../utils'

interface CheckboxProps extends MuiCheckboxProps {
  fontSize?: string
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(({ fontSize = unit(7), ...props }, ref) => (
  <MuiCheckbox
    {...props}
    ref={ref}
    icon={
      <Box fontSize={fontSize}>
        <FontAwesomeIcon icon={ICONS.faslSquare} color={COLORS.black} />
      </Box>
    }
    checkedIcon={
      <Box fontSize={fontSize}>
        <FontAwesomeIcon icon={ICONS.fassSquareCheck} />
      </Box>
    }
    sx={{ padding: unit(2.75) }}
  />
))
