import hash from 'object-hash'

import type { BenefitsGroupDto } from '@hcr/api/optimizely'

import { BenefitsGroup } from './BenefitsGroup'

export const createBenefitsGroup = () => {
  return (dto: BenefitsGroupDto) => {
    return <BenefitsGroup key={hash([dto.title])} benefitsGroup={dto} />
  }
}
