import { t } from 'i18next'

import type { SingleTicketDto } from '@hcr/api/consumer'
import { isNotNull } from '@hcr/utils'

import { DateFormat, DateTimeFormat } from '../../models'
import { dateFormatter } from '../dates'

interface SingleTicketValidity {
  date: string
  label: string
}

// Refactoring opportunity: Move formatting back to components when Consumer API fixes bug with incorrect timestamps for some dates
export const createSingleTicketValidity = (ticket: SingleTicketDto): SingleTicketValidity => {
  switch (ticket.status) {
    case 'Active':
      return {
        date: dateFormatter.format(DateTimeFormat.Standard, String(ticket.activation_date)),
        label: t('utils.tickets-single-active'),
      }
    case 'Inactive':
      return {
        date: dateFormatter.format(DateFormat.Standard, ticket.activation_possible_to_date),
        label: t('utils.tickets-single-inactive'),
      }
    case 'Used':
      return {
        date: isNotNull(ticket.activation_date)
          ? dateFormatter.format(DateTimeFormat.Standard, ticket.activation_date)
          : dateFormatter.format(DateFormat.Standard, ticket.activation_possible_to_date),
        label: t('utils.tickets-single-used'),
      }
  }
}
