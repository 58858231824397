import { z } from 'zod'

import { DoubleDtoSchema, Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { SingleTicketGroupDtoSchema } from './SingleTicketGroupDto'
import { SingleTicketStatusDtoSchema } from './SingleTicketStatusDto'

export const SingleTicketDtoSchema = z.strictObject({
  ticket_id: Int32DtoSchema,
  resort_id: Int32DtoSchema,
  status: SingleTicketStatusDtoSchema,
  activation_possible_from_date: StringDtoSchema.datetime(),
  activation_possible_to_date: StringDtoSchema.datetime(),
  activation_date: StringDtoSchema.datetime().nullable(),
  activation_expiration_date: StringDtoSchema.datetime().nullable(),
  category_id: StringDtoSchema,
  price: DoubleDtoSchema,
  ticket_group: SingleTicketGroupDtoSchema,
})

export type SingleTicketDto = z.infer<typeof SingleTicketDtoSchema>

export const isSingleTicketDto = (x: unknown): x is SingleTicketDto => SingleTicketDtoSchema.safeParse(x).success
