import type { LinkServiceDetailsDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkServiceDetails = (dto: LinkServiceDetailsDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to([Path.ServiceDetails, { destinationId: dto.destinationId, serviceId: dto.serviceId }]),
})
