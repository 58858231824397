import { z } from 'zod'

import { LinkBenefitDetailsDtoSchema } from './LinkBenefitDetailsDto'
import { LinkBenefitsDtoSchema } from './LinkBenefitsDto'
import { LinkBookingsDtoSchema } from './LinkBookingsDto'
import { LinkDestinationsDtoSchema } from './LinkDestinationsDto'
import { LinkExternalDtoSchema } from './LinkExternalDto'
import { LinkGomeddoBookingDtoSchema } from './LinkGomeddoBookingDto'
import { LinkHomeDtoSchema } from './LinkHomeDto'
import { LinkOwnerDetailsDtoSchema } from './LinkOwnerDetailsDto'
import { LinkServiceDetailsDtoSchema } from './LinkServiceDetailsDto'
import { LinkServicesDtoSchema } from './LinkServicesDto'

export const LinkDtoSchema = z.discriminatedUnion('target', [
  LinkBenefitDetailsDtoSchema,
  LinkBenefitsDtoSchema,
  LinkBookingsDtoSchema,
  LinkDestinationsDtoSchema,
  LinkExternalDtoSchema,
  LinkGomeddoBookingDtoSchema,
  LinkHomeDtoSchema,
  LinkOwnerDetailsDtoSchema,
  LinkServiceDetailsDtoSchema,
  LinkServicesDtoSchema,
])

export type LinkDto = z.infer<typeof LinkDtoSchema>

export const isLinkDto = (x: unknown): x is LinkDto => LinkDtoSchema.safeParse(x).success
