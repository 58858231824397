import { z } from 'zod'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkDestinationsDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('destinations'),
})

export type LinkDestinationsDto = z.infer<typeof LinkDestinationsDtoSchema>

export const isLinkDestinationsDto = (x: unknown): x is LinkDestinationsDto =>
  LinkDestinationsDtoSchema.safeParse(x).success
