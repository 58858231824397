import { Box, styled } from '@mui/material'

import { unit } from '@hcr/ui'

const OPACITY = 0.35

export const Background = styled(Box)<{ readonly url: string }>`
  background: linear-gradient(rgba(0, 0, 0, ${OPACITY}), rgba(0, 0, 0, ${OPACITY})), url(${props => props.url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-inline: ${unit(-3)};
  padding: ${unit(19)} ${unit(3)} ${unit(3)};
`
