import { Skeleton } from '@mui/material'
import type { FC } from 'react'

import { COLORS, unit } from '@hcr/ui'

import { BookingDatesLoading, LayoutBookingOverview, LayoutBookingOverviewContent } from '../common'

export const BookingOverviewCompactLoading: FC = () => {
  return (
    <LayoutBookingOverview>
      <LayoutBookingOverviewContent>
        <BookingDatesLoading />
        <Skeleton width='100%' height={unit(13)} sx={{ bgcolor: COLORS.burgundi[500] }} variant='rectangular' />
      </LayoutBookingOverviewContent>
    </LayoutBookingOverview>
  )
}
