import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@hcr/ui'

interface WarningMissingDataProps {
  onClose?: () => void
}

export const WarningMissingData = forwardRef<HTMLDivElement, WarningMissingDataProps>(({ onClose }, ref) => {
  const { t } = useTranslation()

  return (
    <Alert
      severity='warning'
      title={t('fallback-warning.technical-hiccup')}
      description={t('fallback-warning.apologies-certain-information')}
      onClose={onClose}
      ref={ref}
    />
  )
})
