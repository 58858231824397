import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactElement, ReactNode } from 'react'

import { isNotUndefined } from '@hcr/utils'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

interface InformationBannerProps {
  description: ReactElement | string
  header?: string
  icon: ReactNode
}

export const InformationBanner: FC<InformationBannerProps> = ({ description, header, icon }) => {
  return (
    <Container>
      <Box marginTop={unit(1)} color={COLORS.tummakaarna[500]}>
        {icon}
      </Box>
      <Stack gap={unit(0.5)}>
        {isNotUndefined(header) && (
          <Typography variant='uppercaseExtraSmall' color={COLORS.tummakaarna[500]}>
            {header}
          </Typography>
        )}
        <Typography variant='bodyS'>{description}</Typography>
      </Stack>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  background-color: ${COLORS.vaalea['60%']};
  padding: ${unit(4)} ${unit(10)} ${unit(4)} ${unit(5)};
  gap: ${unit(4)};
`
