import type { LinkDestinationsDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkDestinations = (dto: LinkDestinationsDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to(Path.ContextMenuDestinations),
})
