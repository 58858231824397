import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { Error500 } from '../error'

import { LayoutNavigationBack } from './LayoutNavigationBack'

interface LayoutNavigationBackError500Props {
  to: To
}

export const LayoutNavigationBackError500: FC<LayoutNavigationBackError500Props> = ({ to }) => {
  return (
    <LayoutNavigationBack to={to} arrowVariant='text'>
      <Error500 />
    </LayoutNavigationBack>
  )
}
