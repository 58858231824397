import { Box, Skeleton } from '@mui/material'
import type { FC } from 'react'

import { COLORS, unit } from '@hcr/ui'

export const BenefitPreviewLoading: FC = () => {
  return (
    <Box
      paddingTop={unit(3.5)}
      paddingRight={unit(14)}
      paddingBottom={unit(4.5)}
      paddingLeft={unit(4)}
      bgcolor={COLORS.vaalea['60%']}
    >
      <Skeleton width='80%' height={unit(6.5)} variant='rectangular' />
      <Box marginTop={unit(1.5)}>
        <Skeleton width='40%' height={unit(6)} variant='rectangular' />
      </Box>
      <Box marginTop={unit(2.5)}>
        <Skeleton width='95%' height={unit(6)} />
        <Skeleton width='85%' height={unit(6)} />
        <Skeleton width='90%' height={unit(6)} />
      </Box>
    </Box>
  )
}
