import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Stack } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type To } from 'react-router-dom'

import { COLORS, ICONS, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { Drawer, LayoutNavigationBack } from '../../../../common'

interface LayoutOwnerHoldingActionProps extends PropsWithChildren {
  backNavigationPath: To
  drawer?: ReactNode
  header: ReactNode
}

export const LayoutOwnerHoldingAction: FC<LayoutOwnerHoldingActionProps> = ({
  backNavigationPath,
  children,
  drawer,
  header,
}) => {
  const { t } = useTranslation()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  return (
    <>
      <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <Stack
            direction='row'
            alignItems='flex-start'
            justifyContent='space-between'
            gap={unit(2)}
            paddingTop={unit(24)}
            paddingX={unit(7)}
            paddingBottom={unit(4.5)}
          >
            {header}
            {isNotUndefined(drawer) && (
              <IconButton onClick={openDrawer} aria-label={t('owner-holding.info')} color='primary'>
                <FontAwesomeIcon icon={ICONS.farInfoCircle} />
              </IconButton>
            )}
          </Stack>
          <Stack gap={unit(4)} paddingX={unit(7)} paddingBottom={unit(7)}>
            {children}
          </Stack>
        </Box>
      </LayoutNavigationBack>
      {isNotUndefined(drawer) && (
        <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor='bottom'>
          <Stack>
            <IconButton onClick={closeDrawer} aria-label={t('owner-holding.close')} sx={{ alignSelf: 'end' }}>
              <FontAwesomeIcon icon={ICONS.farXmark} />
            </IconButton>
            {drawer}
          </Stack>
        </Drawer>
      )}
    </>
  )
}
