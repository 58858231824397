import { Box, Skeleton, styled, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

type AccordionLoadingProps = PropsWithChildren

export const AccordionLoading: FC<AccordionLoadingProps> = ({ children }) => {
  return (
    <Container>
      <Typography variant='titleM'>
        {children ?? <Skeleton width={unit(55)} height={unit(6)} variant='rectangular' />}
      </Typography>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${COLORS.vaalea['60%']};
  height: ${unit(16)};
  padding: ${unit(2)} ${unit(5)};
`
