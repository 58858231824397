import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { CarouselLoading, COLORS, unit } from '@hcr/ui'

import { LayoutHome } from './common'

export const HomeDestinationLoading: FC = () => {
  return (
    <LayoutHome
      content={<CarouselLoading slidesMargin={unit(3)} />}
      header={
        <Stack gap={unit(4)}>
          <Stack gap={unit(4)} paddingX={unit(5)}>
            <Skeleton width={unit(10)} height={unit(10)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='80%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
          </Stack>
          <Stack gap={unit(4)} padding={unit(5)} bgcolor={COLORS.red.dark}>
            <Skeleton width={unit(30)} height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            <Box>
              <Skeleton width='95%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='85%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='90%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='95%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='85%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            </Box>
            <Skeleton width='100%' height={unit(13)} sx={{ bgcolor: COLORS.burgundi[500] }} variant='rectangular' />
          </Stack>
        </Stack>
      }
      imageUrl={''}
    ></LayoutHome>
  )
}
