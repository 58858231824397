import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material'
import { sanitize } from 'dompurify'
import hash from 'object-hash'
import type { FC } from 'react'
import React, { Fragment, useState } from 'react'

import { COLORS, ICONS } from '../../../constants'
import { optimizelyCss } from '../../../styles'
import { unit } from '../../../utils'

interface AccordionProps {
  content: Array<{
    description: string
    title: string
  }>
}

export const Accordion: FC<AccordionProps> = ({ content }) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <StyledAccordion
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <StyledAccordionSummary expandIcon={<FontAwesomeIcon icon={expanded ? ICONS.fassMinus : ICONS.fassPlus} />}>
        <Typography variant='titleM'>{content[0].title}</Typography>
      </StyledAccordionSummary>
      {content.map(({ description, title }, index) => (
        <Fragment key={hash([title, description])}>
          {index !== 0 && (
            <StyledAccordionDetails>
              <StyledTypography variant='titleM'>{title}</StyledTypography>
            </StyledAccordionDetails>
          )}
          <StyledAccordionDetails>
            <StyledTypography
              variant='bodyL'
              css={optimizelyCss}
              dangerouslySetInnerHTML={{ __html: sanitize(description) }}
            />
          </StyledAccordionDetails>
        </Fragment>
      ))}
    </StyledAccordion>
  )
}

const StyledAccordion = styled(MuiAccordion)`
  background-color: ${COLORS.vaalea['60%']};
`

const StyledAccordionSummary = styled(AccordionSummary)`
  svg {
    width: ${unit(5)};
    height: ${unit(5)};
    color: ${COLORS.black};
  }

  padding: ${unit(2)} ${unit(5)};
  margin: 0;
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 ${unit(5)} ${unit(5)};
  margin: 0;
`

const StyledTypography = styled(Typography)`
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: ${unit(7)};
  }
`
