import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'

import { COLORS, hideEmptyCss, unit } from '@hcr/ui'

import { SvgLogoHolidayClub } from '../../../../assets'
import { Background, ButtonSignIn, ButtonSignUp, LayoutNavigation } from '../../../common'

interface LayoutHomeUnauthenticatedProps extends PropsWithChildren {
  description: ReactNode
  imageUrl: string
  title: ReactNode
}

export const LayoutHomeUnauthenticated: FC<LayoutHomeUnauthenticatedProps> = ({
  children,
  description,
  imageUrl,
  title,
}) => {
  return (
    <LayoutNavigation showContextMenuButton>
      <Box paddingX={unit(3)}>
        <Background url={imageUrl}>
          <Box marginTop={unit(20.5)}>
            <Content>
              <SvgLogoHolidayClub height={unit(2.5)} fill={COLORS.white} />
            </Content>
            <Content>
              <Typography variant='headlineL' width='100%'>
                {title}
              </Typography>
              <Typography variant='titleS' width='100%'>
                {description}
              </Typography>
            </Content>
            <Content>
              <ButtonSignUp variant='contained' color='primary' />
              <ButtonSignIn variant='outlined' color='white' />
            </Content>
          </Box>
        </Background>
      </Box>
      <Stack gap={unit(3)} padding={unit(3)} css={hideEmptyCss}>
        {children}
      </Stack>
    </LayoutNavigation>
  )
}

const Content = styled(Stack)`
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${COLORS.white};
  gap: ${unit(4)};
  margin: ${unit(8)} 0;
`
