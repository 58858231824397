import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { CardPrimaryDto } from '@hcr/api/optimizely'
import { isNotNull } from '@hcr/utils'

import type { OptionsWithCreateLink } from '../../../../models'

import type { CardPrimaryProps } from './CardPrimary'
import { CardPrimary } from './CardPrimary'

export type CreateCardPrimaryOptions = OptionsWithCreateLink & Pick<CardPrimaryProps, 'embedment' | 'variant'>

export const createCardPrimary =
  ({ createLink, embedment, variant }: CreateCardPrimaryOptions) =>
  (dto: CardPrimaryDto): ReactNode => (
    <CardPrimary
      badge={dto.tag}
      description={dto.description}
      embedment={embedment}
      imageUrl={dto.imageUrl}
      key={hash([dto.title, dto.description])}
      link={isNotNull(dto.link) ? createLink(dto.link) : null}
      title={dto.title}
      variant={variant}
    />
  )
