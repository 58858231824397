import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import type { BenefitsDto, ErrorDto } from '../../dtos'
import { BenefitsDtoSchema } from '../../dtos'

interface BenefitsQueryParams {
  isGoldOwner: boolean
  isVillasOwner: boolean
}

export const createBenefitsQueryKey = (params: BenefitsQueryParams) => ['optimizely', 'benefits', params]

export const createBenefitsQuery =
  (baseUrl: string) =>
  <Data = BenefitsDto>(params: BenefitsQueryParams, options?: Partial<UseQueryOptions<BenefitsDto, ErrorDto, Data>>) =>
    useQuery<BenefitsDto, ErrorDto, Data>({
      ...options,
      queryKey: createBenefitsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: BenefitsDtoSchema,
          url: `${baseUrl}/owners/benefits${encodeSearchParams({
            ownership: Object.entries({
              week: true,
              gold: params.isGoldOwner,
              villas: params.isVillasOwner,
            })
              .filter(([, value]) => value)
              .map(([key]) => key),
          })}`,
        }),
    })
