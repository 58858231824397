import type { LinkDto } from '@hcr/api/optimizely'
import {
  isLinkBenefitDetailsDto,
  isLinkBenefitsDto,
  isLinkBookingsDto,
  isLinkDestinationsDto,
  isLinkExternalDto,
  isLinkGomeddoBookingDto,
  isLinkHomeDto,
  isLinkOwnerDetailsDto,
  isLinkServiceDetailsDto,
} from '@hcr/api/optimizely'
import type { Link, OptionsWithCreateLink } from '@hcr/ui'

import { createLinkBenefitDetails } from './createLinkBenefitDetails'
import { createLinkBenefits } from './createLinkBenefits'
import { createLinkBookings } from './createLinkBookings'
import { createLinkDestinations } from './createLinkDestinations'
import { createLinkExternal } from './createLinkExternal'
import { createLinkGomeddoBooking } from './createLinkGomeddoBooking'
import { createLinkHome } from './createLinkHome'
import { createLinkOwnerDetails } from './createLinkOwnerDetails'
import { createLinkServiceDetails } from './createLinkServiceDetails'
import { createLinkServices } from './createLinkServices'

export const createLink: OptionsWithCreateLink['createLink'] = (dto: LinkDto): Link => {
  if (isLinkBenefitDetailsDto(dto)) {
    return createLinkBenefitDetails(dto)
  }

  if (isLinkBenefitsDto(dto)) {
    return createLinkBenefits(dto)
  }

  if (isLinkBookingsDto(dto)) {
    return createLinkBookings(dto)
  }

  if (isLinkDestinationsDto(dto)) {
    return createLinkDestinations(dto)
  }

  if (isLinkExternalDto(dto)) {
    return createLinkExternal(dto)
  }

  if (isLinkGomeddoBookingDto(dto)) {
    return createLinkGomeddoBooking(dto)
  }

  if (isLinkHomeDto(dto)) {
    return createLinkHome(dto)
  }

  if (isLinkOwnerDetailsDto(dto)) {
    return createLinkOwnerDetails(dto)
  }

  if (isLinkServiceDetailsDto(dto)) {
    return createLinkServiceDetails(dto)
  }

  return createLinkServices(dto)
}
