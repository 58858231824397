import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import type { FC } from 'react'
import { Fragment, useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import type { SingleTicketDto } from '@hcr/api/consumer'
import { createSingleTicketsQueryKey, isPriceCategoryIdDto } from '@hcr/api/consumer'
import { Alert, ButtonLoading, Checkbox, InformationBanner, unit } from '@hcr/ui'
import {
  filter,
  find,
  flow,
  getPropertyValue,
  isEmpty,
  isNotEmpty,
  isNotNull,
  isNotUndefined,
  logger,
  sort,
} from '@hcr/utils'

import { SvgPhoneInHand } from '../../../../assets'
import {
  useDestinationsQuery,
  useIdToken,
  useSingleTicketActivationMutation,
  useSingleTicketsQuery,
} from '../../../../hooks'
import type { TicketDetailsSingleSearchParams } from '../../../../models'
import { LocaleNamespace, Path, TicketDetailsSingleSearchParamsKeys } from '../../../../models'
import {
  compareDates,
  createSingleTicketsGuestsString,
  createSingleTicketValidityString,
  isDestinationOf,
  isSingleTicketIdIn,
  to,
} from '../../../../utils'
import { ButtonBuyTicket, LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsInactive, TicketDivider } from '../common'

interface TicketDetailsSingleInactiveBundleProps {
  resortsIds: number[]
  ticketsIds: number[]
}

const BACK_NAVIGATION_PATH = to(Path.Booking)
const STATUS_ACTIVE_SEARCH_PARAM: TicketDetailsSingleSearchParams[TicketDetailsSingleSearchParamsKeys.Status] = 'Active'

export const TicketDetailsSingleInactiveBundle: FC<TicketDetailsSingleInactiveBundleProps> = ({
  resortsIds,
  ticketsIds,
}) => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const [selectedTickets, setSelectedTickets] = useState<SingleTicketDto[]>([])
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const openConfirmation = () => setIsConfirmationOpen(true)
  const cancelConfirmation = () => setIsConfirmationOpen(false)

  const singleTickets = useSingleTicketsQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: flow(
        filter(isSingleTicketIdIn(ticketsIds)),
        sort({ comparer: compareDates, asc: getPropertyValue('activation_possible_to_date') })
      ),
      enabled: isNotNull(idToken),
    }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(singleTickets.data?.[0]))),
    enabled: isNotUndefined(singleTickets.data?.[0]),
  })

  const singleTicketActivation = useSingleTicketActivationMutation({ idToken: String(idToken) })

  type Schema = Record<string, boolean>

  const form = useForm<Schema>()

  useEffect(() => {
    const subscription = form.watch(data => {
      if (isNotUndefined(singleTickets.data)) {
        const ids = Object.entries(data).reduce(
          (ids: number[], [id, isSelected]) => (isSelected ? [...ids, Number(id)] : ids),
          []
        )
        const tickets = singleTickets.data.filter(isSingleTicketIdIn(ids))
        setSelectedTickets(tickets)
      }
    })
    return () => subscription.unsubscribe()
  }, [form, singleTickets.data])

  const onSubmit: SubmitHandler<Schema> = async () => {
    const mutationsResults = await Promise.allSettled(
      selectedTickets.map(ticket =>
        singleTicketActivation.mutateAsync({ ticketId: ticket.ticket_id }).catch(() => ticket.ticket_id)
      )
    )

    await queryClient.invalidateQueries({ queryKey: createSingleTicketsQueryKey() })

    const failedIds = mutationsResults.reduce((ids: number[], result) => {
      const maybePromiseFulfilledResult = result as PromiseFulfilledResult<number>
      return isNotUndefined(maybePromiseFulfilledResult.value) ? [...ids, maybePromiseFulfilledResult.value] : ids
    }, [])

    if (isEmpty(failedIds)) {
      const updatedSearchParams = new URLSearchParams(searchParams)
      updatedSearchParams.set(TicketDetailsSingleSearchParamsKeys.Status, STATUS_ACTIVE_SEARCH_PARAM)
      return setSearchParams(updatedSearchParams)
    }

    if (isNotUndefined(singleTickets.data)) {
      const failedTickets = singleTickets.data.filter(isSingleTicketIdIn(failedIds))
      setSelectedTickets(failedTickets)
      logger.error(`Failed to activate ${failedIds.length}/${mutationsResults.length} tickets:`, failedTickets)
    }

    cancelConfirmation()
  }

  if (singleTickets.isError || (singleTickets.isSuccess && isEmpty(singleTickets.data))) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (singleTickets.isSuccess) {
    return (
      <LayoutTicketDetailsInactive
        additionalInfo={
          isNotUndefined(destination.data) ? [{ label: t('tickets.resort'), value: destination.data.title }] : []
        }
        backNavigationPath={BACK_NAVIGATION_PATH}
        cta={<ButtonBuyTicket resortId={singleTickets.data[0].resort_id} variant='outlined' color='black' />}
        guests={createSingleTicketsGuestsString(singleTickets.data)}
        guestsLabel={t('tickets.available')}
        name={
          isPriceCategoryIdDto(singleTickets.data[0].category_id)
            ? t(singleTickets.data[0].category_id, { ns: LocaleNamespace.PriceCategoryId })
            : singleTickets.data[0].category_id
        }
        type={t('single-bundle', { ns: LocaleNamespace.TicketType })}
      >
        <Stack gap={unit(1.5)}>
          <TicketsForm>
            {singleTickets.data.map(ticket => (
              <Fragment key={ticket.ticket_id}>
                <Ticket>
                  <Stack gap={unit(1)}>
                    <Typography variant='headlineExtraExtraSmall'>
                      {t(ticket.ticket_group, { ns: LocaleNamespace.TicketGroup })}
                    </Typography>
                    <Typography variant='meta'>{createSingleTicketValidityString(singleTickets.data[0])}</Typography>
                  </Stack>
                  <Controller
                    control={form.control}
                    name={String(ticket.ticket_id)}
                    render={({ field }) => <Checkbox {...field} />}
                  />
                </Ticket>
                <TicketDivider />
              </Fragment>
            ))}
          </TicketsForm>
          <InformationBanner
            header={t('tickets.how-to-use')}
            description={t('tickets.how-to-use-single-bundle')}
            icon={<SvgPhoneInHand />}
          />
          {singleTicketActivation.isError && (
            <Alert
              severity='error'
              title={t('tickets.activation-failed')}
              description={t('tickets.an-error-occurred-while-activating-single-bundle')}
            />
          )}
          {isNotEmpty(selectedTickets) && (
            <Typography variant='bodyM' textAlign='center'>
              {t('tickets.selected')}{' '}
              <Typography variant='titleS'>{createSingleTicketsGuestsString(selectedTickets)}</Typography>
            </Typography>
          )}
          {isNotEmpty(selectedTickets) && isConfirmationOpen ? (
            <>
              <ButtonLoading
                onClick={form.handleSubmit(onSubmit)}
                isLoading={form.formState.isSubmitting}
                variant='contained'
                color='success'
              >
                {t('tickets.confirm')}
              </ButtonLoading>
              <Button
                onClick={cancelConfirmation}
                disabled={form.formState.isSubmitting}
                variant='outlined'
                color='black'
              >
                {t('tickets.cancel')}
              </Button>
            </>
          ) : (
            <Button onClick={openConfirmation} disabled={isEmpty(selectedTickets)} variant='contained' color='primary'>
              {t('tickets.activate-tickets')}
            </Button>
          )}
        </Stack>
      </LayoutTicketDetailsInactive>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}

const TicketsForm = styled('form')`
  margin-bottom: ${unit(4)};
`

const Ticket = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${unit(22)};
`
