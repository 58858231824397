import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const LinkUnstyled = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover,
  &:link,
  &:visited {
    text-decoration: none;
  }
`
