import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { unit } from '@hcr/ui'

export const HistoryPreviewLoading: FC = () => {
  return (
    <Box paddingTop={unit(3.5)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
      <Stack gap={unit(0.75)}>
        <Skeleton width={unit(65)} height={unit(5.5)} variant='rectangular' />
        <Skeleton width={unit(35)} height={unit(3.5)} />
        <Skeleton width={unit(55)} height={unit(5)} />
      </Stack>
    </Box>
  )
}
