import * as Sentry from '@sentry/react'
import DOMPurify from 'dompurify'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { isNotNull, logger } from '@hcr/utils'

import { APP_CONFIG, SANITIZE_CONFIG, SENTRY_CONFIG } from './configs'
import { createRouter } from './createRouter'
import { resolveLoaders } from './loaders'

Sentry.init(SENTRY_CONFIG.sentry)

logger.setLogLevel(APP_CONFIG.logLevel)
logger.info(
  `Loma %c${APP_CONFIG.version}`,
  `color: #${Math.floor(Math.random() * 16777215).toString(16)}; font-weight: bold;`
)

DOMPurify.setConfig(SANITIZE_CONFIG)

const root = document.getElementById('root')

if (isNotNull(root)) {
  void resolveLoaders()
    .then(({ msalInstance, mswInstance }) => {
      createRoot(root).render(
        <StrictMode>
          <RouterProvider router={createRouter({ msalInstance, mswInstance })} />
        </StrictMode>
      )
    })
    .catch(error => {
      logger.error(error)
      const message = document.createTextNode('Could not resolve loaders.')
      root.appendChild(message)
    })
} else {
  logger.error('Could not find the root element.')
}
