import { Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { unit } from '@hcr/ui'

export const WeekPreviewLoading: FC = () => {
  return (
    <Stack height={unit(13)} justifyContent='center'>
      <Skeleton width={unit(20)} height={unit(6)} />
      <Skeleton width={unit(35)} height={unit(5)} />
    </Stack>
  )
}
