import type { AlertColor } from '@mui/material'
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle, Typography } from '@mui/material'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export interface AlertProps {
  description: ReactNode
  onClose?: () => void
  severity: AlertColor
  title: string
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(({ description, onClose, severity, title }, ref) => (
  <MuiAlert severity={severity} onClose={onClose} ref={ref}>
    <MuiAlertTitle>
      <Typography variant='labelM'>{title}</Typography>
    </MuiAlertTitle>
    <Typography variant='bodyM'>{description}</Typography>
  </MuiAlert>
))
