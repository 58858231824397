import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { HistoryDto, ProblemDetailsDto } from '../../dtos'
import { HistoryDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

export const createHistoryQueryKey = () => ['consumer', 'history']

export const createHistoryQuery =
  (baseUrl: string) =>
  <Data = HistoryDto>(
    params: AuthorizedRequestParams,
    options?: Partial<UseQueryOptions<HistoryDto, ProblemDetailsDto, Data>>
  ) =>
    useQuery<HistoryDto, ProblemDetailsDto, Data>({
      ...options,
      queryKey: createHistoryQueryKey(),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(params.idToken),
          schema: HistoryDtoSchema,
          url: `${baseUrl}/user/reservations/history`,
        }),
    })
