import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import { CardVariant, COLORS, unit } from '@hcr/ui'

import { useOwnersQuery } from '../../../../hooks'
import { createOwnersContent } from '../../../../utils'
import { Background, LayoutNavigation, LayoutNavigationError500 } from '../../../common'

import { OwnerUncontractedLoading } from './OwnerUncontractedLoading'

const ownersContent = createOwnersContent({
  slidesMargin: unit(3),
  variant: CardVariant.Owner,
})

export const OwnerUncontracted: FC = () => {
  const owners = useOwnersQuery({ isContracted: false })

  if (owners.isError) {
    return <LayoutNavigationError500 />
  }

  if (owners.isSuccess) {
    return (
      <LayoutNavigation>
        <Box paddingX={unit(3)} bgcolor={COLORS.white}>
          <Background url={owners.data.imageUrl}>
            <Stack marginTop={unit(64)} padding={unit(5)} bgcolor={COLORS.ruoho['20%']}>
              <Typography variant='displayM' marginBottom={unit(4)}>
                {owners.data.title}
              </Typography>
              <Typography variant='bodyM' marginBottom={unit(7)}>
                {owners.data.description}
              </Typography>
            </Stack>
          </Background>
          <Stack gap={unit(3)} paddingY={unit(3)}>
            {owners.data.content.map(ownersContent)}
          </Stack>
        </Box>
      </LayoutNavigation>
    )
  }

  return <OwnerUncontractedLoading />
}
