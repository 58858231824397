import { z } from 'zod'

import { CardPrimaryDtoSchema } from './CardPrimaryDto'
import { CardPromotionDtoSchema } from './CardPromotionDto'

export const CardDtoSchema = z.union([CardPrimaryDtoSchema, CardPromotionDtoSchema])

export type CardDto = z.infer<typeof CardDtoSchema>

export const isCardDto = (x: unknown): x is CardDto => CardDtoSchema.safeParse(x).success
