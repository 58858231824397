import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'

import { COLORS, ICONS, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { dateFormatter } from '../../../../../utils'
import type { BookingListPreviewProps } from '../../../../common'

export const BookingListPreview: FC<BookingListPreviewProps> = ({ dateRange, destinationName, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Stack>
        <Typography variant='headlineExtraSmall'>{destinationName}</Typography>
        {isNotUndefined(dateRange) && (
          <Typography variant='meta' fontWeight='initial' color='initial'>
            {dateFormatter.formatDateRange(dateRange)}
          </Typography>
        )}
      </Stack>
      <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} color={COLORS.black} />
    </Container>
  )
}

const Container = styled(Button)`
  background-color: ${COLORS.white};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: ${unit(4)};
  text-align: left;
  padding: ${unit(5)} ${unit(4)};
` as typeof Button
