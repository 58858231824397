import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'

import type { BenefitsGroupDto } from '@hcr/api/optimizely'
import { unit } from '@hcr/ui'

import { createBenefitPreview } from '../benefit-preview'

interface BenefitsGroupProps {
  benefitsGroup: BenefitsGroupDto
}

const benefitPreview = createBenefitPreview()

export const BenefitsGroup: FC<BenefitsGroupProps> = ({ benefitsGroup }) => {
  return (
    <>
      <Box paddingY={unit(4.5)} paddingX={unit(4)}>
        <Typography variant='headlineS'>{benefitsGroup.title}</Typography>
      </Box>
      <Stack gap={unit(1.5)} paddingBottom={unit(3)}>
        {benefitsGroup.content.map(benefitPreview)}
      </Stack>
    </>
  )
}
