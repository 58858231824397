import type { Configuration as MsalConfig } from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'

interface CreateMsalLoaderOptions {
  msalConfig: MsalConfig
}

export const createMsalLoader = async ({ msalConfig }: CreateMsalLoaderOptions) => {
  const msalInstance = new PublicClientApplication(msalConfig)
  await msalInstance.initialize()
  return msalInstance
}
