import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Skeleton, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, unit } from '@hcr/ui'

export const TimeshareIntroductionBoxLoading: FC = () => {
  const { t } = useTranslation()

  return (
    <Stack gap={unit(1.5)} marginY={unit(4)}>
      <Typography variant='uppercaseL'>{t('booking-timeshare-introduction.appointments')}</Typography>

      <Container>
        <Stack>
          <Typography variant='headlineExtraSmall'>
            <Skeleton width={unit(40)} height={unit(6.5)} sx={{ bgcolor: COLORS.grey }} variant='text' />
          </Typography>
          <Typography variant='bodyM' paddingTop={unit(4)}>
            <Skeleton height={unit(6.5)} width={unit(70)} sx={{ bgcolor: COLORS.grey }} variant='text' />
            <Skeleton height={unit(6.5)} width={unit(70)} sx={{ bgcolor: COLORS.grey }} variant='text' />
            <Skeleton height={unit(6.5)} width={unit(70)} sx={{ bgcolor: COLORS.grey }} variant='text' />
          </Typography>
        </Stack>
        <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} color={COLORS.black} />
      </Container>
    </Stack>
  )
}

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${unit(4)};
  padding: ${unit(5)} ${unit(4)};
  text-align: left;
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  font-weight: normal;
`
