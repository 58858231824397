import { z } from 'zod'

import { Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { AccordionDtoSchema } from '../components'

export const RoomDetailsDtoSchema = z.strictObject({
  imageUrl: StringDtoSchema.url(),
  accommodationName: StringDtoSchema,
  realEstateName: StringDtoSchema.nullable(),
  size: Int32DtoSchema,
  description: StringDtoSchema.nullable(),
  checkInHour: Int32DtoSchema.lte(23),
  checkOutHour: Int32DtoSchema.lte(23),
  content: z.array(AccordionDtoSchema),
})

export type RoomDetailsDto = z.infer<typeof RoomDetailsDtoSchema>

export const isRoomDetailsDto = (x: unknown): x is RoomDetailsDto => RoomDetailsDtoSchema.safeParse(x).success
