import { z } from 'zod'

export const ActivityNameDtoSchema = z.union([
  z.literal('Badminton'),
  z.literal('Bowling'),
  z.literal('Golf simulator'),
  z.literal('Padel'),
  z.literal('Rental'),
  z.literal('Squash'),
  z.literal('Tennis'),
])

export type ActivityNameDto = z.infer<typeof ActivityNameDtoSchema>

export const isActivityNameDto = (x: unknown): x is ActivityNameDto => ActivityNameDtoSchema.safeParse(x).success
