import { z } from 'zod'

import { BooleanDtoSchema, DoubleDtoSchema, Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { ActivityStatusDtoSchema } from './ActivityStatusDto'
import { PaymentStatusDtoSchema } from './PaymentStatusDto'

export const ActivityDtoSchema = z.strictObject({
  activity_id: StringDtoSchema,
  resort_id: Int32DtoSchema,
  status: ActivityStatusDtoSchema,
  payment_status: PaymentStatusDtoSchema,
  start_date: StringDtoSchema.datetime(),
  end_date: StringDtoSchema.datetime(),
  booking_date: StringDtoSchema.datetime(),
  total_amount: DoubleDtoSchema,
  is_manual_reservation: BooleanDtoSchema,
  is_activity_share_reservation: BooleanDtoSchema,
  name: StringDtoSchema,
  details: StringDtoSchema,
  description: StringDtoSchema.nullable(),
  cancellation_term: StringDtoSchema.nullable(),
})

export type ActivityDto = z.infer<typeof ActivityDtoSchema>

export const isActivityDto = (x: unknown): x is ActivityDto => ActivityDtoSchema.safeParse(x).success
