import { Button, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, LinkUnstyled, unit } from '@hcr/ui'

import { LINK_CONFIG } from '../../../../configs'
import { useConsentSettings } from '../../../../contexts'
import { useLocale } from '../../../../hooks'
import type { ConsentSettings } from '../../../../models'
import { Consent } from '../../../../models'
import { Drawer } from '../../../common'

export const ConsentSettingsDrawer: FC = () => {
  const { t } = useTranslation()
  const locale = useLocale()
  const consentSettings = useConsentSettings()

  const form = useForm<ConsentSettings>({ values: consentSettings.data })

  const acceptAll: SubmitHandler<ConsentSettings> = async (): Promise<void> => {
    await consentSettings.store({
      [Consent.Analytics]: true,
      [Consent.Marketing]: true,
    })
    consentSettings.close()
  }

  const acceptSelected: SubmitHandler<ConsentSettings> = async (settings): Promise<void> => {
    await consentSettings.store(settings)
    consentSettings.close()
  }

  return (
    <Drawer open={consentSettings.isOpen} anchor='bottom'>
      <Stack gap={unit(4)}>
        <Typography variant='headlineS'>{t('cookies.we-use-cookies')}</Typography>
        <Typography variant='bodyM'>
          {t('cookies.this-website-uses-cookies')}{' '}
          <LinkUnstyled to={LINK_CONFIG.termsOfCookies[locale.id]} target='_blank' rel='external'>
            <Typography variant='textLinkM'>{t('cookies.read-more-about-cookies')}</Typography>
          </LinkUnstyled>
        </Typography>
        <form>
          <FormGroup>
            <FormControlLabel label={t('cookies.cookies-necessary')} control={<Checkbox disabled checked />} />
            <Controller
              control={form.control}
              name={Consent.Analytics}
              render={({ field }) => (
                <FormControlLabel
                  label={t('cookies.cookies-analytics-and-performance')}
                  control={<Checkbox {...field} checked={field.value} />}
                />
              )}
            />
            <Controller
              control={form.control}
              name={Consent.Marketing}
              render={({ field }) => (
                <FormControlLabel
                  label={t('cookies.cookies-marketing')}
                  control={<Checkbox {...field} checked={field.value} />}
                />
              )}
            />
          </FormGroup>
        </form>
        <Button onClick={form.handleSubmit(acceptAll)} variant='contained' color='primary'>
          {t('cookies.accept-all')}
        </Button>
        <Button onClick={form.handleSubmit(acceptSelected)} variant='outlined' color='black'>
          {t('cookies.accept-selected')}
        </Button>
      </Stack>
    </Drawer>
  )
}
