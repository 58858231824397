import type { FC } from 'react'

import { BookingList, ButtonSignOut } from '../../common'

import { BookingListEmpty, BookingListPreview, LayoutContextMenu } from './common'

export const ContextMenu: FC = () => {
  return (
    <LayoutContextMenu
      cta={<ButtonSignOut variant='outlined' color='black' />}
      list={<BookingList empty={BookingListEmpty} preview={BookingListPreview} />}
    />
  )
}
