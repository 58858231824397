import { Box, styled } from '@mui/material'
import type { FC } from 'react'

import { COLORS, unit } from '@hcr/ui'

export const TicketDivider: FC = styled(Box)`
  position: relative;
  margin: 0 ${unit(-9)};
  border-bottom: ${unit(0.5)} dashed ${COLORS.vaalea['60%']};

  &::before,
  &::after {
    position: absolute;
    content: '';
    height: ${unit(4)};
    width: ${unit(4)};
    background-color: ${COLORS.vaalea['60%']};
    border-radius: 50%;
    transform: translateY(50%);
  }

  &::before {
    bottom: 0;
    right: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }
`
