import type { HoldingDto } from '@hcr/api/consumer'
import { filter, flow, getPropertyValue, head, isNotEmpty, map, sort } from '@hcr/utils'

import { compareDates } from '../../../dates'

import { selectNextHoldingWeeks } from './selectNextHoldingWeeks'

export const selectNextHolding: (holdings: HoldingDto[]) => HoldingDto | undefined = flow(
  map(holding => ({
    ...holding,
    weeks: selectNextHoldingWeeks(holding),
  })),
  filter(flow(getPropertyValue('weeks'), isNotEmpty)),
  sort({
    comparer: compareDates,
    asc: holding => holding.weeks[0].week_start,
  }),
  head
)
