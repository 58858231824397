import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ICONS, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { DateFormat } from '../../../../../models'
import { dateFormatter } from '../../../../../utils'
import { LayoutBookingOverviewSection } from '../layout-booking-overview-section'

interface BookingDatesProps {
  checkInDate: string
  checkInHour?: number
  checkOutDate: string
  checkOutHour?: number
}

export const BookingDates: FC<BookingDatesProps> = ({ checkInDate, checkInHour, checkOutDate, checkOutHour }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <LayoutBookingOverviewSection label={t('booking-overview.check-in')}>
        {createFormattedDate(checkInDate, checkInHour)}
      </LayoutBookingOverviewSection>
      <FontAwesomeIcon icon={ICONS.farArrowRight} />
      <Box textAlign='right'>
        <LayoutBookingOverviewSection label={t('booking-overview.check-out')}>
          {createFormattedDate(checkOutDate, checkOutHour)}
        </LayoutBookingOverviewSection>
      </Box>
    </Container>
  )
}

const createFormattedDate = (date: string, hour?: number) => (
  <>
    <Typography variant='headlineS'>
      {dateFormatter.format(DateFormat.DayOfTheWeekShort, date)}{' '}
      {isNotUndefined(hour) && dateFormatter.formatHour(hour)}
    </Typography>
    <Typography variant='headlineS'>{dateFormatter.format(DateFormat.Standard, date)}</Typography>
  </>
)

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${unit(3)};
  margin-top: ${unit(-5)};
`
