import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { DividerWithLabelLoading } from '@hcr/ui'

import { HistoryPreviewLoading } from '../history-preview'

import { LayoutHistory } from './LayoutHistory'

interface LayoutHistoryLoadingProps {
  backNavigationPath: To
}

export const LayoutHistoryLoading: FC<LayoutHistoryLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutHistory backNavigationPath={backNavigationPath}>
      <DividerWithLabelLoading />
      <HistoryPreviewLoading />
      <HistoryPreviewLoading />
      <HistoryPreviewLoading />
      <DividerWithLabelLoading />
      <HistoryPreviewLoading />
      <HistoryPreviewLoading />
    </LayoutHistory>
  )
}
