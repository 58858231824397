import { t } from 'i18next'

import type { CountryDto } from '@hcr/api/consumer'
import { flow, map, sort } from '@hcr/utils'

import { LocaleNamespace } from '../../../../models'
import { compareStringsIntl } from '../../../strings'

export const selectCountriesSortedByTranslationAsc: (countries: CountryDto[]) => Record<CountryDto, string> = flow(
  map((country: CountryDto) => [country, t(country, { ns: LocaleNamespace.Country })]),
  sort({ comparer: compareStringsIntl, asc: ([, translation]) => translation }),
  Object.fromEntries
)
