import { z } from 'zod'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkBenefitsDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('benefits'),
})

export type LinkBenefitsDto = z.infer<typeof LinkBenefitsDtoSchema>

export const isLinkBenefitsDto = (x: unknown): x is LinkBenefitsDto => LinkBenefitsDtoSchema.safeParse(x).success
