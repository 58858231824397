import type { AccommodationDto, ActivityDto, SingleTicketDto } from '@hcr/api/consumer'
import { hasPropertyValue, isUndefined } from '@hcr/utils'

export const isBookingInResortsOf =
  <
    T1 extends AccommodationDto | SingleTicketDto | ActivityDto,
    T2 extends AccommodationDto | SingleTicketDto | ActivityDto,
  >(
    bookings: T1[] | undefined
  ) =>
  (booking: T2): boolean => {
    if (isUndefined(bookings)) {
      return false
    }

    return bookings.some(hasPropertyValue('resort_id', booking.resort_id))
  }
