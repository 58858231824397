import { Skeleton } from '@mui/material'
import type { FC } from 'react'

import { unit } from '../../../utils'

import { DividerWithLabel } from './DividerWithLabel'

export const DividerWithLabelLoading: FC = () => {
  return (
    <DividerWithLabel>
      <Skeleton width={unit(10)} height={unit(6)} />
    </DividerWithLabel>
  )
}
