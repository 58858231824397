export enum LocaleNamespace {
  AccommodationStatus = 'accommodationStatus',
  ActivityName = 'activityName',
  ActivityPaymentStatus = 'activityPaymentStatus',
  Country = 'country',
  Main = 'main',
  PaymentStatus = 'paymentStatus',
  PriceCategoryId = 'priceCategoryId',
  TicketGroup = 'ticketGroup',
  TicketStatus = 'ticketStatus',
  TicketType = 'ticketType',
}
