import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoSchema } from '../common'

import { CardDtoBaseSchema } from './CardDtoBase'

export const CardPromotionDtoBaseSchema = CardDtoBaseSchema.extend({
  component: z.literal('card-promotion'),
  title: StringDtoSchema,
  link: LinkDtoSchema.nullable(),
})
