import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { CarouselLoading, COLORS, unit } from '@hcr/ui'

import { Background, LayoutNavigation } from '../../../common'

import { HoldingsLoading, NextWeekLoading } from './common'

export const OwnerLoading: FC = () => {
  return (
    <LayoutNavigation>
      <Box paddingX={unit(3)} bgcolor={COLORS.white}>
        <Background url=''>
          <Stack marginTop={unit(64)} padding={unit(5)} bgcolor={COLORS.red.dark}>
            <Box marginBottom={unit(4)}>
              <Skeleton width='80%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            </Box>
            <Box marginBottom={unit(7)}>
              <Skeleton width='95%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='85%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='90%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='95%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
              <Skeleton width='85%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
            </Box>
          </Stack>
        </Background>
        <Stack gap={unit(3)} paddingY={unit(3)}>
          <NextWeekLoading />
          <HoldingsLoading />
          <CarouselLoading slidesMargin={unit(3)} />
        </Stack>
      </Box>
    </LayoutNavigation>
  )
}
