import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgPersonMail } from '../../../assets'
import { LayoutOnboardingInformation } from '../common'

export const OnboardingSuccess: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutOnboardingInformation
      description={t('onboarding.please-confirm-your-email-address')}
      headline={t('onboarding.check-your-email-inbox')}
      image={<SvgPersonMail />}
    />
  )
}
