import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Skeleton, Stack, styled } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, unit } from '@hcr/ui'

import { LayoutBookingOverviewSection } from '../layout-booking-overview-section'

export const BookingDatesLoading: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <LayoutBookingOverviewSection label={t('booking-overview.check-in')}>
        <Skeleton width={unit(20)} height={unit(7)} sx={{ bgcolor: COLORS.white }} />
        <Skeleton width={unit(25)} height={unit(7)} sx={{ bgcolor: COLORS.white }} />
      </LayoutBookingOverviewSection>
      <FontAwesomeIcon icon={ICONS.farArrowRight} />
      <Box textAlign='right'>
        <LayoutBookingOverviewSection label={t('booking-overview.check-out')}>
          <Stack alignItems='end'>
            <Skeleton width={unit(20)} height={unit(7)} sx={{ bgcolor: COLORS.white }} />
          </Stack>
          <Skeleton width={unit(25)} height={unit(7)} sx={{ bgcolor: COLORS.white }} />
        </LayoutBookingOverviewSection>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${unit(3)};
  margin-top: ${unit(-5)};
`
