import { z } from 'zod'

import { ActivityDtoSchema } from './ActivityDto'

export const ActivityHistoryDtoSchema = z.strictObject({
  ...ActivityDtoSchema.shape,
})

export type ActivityHistoryDto = z.infer<typeof ActivityHistoryDtoSchema>

export const isActivityHistoryDto = (x: unknown): x is ActivityHistoryDto =>
  ActivityHistoryDtoSchema.safeParse(x).success
