import { z } from 'zod'

import { CardPromotionDtoBaseSchema } from './CardPromotionDtoBase'

export const CardPromotionRegularDtoSchema = CardPromotionDtoBaseSchema.extend({
  variant: z.literal('regular'),
})

export type CardPromotionRegularDto = z.infer<typeof CardPromotionRegularDtoSchema>

export const isCardPromotionRegularDto = (x: unknown): x is CardPromotionRegularDto =>
  CardPromotionRegularDtoSchema.safeParse(x).success
