import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { PaperDto } from '@hcr/api/optimizely'

import { Paper } from './Paper'

export const createPaper =
  () =>
  (dto: PaperDto): ReactNode => (
    <Paper description={dto.description} key={hash([dto.title, dto.description])} title={dto.title} />
  )
