import { isNull } from '@hcr/utils'

import { MOCKS_CONFIG } from '../configs'
import type { MocksSettings } from '../models'
import { isMocksSettings } from '../models'

export const loadMocksSettings = (): MocksSettings => {
  const storageItem = window.sessionStorage.getItem(MOCKS_CONFIG.storageKeyName)

  if (isNull(storageItem)) {
    return MOCKS_CONFIG.default
  }

  const maybeMocksSettings = JSON.parse(storageItem)

  if (isMocksSettings(maybeMocksSettings)) {
    return maybeMocksSettings
  }

  return MOCKS_CONFIG.default
}
