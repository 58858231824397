import type { LinkHomeDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkHome = (dto: LinkHomeDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to(Path.Home),
})
