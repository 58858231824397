import { t } from 'i18next'

import type { ActivityDto } from '@hcr/api/consumer'

import { DateTimeFormat, TimeFormat } from '../../models'
import { dateFormatter } from '../dates'

interface ActivityValidity {
  date: string
  label: string
}

export const createActivityValidity = (activity: ActivityDto, label?: string): ActivityValidity => ({
  date: dateFormatter.formatDateRange({
    from: activity.start_date,
    fromFormat: DateTimeFormat.Standard,
    to: activity.end_date,
    toFormat: TimeFormat.Standard,
  }),
  label: label || t('utils.tickets-activity'),
})
