import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkExternalDtoSchema, LinkGomeddoBookingDtoSchema } from '../common'
import {
  AccordionDtoSchema,
  CardPrimaryDtoSchema,
  CardPromotionDtoSchema,
  CarouselDtoSchema,
  PricingDtoSchema,
} from '../components'

export const ServiceDetailsDtoSchema = z.strictObject({
  destinationId: StringDtoSchema,
  serviceId: StringDtoSchema,
  imageUrl: StringDtoSchema.url(),
  title: StringDtoSchema,
  description: StringDtoSchema,
  link: z.union([LinkExternalDtoSchema, LinkGomeddoBookingDtoSchema]).nullable(),
  content: z.array(
    z.union([AccordionDtoSchema, CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema, PricingDtoSchema])
  ),
})

export type ServiceDetailsDto = z.infer<typeof ServiceDetailsDtoSchema>

export const isServiceDetailsDto = (x: unknown): x is ServiceDetailsDto => ServiceDetailsDtoSchema.safeParse(x).success
