import { format as formatDate } from 'date-fns'

import type { DateFnsFormat } from '../../models'

import { getCurrentLocale } from './getCurrentLocale'

export const format = (format: DateFnsFormat, date: Date | string): string => {
  const locale = getCurrentLocale()

  return formatDate(date, format, { locale })
}
