import type { SerializedStyles } from '@emotion/react'
import { css } from '@mui/material'

import { COLORS } from '../../../../constants'
import { unit } from '../../../../utils'
import { CardEmbedment, CardVariant } from '../types'

export const CONTENT_GAP = 2.5
export const CONTENT_PADDING = 5
export const IMAGE_HEIGHT = 40

export const cardEmbedmentCss: Record<CardEmbedment, SerializedStyles> = {
  [CardEmbedment.Carousel]: css`
    height: ${unit(99)};
    width: ${unit(70)};
  `,
  [CardEmbedment.Standalone]: css`
    height: auto;
    width: 100%;
  `,
}

export const cardVariantCss: Record<CardVariant, SerializedStyles> = {
  [CardVariant.Owner]: css`
    background-color: ${COLORS.white};
  `,
  [CardVariant.Regular]: css`
    background-color: ${COLORS.vaalea['60%']};
  `,
}

export const overflowGradientVariantCss: Record<CardVariant, SerializedStyles> = {
  [CardVariant.Owner]: css`
    background: linear-gradient(to bottom, transparent 75%, ${COLORS.white});
  `,
  [CardVariant.Regular]: css`
    background: linear-gradient(to bottom, transparent 75%, ${COLORS.vaalea['60%']});
  `,
}
