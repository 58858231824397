import type { ReactNode } from 'react'

import type { RoomDetailsDto } from '@hcr/api/optimizely'
import { createAccordion } from '@hcr/ui'

export const createRoomDetailsContent = () => {
  const accordion = createAccordion()

  return (dto: RoomDetailsDto['content'][number]): ReactNode => {
    return accordion(dto)
  }
}
