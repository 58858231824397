import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema, PaperDtoSchema } from '../components'

export const HomeDtoSchema = z.strictObject({
  imageUrl: StringDtoSchema.url(),
  title: StringDtoSchema,
  description: StringDtoSchema,
  content: z.array(z.union([CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema, PaperDtoSchema])),
})

export type HomeDto = z.infer<typeof HomeDtoSchema>

export const isHomeDto = (x: unknown): x is HomeDto => HomeDtoSchema.safeParse(x).success
