import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { unit } from '@hcr/ui'

import { BenefitPreviewLoading } from '../benefit-preview'

export const BenefitsGroupLoading: FC = () => {
  return (
    <>
      <Box paddingY={unit(4.5)} paddingX={unit(4)}>
        <Skeleton width='80%' height={unit(7)} variant='rectangular' />
      </Box>
      <Stack gap={unit(1.5)} paddingBottom={unit(3)}>
        <BenefitPreviewLoading />
        <BenefitPreviewLoading />
        <BenefitPreviewLoading />
      </Stack>
    </>
  )
}
