import type { LinkBookingsDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkBookings = (dto: LinkBookingsDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to(Path.Booking),
})
