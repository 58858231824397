import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { sendJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, SingleTicketActivationPayloadDto } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

export const createSingleTicketActivationMutation =
  (baseUrl: string) =>
  (
    { idToken }: AuthorizedRequestParams,
    options?: Partial<UseMutationOptions<void, ProblemDetailsDto, SingleTicketActivationPayloadDto>>
  ) =>
    useMutation<void, ProblemDetailsDto, SingleTicketActivationPayloadDto>({
      ...options,
      mutationFn: ({ ticketId }) =>
        sendJson({
          headers: createAuthorizationHeader(idToken),
          method: 'PUT',
          url: `${baseUrl}/single-tickets/${ticketId}/activate`,
        }),
    })
