import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { AccommodationStatusDto, PaymentStatusDto } from '@hcr/api/consumer'
import { BookingSummary } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { DateFormat, LocaleNamespace } from '../../../models'
import { currencyFormatter, dateFormatter } from '../../../utils'

interface BookingSummaryAccommodationProps {
  accommodationId: number
  accommodationStatus: AccommodationStatusDto
  bookingDate: string
  checkInDate: string
  checkInHour?: number
  checkOutDate: string
  checkOutHour?: number
  destinationName?: string
  guests?: string
  isOwnerUsage?: boolean
  paymentStatus: PaymentStatusDto
  // TODO 128: Restore price summaries on booking details when a bug in HotelLinx is fixed
  //  https://hcrdevelopment.atlassian.net/browse/SII-128
  //  - Remove price property as it will be handled by <BookingSummaryPriceLoading /> in the parent component
  price: number
}

export const BookingSummaryAccommodation: FC<BookingSummaryAccommodationProps> = ({
  accommodationId,
  accommodationStatus,
  bookingDate,
  checkInDate,
  checkInHour,
  checkOutDate,
  checkOutHour,
  destinationName,
  guests,
  paymentStatus,
  price,
  isOwnerUsage,
}) => {
  const { t } = useTranslation()

  return (
    <BookingSummary
      content={[
        ...(isNotUndefined(destinationName)
          ? [
              {
                label: t('booking-summary-accommodation.destination'),
                value: destinationName,
              },
            ]
          : []),
        {
          label: t('booking-summary-accommodation.price'),
          value: currencyFormatter.format(price),
        },
        {
          label: t('booking-summary-accommodation.check-in'),
          value:
            dateFormatter.format(DateFormat.Standard, checkInDate) +
            (isNotUndefined(checkInHour) ? `, ${dateFormatter.formatHour(checkInHour)}` : ''),
        },
        {
          label: t('booking-summary-accommodation.check-out'),
          value:
            dateFormatter.format(DateFormat.Standard, checkOutDate) +
            (isNotUndefined(checkOutHour) ? `, ${dateFormatter.formatHour(checkOutHour)}` : ''),
        },
        ...(isNotUndefined(guests)
          ? [
              {
                label: t('booking-summary-accommodation.number-of-visitors'),
                value: guests,
              },
            ]
          : []),
        {
          label: t('booking-summary-accommodation.booking-status'),
          value: t(accommodationStatus, { ns: LocaleNamespace.AccommodationStatus }),
        },
        {
          label: t('booking-summary-accommodation.payment-status'),
          value: isOwnerUsage
            ? t('booking-summary-accommodation.owner-payment')
            : t(paymentStatus, { ns: LocaleNamespace.PaymentStatus }),
        },
        {
          label: t('booking-summary-accommodation.booking-date'),
          value: dateFormatter.format(DateFormat.Standard, bookingDate),
        },
        {
          label: t('booking-summary-accommodation.booking-number'),
          value: accommodationId,
        },
      ]}
    />
  )
}
