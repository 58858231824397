import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { getJson } from '@hcr/api/shared'

import type { CountryDto, ProblemDetailsDto } from '../../dtos'
import { CountryDtoSchema } from '../../dtos'

export const createCountriesQueryKey = () => ['consumer', 'countries']

export const createCountriesQuery =
  (baseUrl: string) =>
  <Data = CountryDto[]>(options?: Partial<UseQueryOptions<CountryDto[], ProblemDetailsDto, Data>>) =>
    useQuery<CountryDto[], ProblemDetailsDto, Data>({
      ...options,
      queryKey: createCountriesQueryKey(),
      queryFn: () =>
        getJson({
          schema: z.array(CountryDtoSchema),
          url: `${baseUrl}/countries`,
        }),
    })
