import type { Locale } from 'date-fns'
import { enUS, fi } from 'date-fns/locale'

import { LOCALE_CONFIG } from '../../configs'

const localeMap = {
  'en-US': enUS,
  fi: fi,
}

export function getCurrentLocale(): Locale {
  const currentLocale = LOCALE_CONFIG.supported[LOCALE_CONFIG.default]
  const locale = localeMap[currentLocale.id]

  if (!locale) {
    return fi
  }

  return locale
}
