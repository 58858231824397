import type { RedirectRequest } from '@azure/msal-browser'
import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { ButtonProps } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLoading, ICONS } from '@hcr/ui'

interface ButtonSignInProps extends Pick<ButtonProps, 'children' | 'color' | 'variant'> {
  request?: RedirectRequest
}

export const ButtonSignIn: FC<ButtonSignInProps> = ({ children, color, request, variant }) => {
  const { t } = useTranslation()
  const msal = useMsal()

  return (
    <ButtonLoading
      isLoading={msal.inProgress === InteractionStatus.Login}
      onClick={() => msal.instance.loginRedirect(request)}
      endIcon={<FontAwesomeIcon icon={ICONS.farArrowRight} />}
      variant={variant}
      color={color}
    >
      {children ?? t('buttons.sign-in')}
    </ButtonLoading>
  )
}
