import { z } from 'zod'

import { Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

export const BenefitsGroupDtoSchema = z.strictObject({
  component: z.literal('benefits-list'),
  title: StringDtoSchema,
  content: z.array(
    z.strictObject({
      benefitId: Int32DtoSchema,
      title: StringDtoSchema,
      subtitle: StringDtoSchema,
      description: StringDtoSchema,
    })
  ),
})

export type BenefitsGroupDto = z.infer<typeof BenefitsGroupDtoSchema>

export const isBenefitsGroupDto = (x: unknown): x is BenefitsGroupDto => BenefitsGroupDtoSchema.safeParse(x).success
