import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema } from '../components'

import { DestinationDetailsDtoBaseSchema } from './DestinationDetailsDtoBase'

export const DestinationDetailsDtoSchema = DestinationDetailsDtoBaseSchema.extend({
  address: StringDtoSchema,
  imageUrl: StringDtoSchema.url(),
  logoUrl: StringDtoSchema.url(),
  name: StringDtoSchema,
  content: z.array(z.union([CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema])),
})

export type DestinationDetailsDto = z.infer<typeof DestinationDetailsDtoSchema>

export const isDestinationDetailsDto = (x: unknown): x is DestinationDetailsDto =>
  DestinationDetailsDtoSchema.safeParse(x).success
