import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgMailExpired } from '../../../assets'
import { Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutOnboardingInformation } from '../common'

export const OnboardingCompletionExpired: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutOnboardingInformation
      description={t(
        'onboarding.the-verification-link-which-was-sent-to-your-email-address-has-expired-or-is-already-used'
      )}
      headline={t('onboarding.verification-link-has-expired')}
      image={<SvgMailExpired />}
      button={{
        label: t('onboarding.start-over'),
        to: to(Path.Onboarding),
      }}
    />
  )
}
