import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { AccountDto, ErrorDto } from '../../dtos'
import { AccountDtoSchema } from '../../dtos'

export const createAccountQueryKey = () => ['optimizely', 'account']

export const createAccountQuery =
  (baseUrl: string) =>
  <Data = AccountDto>(options?: Partial<UseQueryOptions<AccountDto, ErrorDto, Data>>) =>
    useQuery<AccountDto, ErrorDto, Data>({
      ...options,
      queryKey: createAccountQueryKey(),
      queryFn: () =>
        getJson({
          schema: AccountDtoSchema,
          url: `${baseUrl}/account`,
        }),
    })
