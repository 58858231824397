import type { ReactNode } from 'react'

import type { CardPromotionDto } from '@hcr/api/optimizely'
import { isCardPromotionOwnerDto } from '@hcr/api/optimizely'

import type { CreateCardPromotionOwnerOptions } from './createCardPromotionOwner'
import { createCardPromotionOwner } from './createCardPromotionOwner'
import type { CreateCardPromotionRegularOptions } from './createCardPromotionRegular'
import { createCardPromotionRegular } from './createCardPromotionRegular'

export type CreateCardPromotionOptions = CreateCardPromotionOwnerOptions & CreateCardPromotionRegularOptions

export const createCardPromotion = (options: CreateCardPromotionOptions) => {
  const cardPromotionOwner = createCardPromotionOwner(options)
  const cardPromotionRegular = createCardPromotionRegular(options)

  return (dto: CardPromotionDto): ReactNode => {
    if (isCardPromotionOwnerDto(dto)) {
      return cardPromotionOwner(dto)
    }

    return cardPromotionRegular(dto)
  }
}
