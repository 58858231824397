import type { RedirectRequest, SilentRequest } from '@azure/msal-browser'
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'

import { isEmpty, isNull, logger } from '@hcr/utils'

import { AUTH_CONFIG } from '../../../configs'

// Note: Accounts and token claims returned by useMsal() are not updated on token refresh. For this reason, the token must be silently refreshed before each request (i.e. using a regular hook without a context).
export const useIdToken = (): string | null => {
  const msal = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [idToken, setIdToken] = useState<string | null>(null)

  useEffect(() => {
    const acquireIdToken = async (): Promise<void> => {
      if (isEmpty(msal.accounts)) {
        return void setIdToken(null)
      }

      const request: SilentRequest & RedirectRequest = {
        account: msal.accounts[0],
        scopes: [],
      }

      try {
        const { idToken } = await msal.instance.acquireTokenSilent({
          ...request,
          redirectUri: AUTH_CONFIG.token.silentRedirectUri,
        })

        window.localStorage.removeItem(AUTH_CONFIG.token.hasTriedRedirectFlagName)
        return void setIdToken(idToken)
      } catch (error) {
        logger.error(error)

        if (error instanceof InteractionRequiredAuthError) {
          const hasTriedRedirect = window.localStorage.getItem(AUTH_CONFIG.token.hasTriedRedirectFlagName)

          if (isNull(hasTriedRedirect)) {
            window.localStorage.setItem(AUTH_CONFIG.token.hasTriedRedirectFlagName, JSON.stringify(true))
            return msal.instance.acquireTokenRedirect({ ...request, claims: error.claims }).catch(logger.error)
          }
        }

        window.localStorage.removeItem(AUTH_CONFIG.token.hasTriedRedirectFlagName)
        return msal.instance.logoutRedirect(request).catch(logger.error)
      }
    }

    if (isAuthenticated && msal.inProgress === InteractionStatus.None) {
      void acquireIdToken()
    }
  }, [isAuthenticated, msal.accounts, msal.inProgress, msal.instance])

  return idToken
}
