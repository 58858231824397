import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const AccordionDtoSchema = z.strictObject({
  component: z.literal('accordion'),
  content: z.array(
    z.strictObject({
      title: StringDtoSchema,
      description: StringDtoSchema,
    })
  ),
})

export type AccordionDto = z.infer<typeof AccordionDtoSchema>

export const isAccordionDto = (x: unknown): x is AccordionDto => AccordionDtoSchema.safeParse(x).success
