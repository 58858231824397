import type { FC } from 'react'

import { CardVariant, unit } from '@hcr/ui'

import { useHomeQuery } from '../../hooks'
import { createHomeContent } from '../../utils'
import { LayoutNavigationError500 } from '../common'

import { LayoutHomeUnauthenticated } from './common'
import { HomeUnauthenticatedLoading } from './HomeUnauthenticatedLoading'

const homeContent = createHomeContent({ slidesMargin: unit(3), variant: CardVariant.Owner })

export const HomeUnauthenticated: FC = () => {
  const home = useHomeQuery({ isAuthenticated: false })

  if (home.isError) {
    return <LayoutNavigationError500 showContextMenuButton />
  }

  if (home.isSuccess) {
    return (
      <LayoutHomeUnauthenticated
        description={home.data.description}
        imageUrl={home.data.imageUrl}
        title={home.data.title}
      >
        {home.data.content.map(homeContent)}
      </LayoutHomeUnauthenticated>
    )
  }

  return <HomeUnauthenticatedLoading />
}
