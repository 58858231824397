import googleTagManager from '@analytics/google-tag-manager'
import type { AnalyticsOptions, AnalyticsPlugin } from 'analytics'
import { add } from 'date-fns'

import { logger } from '@hcr/utils'

import { Environment } from '../environments/environment'

import { APP_CONFIG } from './app.config'

interface AnalyticsConfig {
  analytics: AnalyticsOptions
  consent: {
    cookieName: string
    expirationTime: Date
  }
}

const eventLogger: AnalyticsPlugin = {
  name: 'event-logger',
  identify: ({ payload }: { payload: unknown }) => logger.log('[Analytics] identify', payload),
  page: ({ payload }: { payload: unknown }) => logger.log('[Analytics] page', payload),
  track: ({ payload }: { payload: unknown }) => logger.log('[Analytics] track', payload),
}

export const ANALYTICS_CONFIG: AnalyticsConfig = {
  analytics: {
    app: 'loma',
    version: APP_CONFIG.version,
    plugins: Environment.Analytics.useAnalytics
      ? [
          eventLogger,
          googleTagManager({
            containerId: Environment.Analytics.containerId,
          }),
        ]
      : [eventLogger],
  },
  consent: {
    cookieName: 'CookieConsent',
    expirationTime: add(new Date(), { days: 180 }),
  },
}
