import { Environment } from '../environments/environment'
import { LocaleId } from '../models'

type Link = string
type LinkLocalized = Record<LocaleId, Link>

interface LinkConfig {
  Owners: Link
  Webshop: Link
  customerSupport: LinkLocalized
  store: LinkLocalized
  storeServices: Link
  termsOfBookings: LinkLocalized
  termsOfCookies: LinkLocalized
  termsOfDigitalServices: Link
  termsOfOwnership: Link
  termsOfRental: Link
  welcomePresentation: Link
}

export const LINK_CONFIG: LinkConfig = {
  Owners: Environment.API.Owners.url,
  Webshop: Environment.API.Webshop.url,
  customerSupport: {
    [LocaleId.EnUS]: 'customersupport@holidayclub.fi',
    [LocaleId.Fi]: 'asiakaspalvelu@holidayclub.fi',
  },
  store: {
    [LocaleId.EnUS]: `${Environment.API.Webshop.url}/en/store`,
    [LocaleId.Fi]: `${Environment.API.Webshop.url}/fi/store`,
  },
  storeServices: `${Environment.API.Webshop.url}/fi/store/lisapalvelut-uusi/lista`,
  termsOfBookings: {
    [LocaleId.EnUS]: `${Environment.API.Webshop.url}/en/booking-terms-and-conditions/terms-and-conditions/`,
    [LocaleId.Fi]: `${Environment.API.Webshop.url}/fi/varausehdot/yhdistetyt-ehdot/`,
  },
  termsOfCookies: {
    [LocaleId.EnUS]: `${Environment.API.Webshop.url}/en/footermenu/our-web-service/data-protection/privacy-policy/#three-five`,
    [LocaleId.Fi]: `${Environment.API.Webshop.url}/fi/footermenu/verkkopalvelustamme/tietosuoja/tietosuojaseloste/#evasteet`,
  },
  termsOfDigitalServices: `${Environment.API.Webshop.url}/fi/footermenu/verkkopalvelustamme/digitaalisten-palveluiden-kayttoehdot/`,
  termsOfOwnership: `${Environment.API.Webshop.url}/fi/omistaminen/omistajien-digipalvelut/`,
  termsOfRental: `${Environment.API.Webshop.url}/fi/omistaminen/viikko-osake/vuokravalitys/`,
  welcomePresentation: `${Environment.API.Webshop.url}/fi/omistaminen/tervetuloa-esittelyyn/`,
}
