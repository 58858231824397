import type { ReactNode } from 'react'

import type { AccommodationDto, ActivityHistoryDto, SingleTicketDto } from '@hcr/api/consumer'
import { isAccommodationDto, isActivityHistoryDto } from '@hcr/api/consumer'

import { HistoryPreviewAccommodation } from './HistoryPreviewAccommodation'
import { HistoryPreviewActivity } from './HistoryPreviewActivity'
import { HistoryPreviewTicket } from './HistoryPreviewTicket'

export const createHistoryPreview = () => {
  return (dto: AccommodationDto | SingleTicketDto | ActivityHistoryDto): ReactNode => {
    if (isAccommodationDto(dto)) {
      return <HistoryPreviewAccommodation key={dto.accommodation_id} accommodation={dto} />
    }

    if (isActivityHistoryDto(dto)) {
      return <HistoryPreviewActivity key={dto.activity_id} activity={dto} />
    }

    return <HistoryPreviewTicket key={dto.ticket_id} ticket={dto} />
  }
}
