import { z } from 'zod'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkBookingsDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('bookings'),
})

export type LinkBookingsDto = z.infer<typeof LinkBookingsDtoSchema>

export const isLinkBookingsDto = (x: unknown): x is LinkBookingsDto => LinkBookingsDtoSchema.safeParse(x).success
