import { Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

interface BookingSummaryProps {
  content: Array<{
    label: string
    value: ReactNode
  }>
}

export const BookingSummary: FC<BookingSummaryProps> = ({ content }) => {
  return (
    <Container>
      {content.map(({ label, value }) => (
        <Content key={label}>
          <Typography variant='labelM'>{label}</Typography>
          <Typography variant='bodyM'>{value}</Typography>
        </Content>
      ))}
    </Container>
  )
}

const Container = styled(Stack)`
  background-color: ${COLORS.vaalea['60%']};
`

const Content = styled(Stack)`
  padding: ${unit(3)} ${unit(5)};

  &:not(:last-of-type) {
    border-bottom: thin solid ${COLORS.vaalea[500]};
  }
`
