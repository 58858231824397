import { Box, CardMedia, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { COLORS, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined, isNull } from '@hcr/utils'

import { useHoliday } from '../../../contexts'
import {
  useAccommodationDetailsQuery,
  useDestinationDetailsQuery,
  useIdToken,
  useRoomDetailsQuery,
} from '../../../hooks'
import { Path } from '../../../models'
import {
  createAccommodationGuestsString,
  createRoomDetailsContent,
  createRoomNameString,
  isFlyAccommodation,
  isOwnerUsage,
  to,
} from '../../../utils'
import {
  BookingSummaryAccommodation,
  ExpandableText,
  LayoutNavigationBack,
  LayoutNavigationBackError500,
  LayoutNavigationBackLoading,
} from '../../common'

import { BookingDetailsFallback } from './BookingDetailsFallback'
import { BookingTimeshareIntroductionInfo, BookingTermsAndConditions } from './common'

const BACK_NAVIGATION_PATH = to(Path.Booking)

const roomDetailsContent = createRoomDetailsContent()

export const BookingDetails: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const holiday = useHoliday()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(holiday.data.accommodationId) },
    { enabled: isNotNull(idToken) && isNotNull(holiday.data.accommodationId) }
  )

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(holiday.data.destinationId) },
    { enabled: isNotNull(holiday.data.destinationId) }
  )

  const roomDetails = useRoomDetailsQuery(
    { destinationId: String(holiday.data.destinationId), roomId: String(accommodation.data?.room_type_id) },
    { enabled: isNotNull(holiday.data.destinationId) && isNotUndefined(accommodation.data?.room_type_id) }
  )

  if (holiday.isError || accommodation.isError) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (destinationDetails.isError || roomDetails.isError) {
    return <BookingDetailsFallback />
  }

  if (holiday.isSuccess && isNull(holiday.data.accommodationId)) {
    return <Navigate to={BACK_NAVIGATION_PATH} replace />
  }

  if (holiday.isSuccess && accommodation.isSuccess && destinationDetails.isSuccess && roomDetails.isSuccess) {
    const hasTimeshareIntroductionInfo = isFlyAccommodation(accommodation.data)

    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='outlined'>
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <CardMedia image={roomDetails.data.imageUrl} sx={{ height: unit(71) }} />
          <Stack paddingTop={unit(7)} paddingX={unit(8)} paddingBottom={unit(4.5)}>
            <Typography variant='titleS' color={COLORS.grey[600]}>
              {t('booking-details.booking-details')}
            </Typography>
            <Typography variant='headlineM' marginTop={unit(1)}>
              {createRoomNameString(roomDetails.data)}
            </Typography>
            {isNotNull(roomDetails.data.realEstateName) && (
              <Typography variant='titleS' marginTop={unit(2)}>
                {roomDetails.data.realEstateName}
              </Typography>
            )}
            {isNotNull(roomDetails.data.description) && (
              <Box marginTop={unit(4)}>
                <ExpandableText value={roomDetails.data.description} />
              </Box>
            )}
          </Stack>
          <Stack gap={unit(3)} padding={unit(3)}>
            <BookingSummaryAccommodation
              accommodationId={accommodation.data.accommodation_id}
              accommodationStatus={accommodation.data.status}
              bookingDate={accommodation.data.booking_date}
              checkInDate={accommodation.data.start_date}
              checkInHour={roomDetails.data.checkInHour}
              checkOutDate={accommodation.data.end_date}
              checkOutHour={roomDetails.data.checkOutHour}
              destinationName={destinationDetails.data.name}
              guests={createAccommodationGuestsString(accommodation.data)}
              paymentStatus={accommodation.data.payment_status}
              isOwnerUsage={isOwnerUsage(accommodation.data)}
              price={accommodation.data.total_amount}
            />
            {/* // TODO 128: Restore price summaries on booking details when a bug in HotelLinx is fixed */}
            {/* //  https://hcrdevelopment.atlassian.net/browse/SII-128 */}
            {/* // <BookingSummaryPrice items={accommodation.data.prices} total={accommodation.data.total_amount} /> */}

            {hasTimeshareIntroductionInfo && <BookingTimeshareIntroductionInfo />}

            <BookingTermsAndConditions />

            {roomDetails.data.content.map(roomDetailsContent)}
          </Stack>
        </Box>
      </LayoutNavigationBack>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
