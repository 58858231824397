import { Box, Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'

import { COLORS, unit } from '@hcr/ui'

import { LayoutNavigation } from '../../../../common'

interface LayoutServicesProps extends PropsWithChildren {
  description: ReactNode
  title: ReactNode
}

export const LayoutServices: FC<LayoutServicesProps> = ({ children, description, title }) => {
  return (
    <LayoutNavigation showContextMenuButton>
      <Box paddingTop={unit(25.5)} paddingX={unit(6)} paddingBottom={unit(3)} bgcolor={COLORS.white}>
        <Stack gap={unit(4)}>
          <Typography variant='headlineL'>{title}</Typography>
          <Typography variant='bodyL'>{description}</Typography>
        </Stack>
        <Stack gap={unit(3)} marginTop={unit(9)}>
          {children}
        </Stack>
      </Box>
    </LayoutNavigation>
  )
}
