import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkGomeddoBookingDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('gomeddo-booking'),
  goMeddoId: StringDtoSchema,
})

export type LinkGomeddoBookingDto = z.infer<typeof LinkGomeddoBookingDtoSchema>

export const isLinkGomeddoBookingDto = (x: unknown): x is LinkGomeddoBookingDto =>
  LinkGomeddoBookingDtoSchema.safeParse(x).success
