import { Box, Button, Stack, Typography } from '@mui/material'
import hash from 'object-hash'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CardEmbedment, CardPromotion, CardVariant, COLORS, LinkUnstyled, unit } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { useIdToken, useOwnersQuery, useUserDetailsQuery } from '../../../../hooks'
import { Path } from '../../../../models'
import { createOwnersContent, to } from '../../../../utils'
import { Background, LayoutNavigation, LayoutNavigationError500 } from '../../../common'

import { Holdings, NextWeek, OwnerCard } from './common'
import { OwnerLoading } from './OwnerLoading'
import { OwnerUncontracted } from './OwnerUncontracted'

export const Owner: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const userDetails = useUserDetailsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const owners = useOwnersQuery(
    {
      hasBenefits: Boolean(userDetails.data?.has_benefits),
      isContracted: true,
      isGoldOwner: Boolean(userDetails.data?.is_active_gold_owner),
      isVillasOwner: Boolean(userDetails.data?.is_villas_owner),
    },
    { enabled: userDetails.isSuccess && userDetails.data.has_active_contracts }
  )

  if (userDetails.isError || owners.isError) {
    return <LayoutNavigationError500 />
  }

  if (userDetails.isSuccess && !userDetails.data.has_active_contracts) {
    return <OwnerUncontracted />
  }

  if (userDetails.isSuccess && owners.isSuccess) {
    const ownersContent = createOwnersContent({
      slidesMargin: unit(3),
      variant: CardVariant.Regular,
      additionalCard: userDetails.data.has_benefits
        ? [
            hash([t('owner.take-advantage-of-your-benefits')]),
            <CardPromotion
              description=''
              embedment={CardEmbedment.Carousel}
              link={{
                isExternal: false,
                label: t('owner.see-all-benefits'),
                to: to(Path.OwnerBenefits),
              }}
              title={t('owner.take-advantage-of-your-benefits')}
              variant={CardVariant.Owner}
            />,
          ]
        : undefined,
    })

    return (
      <LayoutNavigation>
        <Box paddingX={unit(3)} bgcolor={COLORS.white}>
          <Background url={owners.data.imageUrl}>
            {userDetails.data.has_benefits && <OwnerCard />}
            <Stack
              marginTop={userDetails.data.has_benefits ? unit(10.75) : unit(64)}
              padding={unit(5)}
              bgcolor={COLORS.red.dark}
              color={COLORS.white}
            >
              <Typography variant='displayM' marginBottom={unit(4)}>
                {owners.data.title}
              </Typography>
              <Typography variant='bodyM' marginBottom={unit(7)}>
                {owners.data.description}
              </Typography>
            </Stack>
          </Background>
          <Stack gap={unit(3)} paddingY={unit(3)}>
            <NextWeek />
            <Holdings />
            {ownersContent(owners.data.content[0])}

            {userDetails.data.has_benefits && (
              <Button component={LinkUnstyled} to={to(Path.OwnerBenefits)} variant='contained' color='primary'>
                {t('owner.see-all-benefits')}
              </Button>
            )}

            {owners.data.content.slice(1).map(ownersContent)}
          </Stack>
        </Box>
      </LayoutNavigation>
    )
  }

  return <OwnerLoading />
}
