import hash from 'object-hash'

import type { CardDto } from '@hcr/api/optimizely'

import type { CreateCardOptions } from '../../surfaces'
import { createCard } from '../../surfaces'

import type { CarouselProps } from './Carousel'

export type CreateCarouselCardOptions = CreateCardOptions

export const createCarouselCard = (options: CreateCardOptions) => {
  const card = createCard(options)

  return (dto: CardDto): CarouselProps['children'][number] => [hash([dto.title, dto.description]), card(dto)]
}
