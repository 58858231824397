import { Box, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'

export const BookingListEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Content>
        <Typography variant='bodyM'>{t('context-menu.no-bookings')}</Typography>
      </Content>
    </Container>
  )
}

const Container = styled(Box)`
  padding: ${unit(3)};
`

const Content = styled(Box)`
  border: ${unit(0.5)} dashed ${COLORS.semantic.divider};
  padding: ${unit(12)} ${unit(5)};
  text-align: center;
`
