import { isNotNull, LogLevel } from '@hcr/utils'

interface CreateMswLoaderOptions {
  apis: {
    consumerUrl: string | null
    optimizelyUrl: string | null
  }
  logLevel: LogLevel
}

export const createMswLoader = async ({ apis, logLevel }: CreateMswLoaderOptions) => {
  const useMocks = Object.values(apis).some(isNotNull)

  if (useMocks) {
    const { createWorker } = await import(
      /* webpackMode: "lazy" */
      '@hcr/api/msw'
    )
    return createWorker(apis).start({
      onUnhandledRequest: 'bypass',
      quiet: !(LogLevel.Log <= logLevel),
    })
  }

  return Promise.resolve(undefined)
}
