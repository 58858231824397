import type { LinkBenefitDetailsDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkBenefitDetails = (dto: LinkBenefitDetailsDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to([Path.OwnerBenefitDetails, { benefitId: dto.benefitId }]),
})
