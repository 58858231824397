import type { FC } from 'react'
import { Fragment, useMemo } from 'react'

import { DividerWithLabel } from '@hcr/ui'
import { isEmpty, isNotNull, isNotUndefined } from '@hcr/utils'

import {
  useAccommodationsQuery,
  useActivitiesHistoryQuery,
  useHistoryQuery,
  useIdToken,
  useSingleTicketsQuery,
} from '../../../hooks'
import { Path } from '../../../models'
import { to } from '../../../utils'

import { createHistoryPreview, LayoutHistory, LayoutHistoryLoading } from './common'
import { HistoryEmpty } from './HistoryEmpty'
import { prepareHistoryPreviewsData } from './utils'

const BACK_NAVIGATION_PATH = to(Path.Account)

const historyPreview = createHistoryPreview()

export const History: FC = () => {
  const idToken = useIdToken()

  const accommodations = useAccommodationsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const singleTickets = useSingleTicketsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const history = useHistoryQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const activitiesHistory = useActivitiesHistoryQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const historyPreviewsData = useMemo(
    () =>
      prepareHistoryPreviewsData(
        [
          accommodations.data,
          singleTickets.data,
          history.data?.accommodation,
          history.data?.tickets,
          activitiesHistory.data,
        ].filter(isNotUndefined)
      ),
    [
      accommodations.data,
      singleTickets.data,
      history.data?.accommodation,
      history.data?.tickets,
      activitiesHistory.data,
    ]
  )

  if (accommodations.isPending || activitiesHistory.isPending || history.isPending || singleTickets.isPending) {
    return <LayoutHistoryLoading backNavigationPath={BACK_NAVIGATION_PATH} />
  }

  if (isEmpty(historyPreviewsData)) {
    return <HistoryEmpty />
  }

  return (
    <LayoutHistory backNavigationPath={BACK_NAVIGATION_PATH}>
      {Array.from(historyPreviewsData.entries()).map(([year, bookings]) => (
        <Fragment key={year}>
          <DividerWithLabel>{year}</DividerWithLabel>
          {bookings.map(historyPreview)}
        </Fragment>
      ))}
    </LayoutHistory>
  )
}
