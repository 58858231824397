import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, css, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import { A11y as SwiperA11y, Navigation as SwiperNavigation, Pagination as SwiperPagination } from 'swiper'
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react'

import { COLORS, ICONS } from '../../../constants'
import { hideEmptyCss } from '../../../styles'
import { unit, unitless } from '../../../utils'

import { useSwiperRef } from './useSwiperRef'

import 'swiper/swiper-bundle.css'

export interface CarouselProps {
  children: [string, ReactNode][]
  slidesMargin: string
  title: ReactNode
}

export const Carousel: FC<CarouselProps> = ({ children, slidesMargin, title }) => {
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()
  const [paginationEl, paginationRef] = useSwiperRef<HTMLDivElement>()

  return (
    <Box marginY={unit(2)} css={swiperCss}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={unit(5)}>
        <Typography variant='titleL'>{title}</Typography>
        <Stack direction='row' gap={unit(9)}>
          <ButtonNavigation ref={prevElRef}>
            <FontAwesomeIcon icon={ICONS.farArrowLeft} fontSize={unit(6)} />
          </ButtonNavigation>
          <ButtonNavigation ref={nextElRef}>
            <FontAwesomeIcon icon={ICONS.farArrowRight} fontSize={unit(6)} />
          </ButtonNavigation>
        </Stack>
      </Stack>
      <Box marginRight={`-${slidesMargin}`}>
        <SwiperReact
          modules={[SwiperNavigation, SwiperPagination, SwiperA11y]}
          spaceBetween={unitless(2)}
          slidesPerView='auto'
          navigation={{
            prevEl,
            nextEl,
          }}
          pagination={{ clickable: true, el: paginationEl }}
        >
          {children.map(([key, node]) => (
            <SwiperSlide key={key}>{node}</SwiperSlide>
          ))}
        </SwiperReact>
      </Box>
      <Footer css={hideEmptyCss} ref={paginationRef} />
    </Box>
  )
}

const swiperCss = css`
  .swiper-slide {
    width: auto;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    background-color: ${COLORS.burgundi[300]};
  }

  .swiper-pagination-bullet-active {
    opacity: unset;
    background-color: ${COLORS.semantic.floatingLabel};
  }
`

const ButtonNavigation = styled('button')`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: ${COLORS.black};

  &:disabled {
    color: ${COLORS.semantic.disabled};
  }
`

const Footer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${unit(5)};
  width: 100%;
`
