import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkServiceDetailsDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('service-details'),
  destinationId: StringDtoSchema,
  serviceId: StringDtoSchema,
})

export type LinkServiceDetailsDto = z.infer<typeof LinkServiceDetailsDtoSchema>

export const isLinkServiceDetailsDto = (x: unknown): x is LinkServiceDetailsDto =>
  LinkServiceDetailsDtoSchema.safeParse(x).success
