import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkOwnerDetailsDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('owner-details'),
  ownerId: StringDtoSchema,
})

export type LinkOwnerDetailsDto = z.infer<typeof LinkOwnerDetailsDtoSchema>

export const isLinkOwnerDetailsDto = (x: unknown): x is LinkOwnerDetailsDto =>
  LinkOwnerDetailsDtoSchema.safeParse(x).success
