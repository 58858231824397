import { Box, Divider, Skeleton, Stack, styled } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'

import { SvgLogoHolidayClub } from '../../../../../../assets'

export const OwnerCardLoading: FC = () => {
  const { t } = useTranslation()

  return (
    <Box marginX={unit(5)} borderRadius={unit(1.5)} bgcolor={COLORS.white}>
      <Box padding={unit(4)} textAlign='center'>
        <Stack height={unit(5)} justifyContent='center'>
          <SvgLogoHolidayClub height={unit(2.5)} fill={COLORS.black} />
        </Stack>
        <Stack gap={unit(4)} paddingTop={unit(7)} paddingX={unit(3)} paddingBottom={unit(3)}>
          <Title color={COLORS.kaarna['60%']}>{t('owner.owner-card')}</Title>
          <Divider />
          <Stack gap={unit(1)} alignItems='center'>
            <Skeleton width={unit(50)} height={unit(6)} variant='rectangular' />
            <Skeleton width={unit(25)} height={unit(5)} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

const Title = styled(Box)`
  font-family: 'Moret', serif;
  font-size: ${unit(10)};
  font-weight: bold;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: ${unit(8)};
  }

  ${props => props.theme.breakpoints.down(350)} {
    font-size: ${unit(6.5)};
    word-break: break-all;
  }
`
