import type { ButtonProps } from '@mui/material'
import { Button } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkUnstyled } from '@hcr/ui'

import { Path } from '../../../models'
import { to } from '../../../utils'

type ButtonSignUpProps = Pick<ButtonProps, 'children' | 'color' | 'variant'>

export const ButtonSignUp: FC<ButtonSignUpProps> = ({ children, color, variant }) => {
  const { t } = useTranslation()

  return (
    <Button component={LinkUnstyled} to={to(Path.Onboarding)} variant={variant} color={color}>
      {children ?? t('buttons.sign-up')}
    </Button>
  )
}
