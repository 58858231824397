import type { To } from 'react-router-dom'

import { encodeSearchParams } from '@hcr/api/shared'
import { isNotUndefined, replaceAll } from '@hcr/utils'

import type { Route } from '../../models'

export const to = (route: Route): To => {
  if (Array.isArray(route)) {
    const [path, pathParams, searchParams] = route
    const pathWithPathParams = Object.entries(pathParams).reduce(replaceAll(':'), path)

    if (isNotUndefined(searchParams)) {
      const encodedSearchParams = encodeSearchParams(searchParams)
      return pathWithPathParams + encodedSearchParams
    }

    return pathWithPathParams
  }

  return route
}
