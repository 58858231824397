import { Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CardVariant, COLORS, unit } from '@hcr/ui'

import { useHomeQuery } from '../../hooks'
import { createHomeContent } from '../../utils'
import { BookingOverviewCompact, LayoutNavigationError500 } from '../common'

import { LayoutHome } from './common'
import { HomeLoading } from './HomeLoading'

const homeContent = createHomeContent({ slidesMargin: unit(3), variant: CardVariant.Regular })

export const HomeFallback: FC = () => {
  const { t } = useTranslation()

  const home = useHomeQuery({ isAuthenticated: true })

  if (home.isError) {
    return <LayoutNavigationError500 showContextMenuButton />
  }

  if (home.isSuccess) {
    return (
      <LayoutHome
        imageUrl={home.data.imageUrl}
        header={
          <Stack gap={unit(4)}>
            <Typography variant='displayM' paddingX={unit(5)} color={COLORS.white}>
              {t('home.holiday-club-resorts')}
            </Typography>
            <BookingOverviewCompact />
          </Stack>
        }
        content={home.data.content.map(homeContent)}
        showFallbackWarning
      />
    )
  }

  return <HomeLoading />
}
