import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, Tile, unit } from '@hcr/ui'

export const NextWeekLoading: FC = () => {
  const { t } = useTranslation()

  return (
    <Tile cta={null} icon={<FontAwesomeIcon icon={ICONS.falCalendarHeart} />} title={t('owner.your-next-week')}>
      <Box marginX={unit(-5)} borderTop='thin solid' borderBottom='thin solid' borderColor={COLORS.hiekka[700]}>
        <Stack padding={unit(5)}>
          <Skeleton width={unit(40)} height={unit(7)} variant='rectangular' />
          <Skeleton width={unit(50)} height={unit(7)} />
        </Stack>
      </Box>
    </Tile>
  )
}
