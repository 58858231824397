import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { sendJson } from '@hcr/api/shared'

import type { HoldingRentalPayloadDto, ProblemDetailsDto } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

export const createHoldingRentalMutation =
  (baseUrl: string) =>
  (
    { idToken }: AuthorizedRequestParams,
    options?: Partial<UseMutationOptions<void, ProblemDetailsDto, HoldingRentalPayloadDto>>
  ) =>
    useMutation<void, ProblemDetailsDto, HoldingRentalPayloadDto>({
      ...options,
      mutationFn: body =>
        sendJson({
          body,
          headers: createAuthorizationHeader(idToken),
          method: 'POST',
          url: `${baseUrl}/user/holdings/rent`,
        }),
    })
