import { Skeleton } from '@mui/material'
import type { FC } from 'react'

import { unit } from '../../../utils'

import { BookingSummary } from './BookingSummary'

interface BookingSummaryLoadingProps {
  labels: string[]
}

export const BookingSummaryLoading: FC<BookingSummaryLoadingProps> = ({ labels }) => {
  return (
    <BookingSummary
      content={labels.map(label => ({
        label,
        value: <Skeleton width={unit(50)} />,
      }))}
    />
  )
}
