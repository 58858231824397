import type { SerializedStyles } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import type { To } from 'react-router-dom'

import { COLORS, ICONS, LinkUnstyled, unit, Z_INDEX } from '@hcr/ui'

import { LayoutBase } from '../layout-base'

interface LayoutNavigationBackProps extends PropsWithChildren {
  arrowVariant: 'outlined' | 'text'
  to: To
}

export const LayoutNavigationBack: FC<LayoutNavigationBackProps> = ({ arrowVariant, children, to }) => {
  return (
    <LayoutBase>
      <ArrowLinkIcon to={to} css={arrowIconVariantCss[arrowVariant]}>
        <FontAwesomeIcon icon={ICONS.farArrowLeft} />
      </ArrowLinkIcon>
      {children}
    </LayoutBase>
  )
}

const ArrowLinkIcon = styled(LinkUnstyled)`
  width: ${unit(14)};
  height: ${unit(14)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${unit(3)};
  top: ${unit(4)};
  z-index: ${Z_INDEX.navigation};
  font-size: ${unit(6)};
`

const arrowIconVariantCss: Record<LayoutNavigationBackProps['arrowVariant'], SerializedStyles> = {
  outlined: css`
    background-color: ${COLORS.white};
    border: ${unit(0.5)} solid ${COLORS.black};
  `,
  text: css`
    background: none;
    border: none;
  `,
}
