import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { HoldingWeekDto } from '@hcr/api/consumer'

import { WeekPreview } from './WeekPreview'

interface CreateWeekPreviewOptions {
  holdingId: string
  isVilla: boolean
}

export const createWeekPreview = ({ holdingId, isVilla }: CreateWeekPreviewOptions) => {
  return (dto: HoldingWeekDto): ReactNode => {
    return <WeekPreview key={hash(dto.week_start)} holdingId={holdingId} isVilla={isVilla} week={dto} />
  }
}
