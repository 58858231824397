interface SendJsonOptions<T> {
  body?: T
  headers?: HeadersInit
  method: 'POST' | 'PUT'
  url: string
}

export const sendJson = async <T>({ body, headers = {}, method = 'POST', url }: SendJsonOptions<T>): Promise<void> => {
  const response = await fetch(url, {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    method,
    mode: 'cors',
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`Response status was not ok (${response.status} ${response.statusText})!`)
  }
}
