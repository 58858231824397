import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, Tile, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useBuildingDetailsQuery, useHoldingsQuery, useIdToken } from '../../../../../../hooks'
import { Path } from '../../../../../../models'
import { dateFormatter, selectNextHolding, to } from '../../../../../../utils'
import { WarningMissingData } from '../../../../../common'

import { NextWeekLoading } from './NextWeekLoading'

export const NextWeek: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const holding = useHoldingsQuery(
    { idToken: String(idToken) },
    {
      select: selectNextHolding,
      enabled: isNotNull(idToken),
      retryOnMount: false,
    }
  )

  const buildingDetails = useBuildingDetailsQuery(
    { buildingId: String(holding.data?.building_id), isContracted: true },
    { enabled: isNotUndefined(holding.data?.building_id) }
  )

  if (holding.isError || buildingDetails.isError) {
    return <WarningMissingData />
  }

  if (holding.isSuccess && isUndefined(holding.data)) {
    return null
  }

  if (holding.isSuccess && isNotUndefined(holding.data) && buildingDetails.isSuccess) {
    return (
      <Tile cta={null} icon={<FontAwesomeIcon icon={ICONS.falCalendarHeart} />} title={t('owner.your-next-week')}>
        <Row>
          <RowButton
            component={LinkUnstyled}
            to={to([Path.OwnerHoldingDetails, { holdingId: String(holding.data.holding_id) }])}
          >
            <Stack>
              <Typography variant='headlineS'>
                {buildingDetails.data.destinationName ?? buildingDetails.data.title}
              </Typography>
              <Typography variant='bodyL' fontWeight='initial' color='initial'>
                {dateFormatter.formatDateRange({
                  from: holding.data.weeks[0].week_start,
                  to: holding.data.weeks[0].week_end,
                })}
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={unit(1.5)}>
              <FontAwesomeIcon icon={ICONS.farInfoCircle} />
              <Typography variant='bodyM' sx={{ textDecoration: 'underline' }}>
                {t('owner.info')}
              </Typography>
            </Stack>
          </RowButton>
        </Row>
      </Tile>
    )
  }

  return <NextWeekLoading />
}

const Row = styled(Box)`
  margin-left: ${unit(-5)};
  margin-right: ${unit(-5)};
  border-top: thin solid ${COLORS.hiekka[700]};

  :last-of-type {
    border-bottom: thin solid ${COLORS.hiekka[700]};
  }
`

const RowButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: ${unit(4)};
  text-align: left;
  padding: ${unit(5)};
` as typeof Button
