import { Button } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkUnstyled } from '@hcr/ui'
import { isNotNull, isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../../contexts'
import { useAccommodationDetailsQuery, useIdToken, useRoomDetailsQuery } from '../../../../hooks'
import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { BookingDates, LayoutBookingOverview, LayoutBookingOverviewContent } from '../common'

import { BookingOverviewCompactLoading } from './BookingOverviewCompactLoading'

export const BookingOverviewCompact: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const holiday = useHoliday()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(holiday.data.accommodationId) },
    { enabled: isNotNull(idToken) && isNotNull(holiday.data.accommodationId) }
  )

  const roomDetails = useRoomDetailsQuery(
    { destinationId: String(holiday.data.destinationId), roomId: String(accommodation.data?.room_type_id) },
    { enabled: isNotNull(holiday.data.destinationId) && isNotUndefined(accommodation.data?.room_type_id) }
  )

  if (holiday.isSuccess && accommodation.isSuccess) {
    return (
      <LayoutBookingOverview>
        <LayoutBookingOverviewContent>
          <BookingDates
            checkInDate={accommodation.data.start_date}
            checkInHour={roomDetails.data?.checkInHour}
            checkOutDate={accommodation.data.end_date}
            checkOutHour={roomDetails.data?.checkOutHour}
          />
          <Button component={LinkUnstyled} to={to(Path.Booking)} variant='contained' color='primary'>
            {t('booking-overview.booking-and-program')}
          </Button>
        </LayoutBookingOverviewContent>
      </LayoutBookingOverview>
    )
  }

  return <BookingOverviewCompactLoading />
}
