import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkExternalDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('external'),
  url: StringDtoSchema.url(),
})

export type LinkExternalDto = z.infer<typeof LinkExternalDtoSchema>

export const isLinkExternalDto = (x: unknown): x is LinkExternalDto => LinkExternalDtoSchema.safeParse(x).success
