import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BottomNavigation, BottomNavigationAction, styled } from '@mui/material'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { COLORS, ICONS, unit } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useIdToken, useUserDetailsQuery } from '../../../../../hooks'
import type { Route } from '../../../../../models'
import { EMPTY_PATH_PARAM, Path } from '../../../../../models'
import { to } from '../../../../../utils'

interface NavigationItem {
  icons: {
    active: IconDefinition
    normal: IconDefinition
  }
  label: string
  route: Route
}

interface NavigationProps {
  variant: 'horizontal' | 'vertical'
}

export const Navigation: FC<NavigationProps> = ({ variant }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const idToken = useIdToken()
  const holiday = useHoliday()
  const navigate = useNavigate()

  const userDetails = useUserDetailsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const navigationItems = useMemo<NavigationItem[]>(
    () => [
      {
        icons: {
          active: ICONS.fasHouse,
          normal: ICONS.farHouse,
        },
        label: t('navigation.home'),
        route: Path.Home,
      },
      {
        icons: {
          active: ICONS.fasGiftCard,
          normal: ICONS.farGiftCard,
        },
        label: t('navigation.booking'),
        route: Path.Booking,
      },
      {
        icons: {
          active: ICONS.fasUtensils,
          normal: ICONS.farUtensils,
        },
        label: t('navigation.services'),
        route: [Path.Services, { destinationId: holiday.data.destinationId ?? EMPTY_PATH_PARAM }],
      },
      {
        icons: userDetails.data?.has_active_contracts
          ? { active: ICONS.fasBadgeCheck, normal: ICONS.farBadgeCheck }
          : { active: ICONS.fasBadgePercent, normal: ICONS.farBadgePercent },
        label: t('navigation.owner'),
        route: Path.Owner,
      },
    ],
    [t, holiday.data.destinationId, userDetails.data?.has_active_contracts]
  )

  const isActiveRoute = (route: Route): boolean => to(route) === location.pathname

  return (
    <Container sx={{ flexDirection: variant === 'vertical' ? 'column' : 'row' }}>
      {navigationItems.map(item => (
        <NavigationButton
          key={item.label}
          LinkComponent={Link}
          onClick={() => navigate(to(item.route))}
          label={item.label}
          className={isActiveRoute(item.route) ? 'Mui-selected' : ''}
          icon={<FontAwesomeIcon icon={isActiveRoute(item.route) ? item.icons.active : item.icons.normal} />}
          showLabel
        />
      ))}
    </Container>
  )
}

const Container = styled(BottomNavigation)`
  display: flex;
  transition: none;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.puuteri[300]};
`

const NavigationButton = styled(BottomNavigationAction)`
  svg {
    height: ${unit(4)};
  }

  :not(:last-of-type) {
    border-bottom: ${unit(0.25)} solid ${COLORS.puuteri[400]};
  }
`
