import { z } from 'zod'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkServicesDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('services'),
})

export type LinkServicesDto = z.infer<typeof LinkServicesDtoSchema>

export const isLinkServicesDto = (x: unknown): x is LinkServicesDto => LinkServicesDtoSchema.safeParse(x).success
