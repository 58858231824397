import type { Configuration as MsalConfig } from '@azure/msal-browser'
import { BrowserCacheLocation } from '@azure/msal-browser'

import { Environment } from '../environments/environment'

interface AuthConfig {
  msal: MsalConfig
  token: {
    hasTriedRedirectFlagName: string
    silentRedirectUri: string
  }
}

export const AUTH_CONFIG: AuthConfig = {
  msal: {
    auth: {
      authority: Environment.API.Auth.url,
      clientId: Environment.API.Auth.clientId,
      knownAuthorities: [Environment.API.Auth.domain],
      postLogoutRedirectUri: Environment.API.Auth.redirectUrl,
      redirectUri: Environment.API.Auth.redirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  },
  token: {
    hasTriedRedirectFlagName: 'MSAL_HAS_TRIED_REDIRECT',
    silentRedirectUri: Environment.API.Auth.redirectUrl + '/blank.html',
  },
}
