import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { DestinationsDto, ErrorDto } from '../../dtos'
import { DestinationsDtoSchema } from '../../dtos'

export const createDestinationsQueryKey = () => ['optimizely', 'destinations']

export const createDestinationsQuery =
  (baseUrl: string) =>
  <Data = DestinationsDto>(options?: Partial<UseQueryOptions<DestinationsDto, ErrorDto, Data>>) =>
    useQuery<DestinationsDto, ErrorDto, Data>({
      ...options,
      queryKey: createDestinationsQueryKey(),
      queryFn: () =>
        getJson({
          schema: DestinationsDtoSchema,
          url: `${baseUrl}/destinations`,
        }),
    })
