import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import type { To } from 'react-router-dom'

import { ICONS, LinkUnstyled, UNICODE, unit } from '@hcr/ui'

interface HistoryPreviewProps {
  bookingDate: string
  destinationName: string
  headerPrimary: ReactNode
  headerSecondary: string
  link: To
  price: string
}

export const HistoryPreview: FC<HistoryPreviewProps> = ({
  bookingDate,
  destinationName,
  headerPrimary,
  headerSecondary,
  link,
  price,
}) => {
  return (
    <Container component={LinkUnstyled} to={link} color='black'>
      <Stack gap={unit(0.75)} width='100%'>
        <Typography variant='titleS'>{headerPrimary}</Typography>
        <Typography variant='labelExtraSmall'>{headerSecondary}</Typography>
        <Typography variant='bodyS'>
          {bookingDate} {UNICODE.middleDot} {destinationName} {UNICODE.middleDot} {price}
        </Typography>
      </Stack>
      <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} />
    </Container>
  )
}

const Container = styled(Button)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${unit(3.5)} ${unit(7)} ${unit(4.5)};
  font-weight: initial;
` as typeof Button
