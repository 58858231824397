import { Box, Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import type { To } from 'react-router-dom'

import { COLORS, unit } from '@hcr/ui'

import { LayoutNavigationBack, WarningMissingDataFloating } from '../../../../common'

interface LayoutHistoryDetailsProps extends PropsWithChildren {
  backNavigationPath: To
  cta: ReactNode
  headerPrimary: ReactNode
  headerSecondary: ReactNode
  showFallbackWarning?: boolean
}

export const LayoutHistoryDetails: FC<LayoutHistoryDetailsProps> = ({
  backNavigationPath,
  cta,
  children,
  headerPrimary,
  headerSecondary,
  showFallbackWarning = false,
}) => {
  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
      {showFallbackWarning && <WarningMissingDataFloating />}
      <Box bgcolor={COLORS.white} minHeight='100vh'>
        <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
          <Typography variant='headlineL'>{headerPrimary}</Typography>
          <Typography variant='titleS'>{headerSecondary}</Typography>
        </Stack>
        <Stack gap={unit(3)} padding={unit(3)}>
          {children}
        </Stack>
        <Box paddingTop={unit(4.5)} paddingX={unit(7)} paddingBottom={unit(7.5)}>
          {cta}
        </Box>
      </Box>
    </LayoutNavigationBack>
  )
}
