import { Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { unit } from '@hcr/ui'

import { ButtonSignIn, ButtonSignUp, LayoutNavigation } from '../../../common'

export const OwnerUnauthenticated: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutNavigation stretchContentVertically>
      <Container>
        <Stack gap={unit(2.5)} textAlign='center'>
          <Typography variant='titleM'>{t('owner.your-ownership')}</Typography>
          <Typography variant='bodyM'>{t('owner.to-see-your-ownership-please-sign-in')}</Typography>
        </Stack>
        <Stack gap={unit(2.5)} textAlign='center'>
          <Typography variant='titleS'>{t('owner.not-an-owner-yet?')}</Typography>
          <Typography variant='bodyM'>{t('owner.to-learn-more-about-ownership-please-sign-in')}</Typography>
        </Stack>
        <Stack gap={unit(4)} width='100%'>
          <ButtonSignIn variant='contained' color='primary' />
          <ButtonSignUp variant='outlined' color='black' />
        </Stack>
      </Container>
    </LayoutNavigation>
  )
}

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${unit(7)};
  padding: ${unit(19)} ${unit(7)} ${unit(7)};
`
