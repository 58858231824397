import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema } from '../components'

export const ServicesDtoSchema = z.strictObject({
  destinationId: StringDtoSchema,
  title: StringDtoSchema,
  description: StringDtoSchema,
  content: z.array(z.union([CardPrimaryDtoSchema, CardPromotionDtoSchema, CarouselDtoSchema])),
})

export type ServicesDto = z.infer<typeof ServicesDtoSchema>

export const isServicesDto = (x: unknown): x is ServicesDto => ServicesDtoSchema.safeParse(x).success
