import type { LinkGomeddoBookingDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkGomeddoBooking = (dto: LinkGomeddoBookingDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to([Path.GomeddoBooking, { gomeddoId: dto.goMeddoId }]),
})
