import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  Select,
  InputLabel,
  Input,
  MenuItem,
  IconButton,
} from '@mui/material'
import { useState, type FC } from 'react'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { MocksSettings } from '@hcr/api/shared'
import { loadMocksSettings, Mocks, saveMocksSettings } from '@hcr/api/shared'
import { Checkbox, COLORS, ICONS, unit } from '@hcr/ui'

import { Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutNavigationBack } from '../../common'

const BACK_NAVIGATION_PATH = to(Path.About)

const resorts = ['Katinkulta', 'Vierumäki', 'Saimaan Rauha', 'Ruka']
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      border: '1px solid',
      borderTop: 0,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const AboutMocks: FC = () => {
  const { t } = useTranslation()

  const [showHoldings, setShowHoldings] = useState(loadMocksSettings().hasActiveContracts)
  const form = useForm<MocksSettings>({ values: loadMocksSettings() })

  const onSubmit: SubmitHandler<MocksSettings> = async (settings): Promise<void> => {
    if (!showHoldings) {
      settings.holding = ''
    }

    saveMocksSettings(settings)
    window.location.reload()
  }

  return (
    <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='text'>
      <Box bgcolor={COLORS.white} minHeight='100vh'>
        <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
          <Typography variant='headlineL'>{t('about-mocks.mocked-data-settings')}</Typography>
          <Typography variant='bodyM'>
            {t('about-mocks.the-settings-below-are-retained-until-you-close-the-browser-tab')}
          </Typography>
        </Stack>
        <Stack gap={unit(3)} paddingY={unit(3)} paddingX={unit(7)}>
          <form>
            <FormGroup>
              <Controller
                control={form.control}
                name={Mocks.HasAccommodations}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-accommodations')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasHotelRoom}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-hotel-room')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasSingleTickets}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-single-tickets')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasActivities}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-activities')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasActiveContracts}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-active-contracts')}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={event => {
                          setShowHoldings(value => !value)
                          field.onChange(event.target.checked)
                        }}
                      />
                    }
                  />
                )}
              />
              {showHoldings && (
                <Controller
                  control={form.control}
                  name={Mocks.Holding}
                  render={({ field }) => (
                    <Stack py={unit(2)}>
                      <InputLabel style={{ padding: `${unit(2)} 0` }} id='holdings-label'>
                        {t('about-mocks.holdings')}
                      </InputLabel>
                      <Select
                        {...field}
                        labelId='holdings-label'
                        id='holdings-label'
                        value={field.value}
                        onChange={field.onChange}
                        input={<Input id='holdings-select' />}
                        endAdornment={
                          field.value && (
                            <IconButton
                              sx={{ marginRight: unit(6), fontSize: unit(4) }}
                              onClick={() => field.onChange('')}
                            >
                              <FontAwesomeIcon icon={ICONS.farXmark} />
                            </IconButton>
                          )
                        }
                        MenuProps={MenuProps}
                      >
                        {resorts.map(resort => (
                          <MenuItem key={resort} value={resort}>
                            {resort}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  )}
                />
              )}
              <Controller
                control={form.control}
                name={Mocks.HasBenefits}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-benefits')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.IsGoldOwner}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.is-gold-owner')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.IsVillasOwner}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.is-villas-owner')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
            </FormGroup>
          </form>
        </Stack>
        <Stack gap={unit(3)} paddingTop={unit(4.5)} paddingX={unit(7)} paddingBottom={unit(7.5)}>
          <Button onClick={form.handleSubmit(onSubmit)} variant='contained' color='primary'>
            {t('about-mocks.reload-mocked-data')}
          </Button>
        </Stack>
      </Box>
    </LayoutNavigationBack>
  )
}
