import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const DestinationDetailsDtoBaseSchema = z.strictObject({
  destinationId: StringDtoSchema,
  hotelId: StringDtoSchema.nullable(),
  mokkiId: StringDtoSchema.nullable(),
  title: StringDtoSchema,
  description: StringDtoSchema,
})

export type DestinationDetailsDtoBase = z.infer<typeof DestinationDetailsDtoBaseSchema>
