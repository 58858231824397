import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'

import type { Link } from '@hcr/ui'
import { createLinkExternalProps, ICONS, LinkUnstyled, unit, Z_INDEX } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { LayoutBase } from '../layout-base'

interface LayoutCtaProps extends PropsWithChildren {
  link: Link | null
}

const CTA_HEIGHT = 19

export const LayoutCta: FC<LayoutCtaProps> = ({ children, link }) => {
  return (
    <>
      <Box paddingBottom={isNotNull(link) ? unit(CTA_HEIGHT) : 0}>{children}</Box>
      {isNotNull(link) && (
        <Box position='fixed' left={0} right={0} bottom={0} zIndex={Z_INDEX.navigation}>
          <LayoutBase>
            <Button
              component={LinkUnstyled}
              to={link.to}
              endIcon={<FontAwesomeIcon icon={ICONS.farArrowRight} />}
              sx={{ fontSize: unit(4), height: unit(CTA_HEIGHT), textAlign: 'center' }}
              {...createLinkExternalProps(link.isExternal)}
              variant='contained'
              color='primary'
            >
              {link.label}
            </Button>
          </LayoutBase>
        </Box>
      )}
    </>
  )
}
