import { Skeleton } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { unit } from '@hcr/ui'

import { ContractPreviewLoading } from '../contract-preview'

import { LayoutOwnerHoldingAction } from './LayoutOwnerHoldingAction'

interface LayoutOwnerHoldingActionLoadingProps {
  backNavigationPath: To
}

export const LayoutOwnerHoldingActionLoading: FC<LayoutOwnerHoldingActionLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutOwnerHoldingAction
      backNavigationPath={backNavigationPath}
      header={<Skeleton width='80%' height={unit(9.5)} variant='rectangular' />}
    >
      <ContractPreviewLoading isActionRequested={false} />
      <Skeleton height={unit(14)} variant='rectangular' />
    </LayoutOwnerHoldingAction>
  )
}
