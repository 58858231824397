import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { AccommodationDtoSchema } from './AccommodationDto'
import { SingleTicketDtoSchema } from './SingleTicketDto'

export const HistoryDtoSchema = z.strictObject({
  global_id: StringDtoSchema,
  accommodation: z.array(AccommodationDtoSchema),
  tickets: z.array(SingleTicketDtoSchema),
})

export type HistoryDto = z.infer<typeof HistoryDtoSchema>
