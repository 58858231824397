import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, styled } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, unit, Z_INDEX } from '@hcr/ui'

import { LINK_CONFIG } from '../../../configs'
import { LayoutBase } from '../layout-base'

import { Navigation, NavigationFloating } from './common'

const TOP_NAVIGATION_HEIGHT = 15
const BOTTOM_NAVIGATION_HEIGHT = 17

interface LayoutNavigationProps extends PropsWithChildren {
  showContextMenuButton?: boolean
  stretchContentVertically?: boolean
}

export const LayoutNavigation: FC<LayoutNavigationProps> = ({
  children,
  showContextMenuButton = false,
  stretchContentVertically = false,
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      <TopNavigationContainer>
        <LayoutBase>
          <TopNavigationContent>
            <TopNavigationButton component={LinkUnstyled} to={LINK_CONFIG.Webshop} target='_blank' rel='external'>
              {t('navigation.holidayclub.fi')}
              <FontAwesomeIcon icon={ICONS.farArrowUpRightFromSquare} />
            </TopNavigationButton>
          </TopNavigationContent>
        </LayoutBase>
      </TopNavigationContainer>
      <LayoutBase>
        <SideNavigationContainer>
          <SideNavigationContent>
            <SideNavigation>
              <Navigation variant='vertical' />
            </SideNavigation>
          </SideNavigationContent>
        </SideNavigationContainer>
        <NavigationFloating showContextMenuButton={showContextMenuButton} />
        {stretchContentVertically ? <ContentStretched>{children}</ContentStretched> : <Content>{children}</Content>}
      </LayoutBase>
      <BottomNavigation>
        <Navigation variant='horizontal' />
      </BottomNavigation>
    </Box>
  )
}

const TopNavigationContainer = styled(Box)`
  position: relative;
  background-color: ${COLORS.burgundi[500]};
  height: ${unit(TOP_NAVIGATION_HEIGHT)};
  text-align: right;
  z-index: ${Z_INDEX.navigation};
`

const TopNavigationContent = styled(Box)`
  padding: 0 ${unit(3.75)};
  height: 100%;
`

const TopNavigationButton = styled(Button)`
  display: inline-flex;
  gap: ${unit(1.5)};
  color: ${COLORS.white};
  font-size: ${unit(3)};
  font-weight: initial;
  width: fit-content;
  height: 100%;
` as typeof Button

const SideNavigationContainer = styled(Box)`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.navigation};

  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`

const SideNavigationContent = styled(Box)`
  position: absolute;
  left: ${unit(-23)};
  top: ${unit(4)};
`

const SideNavigation = styled(Box)`
  border-radius: ${unit(1)};
  overflow: hidden;
`

const Content = styled(Box)`
  ${props => props.theme.breakpoints.down('md')} {
    padding-bottom: ${unit(BOTTOM_NAVIGATION_HEIGHT)};
  }
`

const ContentStretched = styled(Box)`
  height: calc(100vh - ${unit(TOP_NAVIGATION_HEIGHT)});

  ${props => props.theme.breakpoints.down('md')} {
    padding-bottom: ${unit(BOTTOM_NAVIGATION_HEIGHT)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-bottom: ${unit(TOP_NAVIGATION_HEIGHT)};
  }
`

const BottomNavigation = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${unit(BOTTOM_NAVIGATION_HEIGHT)};
  z-index: ${Z_INDEX.navigation};

  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`
