import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const PaperDtoSchema = z.strictObject({
  component: z.literal('paper'),
  title: StringDtoSchema,
  description: StringDtoSchema,
})

export type PaperDto = z.infer<typeof PaperDtoSchema>

export const isPaperDto = (x: unknown): x is PaperDto => PaperDtoSchema.safeParse(x).success
