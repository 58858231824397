import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { PricingDto } from '@hcr/api/optimizely'

import { Pricing } from './Pricing'

export const createPricing =
  () =>
  (dto: PricingDto): ReactNode => (
    <Pricing
      content={dto.content}
      description={dto.description}
      footer={dto.footer}
      key={hash([dto.title, dto.description])}
      title={dto.title}
    />
  )
