import { Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'

import { Path } from '../../../models'
import { to } from '../../../utils'
import { ButtonBuyAccommodation } from '../../common'

import { LayoutHistory } from './common'

const BACK_NAVIGATION_PATH = to(Path.Account)

export const HistoryEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutHistory backNavigationPath={BACK_NAVIGATION_PATH}>
      <Container>
        <Typography variant='bodyM' textAlign='center'>
          {t('history.no-bookings')}
        </Typography>
        <ButtonBuyAccommodation variant='outlined' color='black' />
      </Container>
    </LayoutHistory>
  )
}

const Container = styled(Stack)`
  gap: ${unit(4)};
  margin: ${unit(4)} ${unit(2.5)};
  padding: ${unit(6)} ${unit(4)};
  border: ${unit(0.5)} dashed ${COLORS.semantic.divider};
`
