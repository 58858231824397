import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const BenefitDetailsDtoSchema = z.strictObject({
  title: StringDtoSchema,
  subtitle: StringDtoSchema,
  description: StringDtoSchema,
})

export type BenefitDetailsDto = z.infer<typeof BenefitDetailsDtoSchema>

export const isBenefitDetailsDto = (x: unknown): x is BenefitDetailsDto => BenefitDetailsDtoSchema.safeParse(x).success
