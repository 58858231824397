import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import type { BuildingDetailsDto, ErrorDto } from '../../dtos'
import { BuildingDetailsDtoSchema } from '../../dtos'

interface BuildingDetailsQueryParams {
  buildingId: string
  isContracted: boolean
}

export const createBuildingDetailsQueryKey = (params: BuildingDetailsQueryParams) => ['optimizely', 'buildings', params]

export const createBuildingDetailsQuery =
  (baseUrl: string) =>
  <Data = BuildingDetailsDto>(
    params: BuildingDetailsQueryParams,
    options?: Partial<UseQueryOptions<BuildingDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<BuildingDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createBuildingDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: BuildingDetailsDtoSchema,
          url: `${baseUrl}/buildings/${params.buildingId}/details${encodeSearchParams({
            isContracted: params.isContracted,
          })}`,
        }),
    })
