import type { ButtonProps } from '@mui/material'
import { Button } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkUnstyled } from '@hcr/ui'

import { LINK_CONFIG } from '../../../configs'
import { useLocale } from '../../../hooks'

interface ButtonBuyAccommodationProps extends Pick<ButtonProps, 'children' | 'color' | 'variant'> {
  resortId?: number
}

// TODO 21: Component: Book new holiday
//  https://hcrdevelopment.atlassian.net/browse/SII-21
//  - Add the resortId parameter back once it's clear what it is
export const ButtonBuyAccommodation: FC<ButtonBuyAccommodationProps> = ({ children, color, resortId, variant }) => {
  const { t } = useTranslation()
  const locale = useLocale()

  return (
    <Button
      component={LinkUnstyled}
      // to={LINK_CONFIG.store[locale.id] + (isNotUndefined(resortId) ? encodeSearchParams({ resortId }) : '')}
      to={LINK_CONFIG.store[locale.id]}
      variant={variant}
      color={color}
      rel='external'
    >
      {children ?? t('buttons.buy-accommodation')}
    </Button>
  )
}
