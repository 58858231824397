import { LogLevel } from './types'

/* eslint-disable no-console */
class Logger {
  private logLevel: LogLevel = LogLevel.Debug

  public setLogLevel = (logLevel: LogLevel): void => {
    this.logLevel = logLevel
  }

  public debug = (...data: unknown[]): void => {
    this.call(LogLevel.Debug, () => console.debug(...data))
  }

  public error = (...data: unknown[]): void => {
    this.call(LogLevel.Error, () => console.error(...data))
  }

  public groupCollapsed = (...data: unknown[]): void => {
    this.call(LogLevel.Log, () => console.groupCollapsed(...data))
  }

  public groupEnd = (): void => {
    this.call(LogLevel.Log, () => console.groupEnd())
  }

  public info = (...data: unknown[]): void => {
    this.call(LogLevel.Info, () => console.info(...data))
  }

  public log = (...data: unknown[]): void => {
    this.call(LogLevel.Log, () => console.log(...data))
  }

  public warn = (...data: unknown[]): void => {
    this.call(LogLevel.Warn, () => console.warn(...data))
  }

  protected call = (logLevel: LogLevel, fn: () => void): void => {
    if (logLevel <= this.logLevel) {
      fn()
    }
  }
}

export const logger = new Logger()
