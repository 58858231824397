import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { sendJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, UserOnboardingStartPayloadDto } from '../../dtos'

export const createUserOnboardingStartMutation =
  (baseUrl: string) =>
  (options?: Partial<UseMutationOptions<void, ProblemDetailsDto, UserOnboardingStartPayloadDto>>) =>
    useMutation<void, ProblemDetailsDto, UserOnboardingStartPayloadDto>({
      ...options,
      mutationFn: body =>
        sendJson({
          body,
          method: 'POST',
          url: `${baseUrl}/user/start-registration`,
        }),
    })
