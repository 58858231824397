import { isEmpty, isNotUndefined, isUndefined } from '@hcr/utils'

type SearchParamValue = boolean | number | string | undefined

export const encodeSearchParams = (params: Record<string, SearchParamValue | SearchParamValue[]>): string => {
  const tuples = Object.entries(params).reduce<[string, string][]>((tuples, [key, value]) => {
    if (isUndefined(value)) {
      return tuples
    }

    if (Array.isArray(value)) {
      value = value.filter(isNotUndefined)

      if (isEmpty(value)) {
        return tuples
      }
    }

    return [...tuples, [key, String(value)]]
  }, [])

  const search = new URLSearchParams(tuples).toString()
  return isEmpty(search) ? '' : `?${search}`
}
