import { type ButtonProps } from '@mui/material'
import type { FC } from 'react'

interface ButtonBuyTicketProps extends Pick<ButtonProps, 'children' | 'color' | 'variant'> {
  resortId: number
}

// TODO 22: Component: Buy new ticket
//  https://hcrdevelopment.atlassian.net/browse/SII-22
//  - Enable the button back once it's clear what is the resortId parameter
export const ButtonBuyTicket: FC<ButtonBuyTicketProps> = () => {
  return null

  // const { t } = useTranslation()
  //
  // return (
  //   <Button
  //     component={LinkUnstyled}
  //     to={LINK_CONFIG.storeServices + encodeSearchParams({ resortId })}
  //     variant={variant}
  //     color={color}
  //     rel='external'
  //   >
  //     {children ?? t('buttons.buy-ticket')}
  //   </Button>
  // )
}
