import type { BrowserOptions } from '@sentry/react'
import { httpClientIntegration, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import { Environment } from '../environments/environment'

import { APP_CONFIG } from './app.config'
import { HTTP_CONFIG } from './http.config'

interface SentryConfig {
  privacy: {
    className: string
  }
  sentry: BrowserOptions
}

export const SENTRY_CONFIG: SentryConfig = {
  privacy: {
    className: 'sentry-mask',
  },
  sentry: {
    ...(Environment.Sentry.useSentry && {
      dsn: Environment.Sentry.dsn,
      tracesSampleRate: Environment.Sentry.tracesSampleRate,
    }),
    integrations: [
      httpClientIntegration({ failedRequestStatusCodes: [[400, 599]] }),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        networkDetailAllowUrls: [HTTP_CONFIG.API.Consumer.url, HTTP_CONFIG.API.Optimizely.url],
      }),
    ],
    environment: Environment.General.name,
    sendDefaultPii: true,
    release: APP_CONFIG.version,
    replaysOnErrorSampleRate: 1.0,
  },
}
