import { Skeleton } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import type { To } from 'react-router-dom'

import { unit } from '@hcr/ui'

import { LayoutHistoryDetails } from './LayoutHistoryDetails'

interface LayoutHistoryDetailsLoadingProps extends PropsWithChildren {
  backNavigationPath: To
}

export const LayoutHistoryDetailsLoading: FC<LayoutHistoryDetailsLoadingProps> = ({ backNavigationPath, children }) => {
  return (
    <LayoutHistoryDetails
      backNavigationPath={backNavigationPath}
      cta={<Skeleton height={unit(14)} variant='rectangular' />}
      headerPrimary={<Skeleton width={unit(70)} height={unit(9.5)} variant='rectangular' />}
      headerSecondary={<Skeleton width={unit(40)} height={unit(5.5)} />}
    >
      {children}
    </LayoutHistoryDetails>
  )
}
