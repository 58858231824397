import type { DateFnsFormat } from '../../models'
import { DateFormat } from '../../models'

import { format as formatDate } from './format'

export const formatDateRange = ({
  from,
  fromFormat = DateFormat.Standard,
  to,
  toFormat = DateFormat.Standard,
}: {
  from: Date | string
  fromFormat?: DateFnsFormat
  to: Date | string
  toFormat?: DateFnsFormat
}): string => `${formatDate(fromFormat, from)} - ${formatDate(toFormat, to)}`
