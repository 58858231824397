import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, HomeDto } from '../../dtos'
import { HomeDtoSchema } from '../../dtos'

interface HomeQueryParams {
  isAuthenticated: boolean
}

export const createHomeQueryKey = (params: HomeQueryParams) => ['optimizely', 'home', params]

export const createHomeQuery =
  (baseUrl: string) =>
  <Data = HomeDto>(params: HomeQueryParams, options?: Partial<UseQueryOptions<HomeDto, ErrorDto, Data>>) =>
    useQuery<HomeDto, ErrorDto, Data>({
      ...options,
      queryKey: createHomeQueryKey(params),
      queryFn: () =>
        getJson({
          schema: HomeDtoSchema,
          url: `${baseUrl}/home${params.isAuthenticated ? '/authenticated' : ''}`,
        }),
    })
