import type { LinkOwnerDetailsDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkOwnerDetails = (dto: LinkOwnerDetailsDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to([Path.OwnerDetails, { ownerId: dto.ownerId }]),
})
