import { Box, Card, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { unit } from '../../../../utils'
import type { CardEmbedment, CardVariant } from '../types'

import { cardEmbedmentCss, cardVariantCss, CONTENT_GAP, CONTENT_PADDING, IMAGE_HEIGHT } from './styles'

interface CardPrimaryLoadingProps {
  embedment: CardEmbedment
  variant: CardVariant
}

export const CardPrimaryLoading: FC<CardPrimaryLoadingProps> = ({ embedment, variant }) => {
  return (
    <Card css={[cardEmbedmentCss[embedment], cardVariantCss[variant]]}>
      <Skeleton height={unit(IMAGE_HEIGHT)} variant='rectangular' />
      <Stack gap={unit(CONTENT_GAP)} padding={unit(CONTENT_PADDING)}>
        <Skeleton width='80%' height={unit(6)} variant='rectangular' />
        <Box>
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
          <Skeleton width='90%' height={unit(6)} />
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
        </Box>
      </Stack>
    </Card>
  )
}
