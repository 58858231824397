import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, unit } from '@hcr/ui'

import { APP_CONFIG, HTTP_CONFIG } from '../../../configs'
import { useConsentSettings } from '../../../contexts'
import { Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutNavigationBack } from '../../common'

const BACK_NAVIGATION_PATH = to(Path.Account)

export const About: FC = () => {
  const { t } = useTranslation()
  const consentSettings = useConsentSettings()

  return (
    <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='text'>
      <Box paddingTop={unit(24)} paddingX={unit(7)}>
        <Stack gap={unit(1)}>
          <Typography variant='labelM' color={COLORS.grey[600]}>
            {t('about.version')}
          </Typography>
          <Typography variant='headlineM'>{APP_CONFIG.version}</Typography>
        </Stack>
        <Stack marginTop={unit(8.5)} marginX={unit(-7)}>
          {HTTP_CONFIG.API.Consumer.useMocks && (
            <Link component={LinkUnstyled} to={to(Path.AboutMocks)}>
              <Typography variant='labelM'>{t('about.change-mocked-data-settings')}</Typography>
              <FontAwesomeIcon icon={ICONS.farChevronRight} />
            </Link>
          )}
          <Link onClick={consentSettings.open}>
            <Typography variant='labelM'>{t('about.change-consent-settings')}</Typography>
            <FontAwesomeIcon icon={ICONS.farChevronRight} />
          </Link>
          <Link component={LinkUnstyled} to={APP_CONFIG.thirdPartySoftwareLicensesPath} target='_blank' rel='external'>
            <Typography variant='labelM'>{t('about.third-party-software-licenses')}</Typography>
            <FontAwesomeIcon icon={ICONS.farArrowUpRightFromSquare} />
          </Link>
        </Stack>
      </Box>
    </LayoutNavigationBack>
  )
}

const Link = styled(Button)`
  display: flex;
  justify-content: space-between;
  padding: ${unit(4)} ${unit(7)};
  color: ${COLORS.black};
  border-top: ${unit(0.25)} solid ${COLORS.vaalea[600]};

  :last-child {
    border-bottom: ${unit(0.25)} solid ${COLORS.vaalea[600]};
  }
` as typeof Button
