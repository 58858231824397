import type { SingleTicketDto } from '@hcr/api/consumer'
import { reduce } from '@hcr/utils'

import type { Guests } from '../../models'

export const createSingleTicketsGuests = reduce(
  (guests: Guests, ticket: SingleTicketDto) => {
    switch (ticket.ticket_group) {
      case 'adult':
        return { ...guests, adults: guests.adults + 1 }
      case 'child':
        return { ...guests, children: guests.children + 1 }
      case 'universal':
        return { ...guests, universal: guests.universal + 1 }
    }
  },
  {
    adults: 0,
    children: 0,
    universal: 0,
  }
)
