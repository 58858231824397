import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, RoomDetailsDto } from '../../dtos'
import { RoomDetailsDtoSchema } from '../../dtos'

interface RoomDetailsQueryParams {
  destinationId: string
  roomId: string
}

export const createRoomDetailsQueryKey = (params: RoomDetailsQueryParams) => ['optimizely', 'rooms', params]

export const createRoomDetailsQuery =
  (baseUrl: string) =>
  <Data = RoomDetailsDto>(
    params: RoomDetailsQueryParams,
    options?: Partial<UseQueryOptions<RoomDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<RoomDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createRoomDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: RoomDetailsDtoSchema,
          url: `${baseUrl}/destinations/${params.destinationId}/rooms/${params.roomId}/details`,
        }),
    })
