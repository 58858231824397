import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import { ActivityHistoryDtoSchema, type ActivityHistoryDto, type ProblemDetailsDto } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

interface ActivitiesHistoryQueryParams {
  resortsIds?: number[]
}

export const createActivitiesHistoryQueryKey = (params: ActivitiesHistoryQueryParams = {}) => [
  'consumer',
  'activities',
  'history',
  params,
]

export const createActivitiesHistoryQuery =
  (baseUrl: string) =>
  <Data = ActivityHistoryDto[]>(
    { idToken, ...params }: AuthorizedRequestParams & ActivitiesHistoryQueryParams,
    options?: Partial<UseQueryOptions<ActivityHistoryDto[], ProblemDetailsDto, Data>>
  ) =>
    useQuery<ActivityHistoryDto[], ProblemDetailsDto, Data>({
      ...options,
      queryKey: createActivitiesHistoryQueryKey(params),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(idToken),
          schema: z.array(ActivityHistoryDtoSchema),
          url: `${baseUrl}/activities/history${encodeSearchParams({ resort_ids: params.resortsIds })}`,
        }),
    })
