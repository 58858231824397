import type { LinkServicesDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { EMPTY_PATH_PARAM, Path } from '../../../../models'
import { to } from '../../../navigation'

// TODO 129: Optimizely API: Add destinationId to LinkServicesDto
//  https://hcrdevelopment.atlassian.net/browse/SII-129
export const createLinkServices = (dto: LinkServicesDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to([Path.Services, { destinationId: EMPTY_PATH_PARAM }]),
})
