import { z } from 'zod'

import { BooleanDtoSchema } from '@hcr/api/shared'

export const MarketingPermissionsDtoSchema = z.strictObject({
  email: BooleanDtoSchema,
  sms: BooleanDtoSchema,
})

export type MarketingPermissionsDto = z.infer<typeof MarketingPermissionsDtoSchema>
