import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import type { AccommodationDto } from '@hcr/api/consumer'
import { find, flow, getPropertyValue, isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationsQuery } from '../../../../../hooks'
import { Path } from '../../../../../models'
import { isDestinationOf, to } from '../../../../../utils'
import type { BookingListPreview } from '../../types'

interface BookingListPreviewAccommodationProps {
  accommodation: AccommodationDto
  component: BookingListPreview
}

export const BookingListPreviewAccommodation: FC<BookingListPreviewAccommodationProps> = ({
  accommodation,
  component: Component,
}) => {
  const holiday = useHoliday()
  const navigate = useNavigate()

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(accommodation))),
  })

  if (destination.isSuccess && isNotUndefined(destination.data)) {
    return (
      <Component
        dateRange={{
          from: accommodation.start_date,
          to: accommodation.end_date,
        }}
        destinationId={destination.data.destinationId}
        destinationName={destination.data.title}
        isActive={accommodation.accommodation_id === holiday.data.accommodationId}
        onClick={() => {
          holiday.setAccommodationId(accommodation.accommodation_id)
          navigate(to(Path.Booking))
        }}
      />
    )
  }

  return null
}
