import { z } from 'zod'

import { BooleanDtoSchema } from '@hcr/api/shared'

import { DestinationDetailsDtoBaseSchema } from './DestinationDetailsDtoBase'

export const DestinationsDtoSchema = z.strictObject({
  destinations: z.array(
    DestinationDetailsDtoBaseSchema.extend({
      isSpa: BooleanDtoSchema,
    })
  ),
})

export type DestinationsDto = z.infer<typeof DestinationsDtoSchema>

export const isDestinationsDto = (x: unknown): x is DestinationsDto => DestinationsDtoSchema.safeParse(x).success
