import type { FC } from 'react'

import { isNotNull } from '@hcr/utils'

import { useBenefitsQuery, useIdToken, useUserDetailsQuery } from '../../../../hooks'
import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutNavigationBackError404, LayoutNavigationBackError500 } from '../../../common'

import { createBenefitsGroup, LayoutOwnerBenefits, LayoutOwnerBenefitsLoading } from './common'

const BACK_NAVIGATION_PATH = to(Path.Owner)

const benefitsGroup = createBenefitsGroup()

export const OwnerBenefits: FC = () => {
  const idToken = useIdToken()

  const userDetails = useUserDetailsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  const benefits = useBenefitsQuery(
    {
      isGoldOwner: Boolean(userDetails.data?.is_active_gold_owner),
      isVillasOwner: Boolean(userDetails.data?.is_villas_owner),
    },
    { enabled: userDetails.isSuccess && userDetails.data.has_benefits }
  )

  if (userDetails.isError || benefits.isError) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (userDetails.isSuccess && !userDetails.data.has_benefits) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (userDetails.isSuccess && benefits.isSuccess) {
    return (
      <LayoutOwnerBenefits
        backNavigationPath={BACK_NAVIGATION_PATH}
        description={benefits.data.description}
        title={benefits.data.title}
      >
        {benefits.data.content.map(benefitsGroup)}
      </LayoutOwnerBenefits>
    )
  }

  return <LayoutOwnerBenefitsLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
