import { z } from 'zod'

export enum HoldingWeekTypeDto {
  Unknown = 0,
  Fixed = 1,
  PointsFixed = 2,
  VillasFullOwnership = 3,
  VillasFractional = 4,
}

export const HoldingWeekTypeDtoSchema = z.nativeEnum(HoldingWeekTypeDto)
