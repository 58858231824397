import { Box, Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { unit } from '@hcr/ui'

import { BookingList, LayoutNavigation } from '../../../../common'
import { Tickets } from '../../../../tickets'
import { BookingListEmpty } from '../booking-list-empty'
import { BookingListPreview } from '../booking-list-preview'

type LayoutBookingProps = PropsWithChildren

export const LayoutBooking: FC<LayoutBookingProps> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <LayoutNavigation showContextMenuButton>
      <Box paddingTop={unit(19)} paddingX={unit(3)} paddingBottom={unit(3)}>
        {children}
        <Stack gap={unit(7)} marginTop={unit(7)}>
          <Stack gap={unit(1.5)}>
            <Typography variant='uppercaseL'>{t('booking.purchased-services')}</Typography>
            <Tickets />
          </Stack>
          <Stack gap={unit(1.5)}>
            <Typography variant='uppercaseL'>{t('booking.your-bookings')}</Typography>
            <BookingList empty={BookingListEmpty} preview={BookingListPreview} />
          </Stack>
        </Stack>
      </Box>
    </LayoutNavigation>
  )
}
