import type { ReactNode } from 'react'

import type { DestinationDetailsDto } from '@hcr/api/optimizely'
import { isCardPrimaryDto, isCardPromotionDto } from '@hcr/api/optimizely'
import type { CreateCarouselOptions } from '@hcr/ui'
import { CardEmbedment, CardVariant, createCardPrimary, createCardPromotion, createCarousel } from '@hcr/ui'

import { createLink } from '../common'

export type CreateDestinationDetailsContentOptions = Pick<CreateCarouselOptions, 'slidesMargin'>

export const createDestinationDetailsContent = ({ slidesMargin }: CreateDestinationDetailsContentOptions) => {
  const cardPrimary = createCardPrimary({
    createLink,
    embedment: CardEmbedment.Standalone,
    variant: CardVariant.Regular,
  })
  const cardPromotion = createCardPromotion({ createLink, embedment: CardEmbedment.Standalone })
  const carousel = createCarousel({ createLink, slidesMargin })

  return (dto: DestinationDetailsDto['content'][number]): ReactNode => {
    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    if (isCardPromotionDto(dto)) {
      return cardPromotion(dto)
    }

    return carousel(dto)
  }
}
