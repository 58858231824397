import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { type To } from 'react-router-dom'

import { COLORS, ICONS, LinkUnstyled, rotateYCss, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { SvgLogoHolidayClub } from '../../../../../assets'
import { LayoutBase } from '../../../../common'
import type { AdditionalInfoListItem } from '../additional-info-list'
import { AdditionalInfoList } from '../additional-info-list'

interface LayoutTicketDetailsActiveProps extends PropsWithChildren {
  additionalInfo: AdditionalInfoListItem[]
  backNavigationPath: To
  cta: ReactNode
  destinationLogoUrl: string
  destinationName: string
  guests?: string
  name: string
  validity: string
}

export const LayoutTicketDetailsActive: FC<LayoutTicketDetailsActiveProps> = ({
  additionalInfo,
  backNavigationPath,
  children,
  cta,
  destinationLogoUrl,
  destinationName,
  guests,
  name,
  validity,
}) => {
  const { t } = useTranslation()

  return (
    <LayoutBase>
      <Container>
        <Content>
          <Header>
            <HeaderContent>
              <ArrowLinkIcon to={backNavigationPath} aria-label={t('tickets.back-to-booking')}>
                <FontAwesomeIcon icon={ICONS.farArrowLeft} />
              </ArrowLinkIcon>
              <Stack alignItems='center' css={rotateYCss}>
                <Box component='img' src={destinationLogoUrl} width={unit(6.5)} height={unit(6.5)} />
                <Typography variant='headlineL' color={COLORS.white} marginTop={unit(3)}>
                  {destinationName}
                </Typography>
                <SvgLogoHolidayClub height={unit(2)} fill={COLORS.white} />
              </Stack>
            </HeaderContent>
          </Header>
          <Info>
            <InfoContent>
              <Name>
                <Typography variant='headlineM'>{name}</Typography>
              </Name>
              <InfoContentSection>
                {isNotUndefined(guests) && <Typography variant='titleS'>{guests}</Typography>}
                <Typography variant='bodyM' textAlign='center'>
                  {validity}
                </Typography>
              </InfoContentSection>
              {children}
            </InfoContent>
          </Info>
        </Content>
        <Footer>
          <AdditionalInfoList items={additionalInfo} />
          {cta}
        </Footer>
      </Container>
    </LayoutBase>
  )
}

const Container = styled(Box)`
  padding: 0 ${unit(3)} ${unit(3)};
`

const Content = styled(Stack)`
  background-color: ${COLORS.white};
  /* https://css-generators.com/custom-borders/ */
  mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50% / ${unit(3)} 100%;
`

const Header = styled(Box)`
  position: relative;
  background-color: ${COLORS.burgundi[500]};
  padding: ${unit(4)} ${unit(4)} 0;
`

const HeaderContent = styled(Stack)`
  justify-content: center;
  align-items: center;
  text-align: center;
  height: ${unit(55)};
  border: ${unit(0.5)} dashed ${COLORS.burgundi.tint};
`

const ArrowLinkIcon = styled(LinkUnstyled)`
  color: ${COLORS.white};
  position: absolute;
  top: ${unit(10)};
  left: ${unit(10)};
  font-size: ${unit(6)};
`

const Info = styled(Box)`
  padding: 0 ${unit(4)};
`

const InfoContent = styled(Stack)`
  align-items: stretch;
  gap: ${unit(7)};
  position: relative;
  transform: translateY(${unit(-7.5)});
  background-color: white;
  padding-top: ${unit(6)};
`

const Name = styled(Box)`
  display: flex;
  justify-content: center;
  padding-bottom: ${unit(6)};
  border-bottom: ${unit(0.5)} dashed ${COLORS.hiekka[500]};
`

const InfoContentSection = styled(Stack)`
  justify-content: center;
  align-items: center;
`

const Footer = styled(Stack)`
  gap: ${unit(5)};
  padding: ${unit(5)} ${unit(4)};
`
