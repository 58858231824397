import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkExternalDtoSchema } from '../common'

export const TimeshareIntroductionInfoDtoSchema = z.strictObject({
  title: StringDtoSchema,
  ingress: StringDtoSchema,
  subHeading: StringDtoSchema,
  content: StringDtoSchema,
  formLink: LinkExternalDtoSchema,
})

export type TimeshareIntroductionInfoDto = z.infer<typeof TimeshareIntroductionInfoDtoSchema>

export const isTimeshareIntroductionInfoDto = (x: unknown): x is TimeshareIntroductionInfoDto =>
  TimeshareIntroductionInfoDtoSchema.safeParse(x).success
