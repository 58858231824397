import { getYear } from 'date-fns'

import type { AccommodationDto, ActivityHistoryDto, SingleTicketDto } from '@hcr/api/consumer'
import { isAccommodationDto, isActivityHistoryDto } from '@hcr/api/consumer'
import { flat, flow, group, sort } from '@hcr/utils'

import { compareDates } from '../../../../utils'

const getBookingDate = (booking: AccommodationDto | SingleTicketDto | ActivityHistoryDto): string => {
  if (isAccommodationDto(booking)) {
    return booking.booking_date
  }

  if (isActivityHistoryDto(booking)) {
    return booking.booking_date
  }

  return booking.activation_possible_from_date
}

export const prepareHistoryPreviewsData = flow(
  flat<AccommodationDto[] | SingleTicketDto[] | ActivityHistoryDto[]>(),
  sort({
    comparer: compareDates,
    desc: getBookingDate,
  }),
  group(booking => {
    const bookingDate = getBookingDate(booking)
    return String(getYear(new Date(bookingDate)))
  })
)
