import type { ButtonProps as MuiButtonProps } from '@mui/material'
import { Box, Button as MuiButton, CircularProgress, styled } from '@mui/material'
import { type FC } from 'react'

import { COLORS } from '../../../constants'
import { unit } from '../../../utils'

interface ButtonLoadingProps extends MuiButtonProps {
  isLoading?: boolean
}

// Refactoring opportunity: Replace with https://mui.com/material-ui/api/loading-button/ when it gets merged to @mui/core
export const ButtonLoading: FC<ButtonLoadingProps> = ({
  isLoading = false,
  children,
  disabled,
  startIcon,
  endIcon,
  ...props
}) => (
  <MuiButton
    {...props}
    disabled={disabled || isLoading}
    startIcon={!isLoading && startIcon}
    endIcon={!isLoading && endIcon}
  >
    <Box visibility={isLoading ? 'hidden' : 'visible'}>{children}</Box>
    <LoadingIndicatorContainer visibility={isLoading ? 'visible' : 'hidden'}>
      <CircularProgress size={unit(6)} sx={{ color: COLORS.grey[600] }} />
    </LoadingIndicatorContainer>
  </MuiButton>
)

const LoadingIndicatorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
