import { Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'

import { COLORS, unit } from '@hcr/ui'

import type { AdditionalInfoListItem } from './types'

interface AdditionalInfoListProps {
  items: AdditionalInfoListItem[]
}

export const AdditionalInfoList: FC<AdditionalInfoListProps> = ({ items }) => {
  return (
    <Stack>
      {items.map(({ label, value }) => (
        <Element key={label}>
          <Typography variant='labelM'>{label}</Typography>
          {Array.isArray(value) ? (
            value.map(value => (
              <Typography key={value} variant='bodyM'>
                {value}
              </Typography>
            ))
          ) : (
            <Typography variant='bodyM'>{value}</Typography>
          )}
        </Element>
      ))}
    </Stack>
  )
}

const Element = styled(Stack)`
  padding-top: ${unit(2)};
  padding-bottom: ${unit(3)};

  :not(:last-of-type) {
    border-bottom: ${unit(0.25)} dashed ${COLORS.vaalea[700]};
  }
`
