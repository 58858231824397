import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useMemo } from 'react'
import type { To } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { COLORS, ICONS, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { useLastPath } from '../../../../../contexts'
import { Path } from '../../../../../models'
import { dateFormatter, isServicesPath, to } from '../../../../../utils'
import type { BookingListPreviewProps } from '../../../../common'

export const BookingListPreview: FC<BookingListPreviewProps> = ({
  dateRange,
  destinationId,
  destinationName,
  isActive,
  onClick,
}) => {
  const lastPath = useLastPath()
  const navigate = useNavigate()

  const backNavigationPath = useMemo((): To => {
    if (isNotUndefined(destinationId) && isServicesPath(lastPath.root)) {
      return to([Path.Services, { destinationId }])
    }

    return lastPath.root
  }, [destinationId, lastPath.root])

  return (
    <Container
      bgcolor={isActive ? COLORS.burgundi[500] : COLORS.vaalea['60%']}
      color={isActive ? COLORS.white : COLORS.black}
      onClick={() => {
        onClick()
        navigate(backNavigationPath)
      }}
    >
      <Stack>
        <Typography color={isActive ? 'inherit' : COLORS.burgundi[500]} variant='headlineS'>
          {destinationName}
        </Typography>
        {isNotUndefined(dateRange) && (
          <Typography variant='bodyM'>{dateFormatter.formatDateRange(dateRange)}</Typography>
        )}
      </Stack>
      <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} />
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(4)};
  border-top: thin solid ${COLORS.vaalea[600]};
  padding: ${unit(4)} ${unit(8)} ${unit(5)} ${unit(7)};
  cursor: pointer;
`
