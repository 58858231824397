import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'

import { SvgPersonCalendar } from '../../../assets'
import { ButtonBuyAccommodation } from '../../common'

import { LayoutBooking } from './common'

export const BookingEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutBooking>
      <Header>
        <Box>
          <SvgPersonCalendar />
        </Box>
        <Box marginTop={unit(6.5)}>
          <Typography variant='titleM'>{t('booking.no-accommodation')}</Typography>
        </Box>
        <Box marginTop={unit(3)}>
          <Typography variant='bodyM'>{t('booking.no-bookings-in-the-destination')}</Typography>
        </Box>
        <Box marginTop={unit(7)}>
          <ButtonBuyAccommodation variant='outlined' color='black' />
        </Box>
      </Header>
      <ContentDivider />
    </LayoutBooking>
  )
}

const Header = styled(Stack)`
  align-items: stretch;
  padding: 0 ${unit(4)} ${unit(7)};
  text-align: center;
`

const ContentDivider = styled(Box)`
  background-color: ${COLORS.vaalea[500]};
  height: ${unit(0.25)};
  width: calc(100% + ${unit(6)});
  margin-left: ${unit(-3)};
`
