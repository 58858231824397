import type { AnalyticsInstance } from 'analytics'

type CreateAccommodationContextChangedEventOptions =
  | {
      accommodationId: number
      fromDate: string
      resortId: number
      toDate: string
    }
  | {
      accommodationId: null
    }

export const createAccommodationContextChangedEvent = (
  options: CreateAccommodationContextChangedEventOptions
): Parameters<AnalyticsInstance['track']> => ['accommodationContextChanged', options]
