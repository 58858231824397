import type { ReactNode } from 'react'

import type { AccommodationDto, ActivityDto, SingleTicketDto } from '@hcr/api/consumer'
import { isAccommodationDto } from '@hcr/api/consumer'

import type { BookingListPreview } from '../../types'

import { BookingListPreviewAccommodation } from './BookingListPreviewAccommodation'
import { BookingListPreviewTicket } from './BookingListPreviewTicket'

interface CreateBookingListPreviewOptions {
  component: BookingListPreview
}

export const createBookingListPreview = (options: CreateBookingListPreviewOptions) => {
  return (dto: AccommodationDto | ActivityDto | SingleTicketDto): ReactNode => {
    if (isAccommodationDto(dto)) {
      return (
        <BookingListPreviewAccommodation key={dto.accommodation_id} component={options.component} accommodation={dto} />
      )
    }

    return <BookingListPreviewTicket key={dto.resort_id} component={options.component} ticket={dto} />
  }
}
