import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { COLORS, unit } from '@hcr/ui'

import { LayoutNavigationBack } from '../../../common'

interface OwnerDetailsLoadingProps {
  backNavigationPath: To
}

export const OwnerDetailsLoading: FC<OwnerDetailsLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='outlined'>
      <Box bgcolor={COLORS.white} minHeight='100vh'>
        <Skeleton height={unit(71)} variant='rectangular' />
        <Stack gap={unit(4)} paddingY={unit(9)} paddingX={unit(8)}>
          <Skeleton width='80%' height={unit(9.5)} variant='rectangular' />
          <Box>
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
            <Skeleton width='90%' height={unit(6)} />
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
            <Skeleton width='90%' height={unit(6)} />
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
          </Box>
        </Stack>
      </Box>
    </LayoutNavigationBack>
  )
}
