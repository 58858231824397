import { sort as fastSort } from 'fast-sort'
import { t } from 'i18next'

import type {
  ActivityDto,
  ActivityStatusDto,
  PackageTicketDto,
  PackageTicketStatusDto,
  SingleTicketDto,
  SingleTicketStatusDto,
} from '@hcr/api/consumer'
import { isActivityDto, isPackageTicketDto, isPriceCategoryIdDto, isSingleTicketDto } from '@hcr/api/consumer'
import { flat, flow, getPropertyValue, isUndefined, map, reduce, sort } from '@hcr/utils'

import { LocaleNamespace } from '../../../../models'
import { compareDates } from '../../../../utils'

const TICKETS_STATUSES_WEIGHTS: Record<ActivityStatusDto | PackageTicketStatusDto | SingleTicketStatusDto, number> = {
  Active: 0,
  Inactive: 1,
  Used: 2,
}

const TICKETS_TYPES_WEIGHTS: Record<'Activity' | 'Package' | 'SingleTicket', number> = {
  Package: 0,
  SingleTicket: 1,
  Activity: 2,
}

const isSingleTicketsBundle = (a: SingleTicketDto, b: SingleTicketDto) =>
  a.status === b.status && a.category_id === b.category_id

export const prepareTicketPreviewsData = flow(
  flat<ActivityDto[] | PackageTicketDto[] | SingleTicketDto[]>(),
  sort([
    { asc: ({ status }) => TICKETS_STATUSES_WEIGHTS[status] },
    {
      asc: ticket => {
        if (isActivityDto(ticket)) {
          return TICKETS_TYPES_WEIGHTS.Activity
        }

        if (isPackageTicketDto(ticket)) {
          return TICKETS_TYPES_WEIGHTS.Package
        }

        return TICKETS_TYPES_WEIGHTS.SingleTicket
      },
    },
    {
      asc: ticket => {
        if (isActivityDto(ticket)) {
          return ticket.start_date
        }

        return isPriceCategoryIdDto(ticket.category_id)
          ? t(ticket.category_id, { ns: LocaleNamespace.PriceCategoryId })
          : ticket.category_id
      },
    },
  ]),
  reduce(
    (acc, curr) => {
      const [prev] = acc.slice(-1)

      if (
        isUndefined(prev) ||
        isActivityDto(prev) ||
        isActivityDto(curr) ||
        isPackageTicketDto(prev) ||
        isPackageTicketDto(curr)
      ) {
        return [...acc, curr]
      }

      if (isSingleTicketDto(prev) && isSingleTicketsBundle(prev, curr)) {
        return [...acc.slice(0, -1), [prev, curr]]
      }

      if (Array.isArray(prev) && isSingleTicketsBundle(prev[0], curr)) {
        return [...acc.slice(0, -1), [...prev, curr]]
      }

      return [...acc, curr]
    },
    [] as Array<ActivityDto | PackageTicketDto | SingleTicketDto | Array<SingleTicketDto>>
  ),
  map(dto => {
    if (Array.isArray(dto)) {
      switch (dto[0].status) {
        case 'Active':
        case 'Used':
          return fastSort(dto).by({ comparer: compareDates, desc: getPropertyValue('activation_date') })

        case 'Inactive':
          return fastSort(dto).by({ comparer: compareDates, asc: getPropertyValue('activation_possible_to_date') })
      }
    }

    return dto
  })
)
