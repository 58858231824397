import { useMsal } from '@azure/msal-react'
import { Typography } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutOnboarding } from '../common'

import { OnboardingForm } from './common'
import { OnboardingSuccess } from './OnboardingSuccess'

export const Onboarding: FC = () => {
  const { t } = useTranslation()
  const msal = useMsal()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  if (isFormSubmitted) {
    return <OnboardingSuccess />
  }

  return (
    <LayoutOnboarding
      description={t('onboarding.creating-an-account-ensures-you-get-the-best-holiday-experience-and-price')}
      footer={
        <>
          <Typography variant='bodyM'>{t('onboarding.already-have-an-account?')}</Typography>
          <Typography variant='textLinkM' onClick={() => msal.instance.loginRedirect()}>
            {t('onboarding.sign-in')}
          </Typography>
        </>
      }
      form={<OnboardingForm setIsFormSubmitted={setIsFormSubmitted} />}
      headline={t('onboarding.start-creating-your-account')}
      stepper={{ highlight: 1, of: 2 }}
    />
  )
}
