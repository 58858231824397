import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { BenefitsGroupDtoSchema } from '../components'

export const BenefitsDtoSchema = z.strictObject({
  title: StringDtoSchema,
  description: StringDtoSchema,
  content: z.array(BenefitsGroupDtoSchema),
})

export type BenefitsDto = z.infer<typeof BenefitsDtoSchema>

export const isBenefitsDto = (x: unknown): x is BenefitsDto => BenefitsDtoSchema.safeParse(x).success
