import { z } from 'zod'

import { CardPromotionDtoBaseSchema } from './CardPromotionDtoBase'

export const CardPromotionOwnerDtoSchema = CardPromotionDtoBaseSchema.extend({
  variant: z.literal('timeshare-owner'),
})

export type CardPromotionOwnerDto = z.infer<typeof CardPromotionOwnerDtoSchema>

export const isCardPromotionOwnerDto = (x: unknown): x is CardPromotionOwnerDto =>
  CardPromotionOwnerDtoSchema.safeParse(x).success
