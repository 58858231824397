import hash from 'object-hash'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { isPriceCategoryIdDto, type SingleTicketDto } from '@hcr/api/consumer'
import { ICONS } from '@hcr/ui'
import { filter, flow, isNotNull, isSame, unique } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useDestinationDetailsQuery } from '../../../../../hooks'
import { LocaleNamespace, Path } from '../../../../../models'
import { createSingleTicketsGuestsString, createSingleTicketValidityString, to } from '../../../../../utils'

import { TicketPreview } from './TicketPreview'
import { TicketPreviewLoading } from './TicketPreviewLoading'

interface TicketPreviewSingleBundleProps {
  tickets: SingleTicketDto[]
}

export const TicketPreviewSingleBundle: FC<TicketPreviewSingleBundleProps> = ({ tickets }) => {
  const { t } = useTranslation()
  const holiday = useHoliday()

  const resortsIds = useDestinationDetailsQuery(
    { destinationId: String(holiday.data.destinationId) },
    {
      select: flow(
        destinationDetails => [destinationDetails.hotelId, destinationDetails.mokkiId],
        filter(isNotNull),
        unique(isSame)
      ),
      enabled: isNotNull(holiday.data.destinationId),
      retryOnMount: false,
    }
  )

  if (holiday.isError || resortsIds.isError) {
    return null
  }

  if (holiday.isSuccess && resortsIds.isSuccess) {
    return (
      <TicketPreview
        key={hash(['single-bundle', tickets[0].category_id, tickets[0].status])}
        guests={createSingleTicketsGuestsString(tickets)}
        guestsIcon={ICONS.farLayerGroup}
        link={to([
          Path.TicketDetailsSingle,
          { categoryId: tickets[0].category_id },
          { resortsIds: resortsIds.data.join(','), status: tickets[0].status },
        ])}
        name={
          isPriceCategoryIdDto(tickets[0].category_id)
            ? t(tickets[0].category_id, { ns: LocaleNamespace.PriceCategoryId })
            : tickets[0].category_id
        }
        status={tickets[0].status}
        type={t('single-bundle', { ns: LocaleNamespace.TicketType })}
        validity={createSingleTicketValidityString(tickets[0])}
      />
    )
  }

  return <TicketPreviewLoading />
}
