import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { PackageTicketStatusDtoSchema } from './PackageTicketStatusDto'

export const PackageTicketDtoSchema = z.strictObject({
  category_id: StringDtoSchema,
  status: PackageTicketStatusDtoSchema,
})

export type PackageTicketDto = z.infer<typeof PackageTicketDtoSchema>

export const isPackageTicketDto = (x: unknown): x is PackageTicketDto => PackageTicketDtoSchema.safeParse(x).success
