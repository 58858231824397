import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, ServiceDetailsDto } from '../../dtos'
import { ServiceDetailsDtoSchema } from '../../dtos'

interface ServiceDetailsQueryParams {
  destinationId: string
  serviceId: string
}

export const createServiceDetailsQueryKey = (params: ServiceDetailsQueryParams) => ['optimizely', 'services', params]

export const createServiceDetailsQuery =
  (baseUrl: string) =>
  <Data = ServiceDetailsDto>(
    params: ServiceDetailsQueryParams,
    options?: Partial<UseQueryOptions<ServiceDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<ServiceDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createServiceDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: ServiceDetailsDtoSchema,
          url: `${baseUrl}/destinations/${params.destinationId}/services/${params.serviceId}/details`,
        }),
    })
