import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { LinkDtoBaseSchema } from './LinkDtoBase'

export const LinkBenefitDetailsDtoSchema = LinkDtoBaseSchema.extend({
  target: z.literal('benefit-details'),
  benefitId: StringDtoSchema,
})

export type LinkBenefitDetailsDto = z.infer<typeof LinkBenefitDetailsDtoSchema>

export const isLinkBenefitDetailsDto = (x: unknown): x is LinkBenefitDetailsDto =>
  LinkBenefitDetailsDtoSchema.safeParse(x).success
