import { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

export const ErrorDtoSchema = z
  .object({
    Message: StringDtoSchema,
  })
  .optional()

export type ErrorDto = z.infer<typeof ErrorDtoSchema>

export const isErrorDto = (x: unknown): x is ErrorDto => ErrorDtoSchema.safeParse(x).success
