import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Accordion } from '@hcr/ui'

import { LINK_CONFIG } from '../../../../../configs'

export const BookingTimeshareIntroductionInfo: FC = () => {
  const { t } = useTranslation()

  return (
    <Accordion
      content={[
        {
          title: t('booking-details.acquaintance-vacation'),
          description: `
            ${t('booking-details.your-vacation-includes-a-two-hour-long-presentation-about-your-vacation-week')}
            <br />
            <br />
            <a href='${LINK_CONFIG.welcomePresentation}' target='_blank' rel='external'>${t(
              'booking-details.read-more'
            )}
            </a>
          `,
        },
      ]}
    />
  )
}
