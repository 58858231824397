import { t } from 'i18next'

import type { PackageTicketStatusDto } from '@hcr/api/consumer'

export const createPackageTicketValidityString = (status: PackageTicketStatusDto): string => {
  switch (status) {
    case 'Active':
      return t('utils.tickets-package-active')
    case 'Inactive':
      return t('utils.tickets-package-inactive')
  }
}
