import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import type { AccommodationDto, ProblemDetailsDto } from '../../dtos'
import { AccommodationDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

interface AccommodationsQueryParams {
  resortId?: number
}

export const createAccommodationsQueryKey = (params: AccommodationsQueryParams = {}) => [
  'consumer',
  'accommodations',
  params,
]

export const createAccommodationsQuery =
  (baseUrl: string) =>
  <Data = AccommodationDto[]>(
    { idToken, ...params }: AuthorizedRequestParams & AccommodationsQueryParams,
    options?: Partial<UseQueryOptions<AccommodationDto[], ProblemDetailsDto, Data>>
  ) =>
    useQuery<AccommodationDto[], ProblemDetailsDto, Data>({
      ...options,
      queryKey: createAccommodationsQueryKey(params),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(idToken),
          schema: z.array(AccommodationDtoSchema),
          url: `${baseUrl}/accommodations${encodeSearchParams({ resort_id: params.resortId })}`,
        }),
    })
