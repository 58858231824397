import type { SerializedStyles } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, css, styled, Typography } from '@mui/material'
import type { FC } from 'react'

import { COLORS, ICONS } from '../../../constants'
import { unit } from '../../../utils'

interface ChipProps {
  text: string
  variant: 'Active' | 'Inactive' | 'Used'
}

export const Chip: FC<ChipProps> = ({ text, variant }) => {
  return (
    <Container css={containerVariantCss[variant]}>
      {variant === 'Active' && (
        <SquareIcon>
          <FontAwesomeIcon icon={ICONS.fassSquare} />
        </SquareIcon>
      )}
      <Typography variant='titleExtraSmall'>{text}</Typography>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${unit(0.25)} ${unit(1.5)};
  border-radius: ${unit(0.5)};
  user-select: none;
`

const containerVariantCss: Record<ChipProps['variant'], SerializedStyles> = {
  Active: css`
    background-color: ${COLORS.semantic.infoBody};
    color: ${COLORS.semantic.info};
  `,
  Inactive: css`
    background-color: ${COLORS.semantic.successBody};
    color: ${COLORS.semantic.success};
  `,
  Used: css`
    background-color: ${COLORS.vaalea['60%']};
    color: ${COLORS.kaarna[500]};
  `,
}

const SquareIcon = styled(Box)`
  font-size: ${unit(1)};
  margin-right: ${unit(1.25)};
`
