import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import type { To } from 'react-router-dom'

import { COLORS, createLinkExternalProps, LinkUnstyled, unit } from '@hcr/ui'

interface LinkProps {
  description: string
  icon: ReactNode
  isExternal?: boolean
  title: string
  to: To
}

export const Link: FC<LinkProps> = ({ description, icon, isExternal = false, title, to }) => {
  return (
    <Container to={to} {...createLinkExternalProps(isExternal)}>
      <Stack>
        <Typography variant='titleS'>{title}</Typography>
        <Typography variant='bodyS'>{description}</Typography>
      </Stack>
      <Box fontSize={unit(5)}>{icon}</Box>
    </Container>
  )
}

const Container = styled(LinkUnstyled)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${unit(3.5)} ${unit(7)} ${unit(4.5)} ${unit(7)};
  background-color: ${COLORS.vaalea['60%']};
  gap: ${unit(4)};
  min-height: ${unit(24.5)};

  :not(:last-of-type) {
    border-bottom: ${unit(0.25)} solid ${COLORS.vaalea[600]};
  }
`
