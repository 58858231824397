import type { ReactNode } from 'react'

import type { BuildingDetailsDto } from '@hcr/api/optimizely'
import { isCardPrimaryDto } from '@hcr/api/optimizely'
import { CardEmbedment, CardVariant, createCardPrimary, createPricing } from '@hcr/ui'

import { createLink } from '../common'

export const createBuildingDetailsContent = () => {
  const cardPrimary = createCardPrimary({
    createLink,
    embedment: CardEmbedment.Standalone,
    variant: CardVariant.Regular,
  })
  const pricing = createPricing()

  return (dto: BuildingDetailsDto['content'][number]): ReactNode => {
    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    return pricing(dto)
  }
}
