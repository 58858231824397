import { Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { unit } from '@hcr/ui'

import { SvgPersonCalendar } from '../../../assets'
import { useLastPath } from '../../../contexts'
import { ButtonSignIn, ButtonSignUp, LayoutNavigationBack } from '../../common'

export const GomeddoBookingUnauthenticated: FC = () => {
  const { t } = useTranslation()
  const lastPath = useLastPath()

  return (
    <LayoutNavigationBack to={lastPath.recent} arrowVariant='text'>
      <Container>
        <SvgPersonCalendar />
        <Stack gap={unit(2.5)} textAlign='center'>
          <Typography variant='titleM'>{t('gomeddo-booking.service-booking')}</Typography>
          <Typography variant='bodyM'>{t('gomeddo-booking.to-book-a-service-please-sign-in')}</Typography>
        </Stack>
        <Stack gap={unit(4)} width='100%'>
          <ButtonSignIn variant='contained' color='primary' />
          <ButtonSignUp variant='outlined' color='black' />
        </Stack>
      </Container>
    </LayoutNavigationBack>
  )
}

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${unit(7)};
  padding: ${unit(19)} ${unit(7)} ${unit(7)};
`
