import { Link, styled } from '@mui/material'

import { COLORS, unit } from '@hcr/ui'

export const DestinationDrawerLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${unit(1)};
  cursor: pointer;
  align-self: flex-start;
  -webkit-tap-highlight-color: transparent;

  span,
  svg {
    color: ${COLORS.linknega[500]};
  }
`
