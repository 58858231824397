import { Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { Fragment, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { DividerWithLabel, unit } from '@hcr/ui'
import { isEmpty, isNotUndefined, isUndefined } from '@hcr/utils'

import { useBuildingDetailsQuery } from '../../../../hooks'
import type { OwnerHoldingWeeksPathParams } from '../../../../models'
import { createHoldingNameString, isHoldingVilla } from '../../../../utils'
import { LayoutNavigationBack, LayoutNavigationBackError404 } from '../../../common'
import { createWeekPreview } from '../common'
import { useBackNavigationPath, useHolding } from '../hooks'

import { OwnerHoldingWeeksLoading } from './OwnerHoldingWeeksLoading'
import { prepareWeekPreviewsData } from './utils'

export const OwnerHoldingWeeks: FC = () => {
  const params = useParams<OwnerHoldingWeeksPathParams>()

  const holding = useHolding({ holdingId: params.holdingId })

  const buildingDetails = useBuildingDetailsQuery(
    { buildingId: String(holding.data?.building_id), isContracted: true },
    { enabled: isNotUndefined(holding.data?.building_id) }
  )

  const weekPreviewsData = useMemo(() => prepareWeekPreviewsData(holding.data?.weeks ?? []), [holding.data?.weeks])

  const backNavigationPath = useBackNavigationPath({ holdingId: params.holdingId })

  if (
    isUndefined(params.holdingId) ||
    holding.isError ||
    (holding.isSuccess && isUndefined(holding.data)) ||
    (holding.isSuccess && isEmpty(weekPreviewsData)) ||
    buildingDetails.isError
  ) {
    return <LayoutNavigationBackError404 to={backNavigationPath} />
  }

  if (holding.isSuccess && isNotUndefined(holding.data) && buildingDetails.isSuccess) {
    const weekPreview = createWeekPreview({
      holdingId: holding.data.holding_id,
      isVilla: isHoldingVilla(holding.data),
    })

    return (
      <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
        <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
          <Typography variant='headlineL'>{buildingDetails.data.title}</Typography>
          <Typography variant='bodyM'>{createHoldingNameString(holding.data)}</Typography>
        </Stack>
        {Array.from(weekPreviewsData.entries()).map(([year, weeks]) => (
          <Fragment key={year}>
            <DividerWithLabel>{year}</DividerWithLabel>
            <Stack gap={unit(4.5)} paddingY={unit(4.5)} paddingX={unit(7)}>
              {weeks.map(weekPreview)}
            </Stack>
          </Fragment>
        ))}
      </LayoutNavigationBack>
    )
  }

  return <OwnerHoldingWeeksLoading backNavigationPath={backNavigationPath} />
}
