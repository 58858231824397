import type { LinkBenefitsDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../navigation'

export const createLinkBenefits = (dto: LinkBenefitsDto): Link => ({
  isExternal: false,
  label: dto.label,
  to: to(Path.OwnerBenefits),
})
