import { isUndefined } from '../objects'

export const unique =
  <T>(predicate: (a: T) => (b: T) => boolean) =>
  (array: T[] | undefined): T[] => {
    if (isUndefined(array)) {
      return []
    }

    return array.filter((item, index) => array.findIndex(predicate(item)) === index)
  }
