import { Box, Skeleton, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, unit } from '@hcr/ui'

import {
  BookingDatesLoading,
  LayoutBookingOverview,
  LayoutBookingOverviewContent,
  LayoutBookingOverviewDivider,
  LayoutBookingOverviewHeader,
  LayoutBookingOverviewSection,
} from '../common'

export const BookingOverviewLoading: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutBookingOverview>
      <LayoutBookingOverviewHeader>
        <Box marginBottom={unit(3)}>
          <Skeleton width={unit(6.5)} height={unit(6.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
        </Box>
        <Typography variant='bodyM'>{t('booking-overview.your-booking-at')}</Typography>
        <Typography variant='headlineL'>{t('booking-overview.resort')}</Typography>
      </LayoutBookingOverviewHeader>
      <LayoutBookingOverviewContent>
        <BookingDatesLoading />
        <LayoutBookingOverviewDivider />
        <LayoutBookingOverviewSection label={t('booking-overview.accommodation')}>
          <Skeleton width={unit(40)} height={unit(6)} sx={{ bgcolor: COLORS.white }} />
          <Box marginTop={unit(1)}>
            <Skeleton width={unit(25)} height={unit(5.5)} sx={{ bgcolor: COLORS.white }} />
          </Box>
        </LayoutBookingOverviewSection>
        <LayoutBookingOverviewDivider />
        <LayoutBookingOverviewSection label={t('booking-overview.booking-status')}>
          <Skeleton width={unit(40)} height={unit(6)} sx={{ bgcolor: COLORS.white }} />
        </LayoutBookingOverviewSection>
        <LayoutBookingOverviewSection label={t('booking-overview.payment-status')}>
          <Skeleton width={unit(40)} height={unit(6)} sx={{ bgcolor: COLORS.white }} />
        </LayoutBookingOverviewSection>
        <Skeleton width='100%' height={unit(13)} sx={{ bgcolor: COLORS.burgundi[500] }} variant='rectangular' />
      </LayoutBookingOverviewContent>
    </LayoutBookingOverview>
  )
}
