import { z } from 'zod'

import { Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

export const ProblemDetailsDtoSchema = z.strictObject({
  type: StringDtoSchema,
  title: StringDtoSchema,
  status: Int32DtoSchema,
  detail: StringDtoSchema,
  instance: StringDtoSchema,
})

export type ProblemDetailsDto = z.infer<typeof ProblemDetailsDtoSchema>
