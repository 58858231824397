import { Stack, Typography } from '@mui/material'
import { sanitize } from 'dompurify'
import type { FC } from 'react'
import { useMemo } from 'react'
import { type To, useParams } from 'react-router-dom'

import { COLORS, optimizelyCss, unit } from '@hcr/ui'
import { isNotUndefined, isUndefined } from '@hcr/utils'

import { useLastPath } from '../../../../contexts'
import { useBenefitDetailsQuery } from '../../../../hooks'
import type { OwnerBenefitDetailsPathParams } from '../../../../models'
import { Path } from '../../../../models'
import { isOwnerPath, to } from '../../../../utils'
import { LayoutNavigationBack, LayoutNavigationBackError404 } from '../../../common'

import { OwnerBenefitDetailsLoading } from './OwnerBenefitDetailsLoading'

export const OwnerBenefitDetails: FC = () => {
  const params = useParams<OwnerBenefitDetailsPathParams>()
  const lastPath = useLastPath()

  const benefitDetails = useBenefitDetailsQuery(
    { benefitId: String(params.benefitId) },
    { enabled: isNotUndefined(params.benefitId) }
  )

  const backNavigationPath = useMemo((): To => {
    if (isOwnerPath(lastPath.recent)) {
      return to(Path.Owner)
    }

    return to(Path.OwnerBenefits)
  }, [lastPath.recent])

  if (isUndefined(params.benefitId) || benefitDetails.isError) {
    return <LayoutNavigationBackError404 to={backNavigationPath} />
  }

  if (benefitDetails.isSuccess) {
    return (
      <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
        <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
          <Typography variant='headlineL'>{benefitDetails.data.title}</Typography>
          <Typography variant='headlineS' color={COLORS.burgundi[500]}>
            {benefitDetails.data.subtitle}
          </Typography>
          <Typography
            variant='bodyM'
            css={optimizelyCss}
            dangerouslySetInnerHTML={{ __html: sanitize(benefitDetails.data.description) }}
          />
        </Stack>
      </LayoutNavigationBack>
    )
  }

  return <OwnerBenefitDetailsLoading backNavigationPath={backNavigationPath} />
}
