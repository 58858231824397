import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { LinkExternalDto } from '@hcr/api/optimizely'
import { COLORS, DividerWithLabel, ICONS, LinkUnstyled, unit } from '@hcr/ui'

import { Path } from '../../../../../models'
import { createLinkExternal, to } from '../../../../../utils'

interface UsefulLinksProps {
  links: LinkExternalDto[]
}

export const UsefulLinks: FC<UsefulLinksProps> = ({ links }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <DividerWithLabel>{t('account.useful-links')}</DividerWithLabel>
      {links.map(createLinkExternal).map(link => (
        <Link component={LinkUnstyled} to={link.to} key={link.label} target='_blank' rel='external'>
          <Typography variant='labelM'>{link.label}</Typography>
          <FontAwesomeIcon icon={ICONS.farArrowUpRightFromSquare} />
        </Link>
      ))}
      <Link component={LinkUnstyled} to={to(Path.About)}>
        <Typography variant='labelM'>{t('account.about-the-app')}</Typography>
        <FontAwesomeIcon icon={ICONS.farChevronRight} />
      </Link>
    </Box>
  )
}

const Link = styled(Button)`
  display: flex;
  justify-content: space-between;
  padding: ${unit(4)} ${unit(7)};
  color: ${COLORS.black};
  border-bottom: ${unit(0.25)} solid ${COLORS.vaalea[600]};
` as typeof Button
