import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { isPriceCategoryIdDto } from '@hcr/api/consumer'
import { BookingSummary } from '@hcr/ui'
import { find, flow, getPropertyValue, hasPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useDestinationsQuery, useHistoryQuery, useIdToken, useSingleTicketsQuery } from '../../../../hooks'
import type { HistoryDetailsTicketPathParams } from '../../../../models'
import { DateFormat, LocaleNamespace, Path } from '../../../../models'
import { createSingleTicketValidity, currencyFormatter, dateFormatter, isDestinationOf, to } from '../../../../utils'
import { ButtonBuyTicket, LayoutNavigationBackError404 } from '../../../common'
import { LayoutHistoryDetails, HistoryDetailsTicketLoading } from '../common'

const BACK_NAVIGATION_PATH = to(Path.History)

export const HistoryDetailsTicket: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const params = useParams<HistoryDetailsTicketPathParams>()

  const currentSingleTicket = useSingleTicketsQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('ticket_id', Number(params.ticketId))),
      enabled: isNotNull(idToken) && isNotUndefined(params.ticketId),
    }
  )

  const isCurrentSingleTicketNotFound =
    currentSingleTicket.isError || (currentSingleTicket.isSuccess && isUndefined(currentSingleTicket.data))

  const historySingleTicket = useHistoryQuery(
    { idToken: String(idToken) },
    {
      select: flow(getPropertyValue('tickets'), find(hasPropertyValue('ticket_id', Number(params.ticketId)))),
      enabled: isNotNull(idToken) && isNotUndefined(params.ticketId) && isCurrentSingleTicketNotFound,
    }
  )

  const singleTicket = useMemo(
    () => (isCurrentSingleTicketNotFound ? historySingleTicket : currentSingleTicket),
    [currentSingleTicket, historySingleTicket, isCurrentSingleTicketNotFound]
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(singleTicket.data))),
    enabled: isNotUndefined(singleTicket.data),
  })

  if (
    isUndefined(params.ticketId) ||
    singleTicket.isError ||
    (singleTicket.isSuccess && isUndefined(singleTicket.data))
  ) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (singleTicket.isSuccess && isNotUndefined(singleTicket.data)) {
    const validity = createSingleTicketValidity(singleTicket.data)

    return (
      <LayoutHistoryDetails
        backNavigationPath={BACK_NAVIGATION_PATH}
        cta={<ButtonBuyTicket resortId={singleTicket.data.resort_id} variant='outlined' color='black' />}
        headerPrimary={
          isPriceCategoryIdDto(singleTicket.data.category_id)
            ? t(singleTicket.data.category_id, { ns: LocaleNamespace.PriceCategoryId })
            : singleTicket.data.category_id
        }
        headerSecondary={t(singleTicket.data.ticket_group, { ns: LocaleNamespace.TicketGroup })}
        showFallbackWarning={destination.isError || (destination.isSuccess && isUndefined(destination.data))}
      >
        <BookingSummary
          content={[
            ...(isNotUndefined(destination.data?.title)
              ? [
                  {
                    label: t('history-details.resort'),
                    value: destination.data?.title,
                  },
                ]
              : []),
            {
              label: t('history-details.price'),
              value: currencyFormatter.format(singleTicket.data.price),
            },
            {
              label: t('history-details.status'),
              value: t(singleTicket.data.status, { ns: LocaleNamespace.TicketStatus }),
            },
            {
              label: validity.label,
              value: validity.date,
            },
            {
              label: t('history-details.booking-date'),
              value: dateFormatter.format(DateFormat.Standard, singleTicket.data.activation_possible_from_date),
            },
            {
              label: t('history-details.booking-number'),
              value: singleTicket.data.ticket_id,
            },
          ]}
        />
      </LayoutHistoryDetails>
    )
  }

  return <HistoryDetailsTicketLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
