import type { ReactNode } from 'react'

import type { OwnerDetailsDto } from '@hcr/api/optimizely'
import { isAccordionDto, isCardPrimaryDto, isCardPromotionDto } from '@hcr/api/optimizely'
import {
  CardEmbedment,
  CardVariant,
  createAccordion,
  createCardPrimary,
  createCardPromotion,
  createPricing,
} from '@hcr/ui'

import { createLink } from '../common'

export const createOwnerDetailsContent = () => {
  const accordion = createAccordion()
  const cardPrimary = createCardPrimary({
    createLink,
    embedment: CardEmbedment.Standalone,
    variant: CardVariant.Regular,
  })
  const cardPromotion = createCardPromotion({ createLink, embedment: CardEmbedment.Standalone })
  const pricing = createPricing()

  return (dto: OwnerDetailsDto['content'][number]): ReactNode => {
    if (isAccordionDto(dto)) {
      return accordion(dto)
    }

    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    if (isCardPromotionDto(dto)) {
      return cardPromotion(dto)
    }

    return pricing(dto)
  }
}
