import type { AccommodationDto, ActivityDto, SingleTicketDto } from '@hcr/api/consumer'
import type { DestinationDto } from '@hcr/api/optimizely'
import { isUndefined } from '@hcr/utils'

export const isDestinationOf =
  <T extends AccommodationDto | ActivityDto | SingleTicketDto>(booking: T | undefined) =>
  (destination: DestinationDto): boolean => {
    if (isUndefined(booking)) {
      return false
    }

    const hasSameHotelId = booking.resort_id === Number(destination.hotelId)
    const hasSameMokkiId = booking.resort_id === Number(destination.mokkiId)
    return hasSameHotelId || hasSameMokkiId
  }
