import { Environment } from '../environments/environment'
import type { GomeddoWidgetProps } from '../typings/gomeddo'

interface GomeddoConfig {
  widget: Pick<GomeddoWidgetProps, 'auto' | 'business' | 'page'>
}

export const GOMEDDO_CONFIG: GomeddoConfig = {
  widget: {
    auto: 'reservation resource',
    business: Environment.Gomeddo.business,
    page: Environment.Gomeddo.page,
  },
}
