import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import type { ActivityDto, ProblemDetailsDto } from '../../dtos'
import { ActivityDtoSchema } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

interface ActivitiesQueryParams {
  resortsIds?: number[]
}

export const createActivitiesQueryKey = (params: ActivitiesQueryParams = {}) => ['consumer', 'activities', params]

export const createActivitiesQuery =
  (baseUrl: string) =>
  <Data = ActivityDto[]>(
    { idToken, ...params }: AuthorizedRequestParams & ActivitiesQueryParams,
    options?: Partial<UseQueryOptions<ActivityDto[], ProblemDetailsDto, Data>>
  ) =>
    useQuery<ActivityDto[], ProblemDetailsDto, Data>({
      ...options,
      queryKey: createActivitiesQueryKey(params),
      queryFn: () =>
        getJson({
          headers: createAuthorizationHeader(idToken),
          schema: z.array(ActivityDtoSchema),
          url: `${baseUrl}/activities${encodeSearchParams({ resort_ids: params.resortsIds })}`,
        }),
    })
