import type { ReactNode } from 'react'

import type { HomeDto } from '@hcr/api/optimizely'
import { isCardPrimaryDto, isCardPromotionDto, isCarouselDto } from '@hcr/api/optimizely'
import type { CreateCardPrimaryOptions, CreateCarouselOptions } from '@hcr/ui'
import { CardEmbedment, createCardPrimary, createCardPromotion, createCarousel, createPaper } from '@hcr/ui'

import { createLink } from '../common'

export type CreateHomeContentOptions = Pick<CreateCardPrimaryOptions, 'variant'> &
  Pick<CreateCarouselOptions, 'slidesMargin'>

export const createHomeContent = ({ slidesMargin, variant }: CreateHomeContentOptions) => {
  const cardPrimary = createCardPrimary({ createLink, embedment: CardEmbedment.Standalone, variant })
  const cardPromotion = createCardPromotion({ createLink, embedment: CardEmbedment.Standalone })
  const carousel = createCarousel({ createLink, slidesMargin })
  const paper = createPaper()

  return (dto: HomeDto['content'][number]): ReactNode => {
    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    if (isCardPromotionDto(dto)) {
      return cardPromotion(dto)
    }

    if (isCarouselDto(dto)) {
      return carousel(dto)
    }

    return paper(dto)
  }
}
