import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, unit } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { SENTRY_CONFIG } from '../../../configs'
import { useLastPath } from '../../../contexts'
import { useAccountQuery, useIdToken, useUserDetailsQuery } from '../../../hooks'
import { LocaleNamespace, Path } from '../../../models'
import { to } from '../../../utils'
import {
  ButtonSignOut,
  LayoutNavigationBack,
  LayoutNavigationBackError500,
  LayoutNavigationBackLoading,
} from '../../common'

import { UsefulLinks } from './common'

export const Account: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const lastPath = useLastPath()

  const account = useAccountQuery()

  const userDetails = useUserDetailsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken) })

  if (userDetails.isError) {
    return <LayoutNavigationBackError500 to={lastPath.root} />
  }

  if (userDetails.isSuccess) {
    return (
      <LayoutNavigationBack to={lastPath.root} arrowVariant='text'>
        <Header>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='headlineS'>
            {userDetails.data.first_name} {userDetails.data.last_name}
          </Typography>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='bodyM'>
            {userDetails.data.email}
          </Typography>
        </Header>
        <ListButton component={LinkUnstyled} to={to(Path.AccountEdit)}>
          <ListItemWithIcon>
            <Typography className={SENTRY_CONFIG.privacy.className} variant='labelM'>
              {t('account.address')}
            </Typography>
            <FontAwesomeIcon icon={ICONS.farPencil} />
          </ListItemWithIcon>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='bodyM'>
            {userDetails.data.street_address}
          </Typography>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='bodyM'>
            {userDetails.data.street_address2}
          </Typography>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='bodyM'>
            {userDetails.data.postal_code} {userDetails.data.city}
          </Typography>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='bodyM'>
            {t(userDetails.data.country, { ns: LocaleNamespace.Country })}
          </Typography>
        </ListButton>
        <ListButton component={LinkUnstyled} to={to(Path.AccountEdit)}>
          <ListItemWithIcon>
            <Typography className={SENTRY_CONFIG.privacy.className} variant='labelM'>
              {t('account.phone-number')}
            </Typography>
            <FontAwesomeIcon icon={ICONS.farPencil} />
          </ListItemWithIcon>
          <Typography className={SENTRY_CONFIG.privacy.className} variant='bodyM'>
            {userDetails.data.country_code} {userDetails.data.phone}
          </Typography>
        </ListButton>
        <ListButton component={LinkUnstyled} to={to(Path.History)}>
          <ListItemWithIcon>
            <Typography variant='labelM'>{t('account.booking-history')}</Typography>
            <FontAwesomeIcon icon={ICONS.farChevronRight} />
          </ListItemWithIcon>
        </ListButton>
        <UsefulLinks links={account.isSuccess ? account.data.links : []} />
        <Footer>
          <ButtonSignOut variant='outlined' color='black' />
        </Footer>
      </LayoutNavigationBack>
    )
  }

  return <LayoutNavigationBackLoading to={lastPath.root} />
}

const Header = styled(Stack)`
  gap: ${unit(2)};
  padding: ${unit(24)} ${unit(7)} ${unit(4)};
  word-break: break-word;
`

const ListButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${unit(4)} ${unit(7)};
  color: ${COLORS.black};
  border-top: ${unit(0.25)} solid ${COLORS.vaalea[600]};
  word-break: break-word;
  font-weight: normal;
` as typeof Button

const ListItemWithIcon = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Footer = styled(Box)`
  padding: ${unit(5)} ${unit(7)};
`
